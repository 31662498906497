import React from "react";
import {Button, TextField} from "@material-ui/core";
import {Element, useNode} from "@craftjs/core";
import {makeStyles} from "@material-ui/core/styles";
import {craftjsElements} from "../../../assets/theme/components/craftjs-element";
import Color from "../Settings/Color";
import Padding from "../Settings/Padding";
import {useIntl} from "react-intl";
import themeColors from "../../../assets/theme/colors";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(craftjsElements);

export const Fieldset = ({title, background, padding, children, ...props}) => {
  const classes = useStyles();
  const {id, connectors: {connect, drag}} = useNode();

  return <Box
    component="fieldset"
    {...props}
    ref={ref => connect(drag(ref))}
    classes={classes}
    style={{
      backgroundColor: background,
      padding: padding,
      minHeight: 16,
      width: "100%",
    }}>
    <legend>{title}</legend>
    <Element
      is={"div"}
      id="fieldset"
      parentNode={id}
      canvas
      style={{
        padding: 0,
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        minHeight: 16,
      }}
    >{children}</Element>
  </Box>;
}


export const FieldsetSettings = () => {
  const {
    background,
    padding,
    title,
    actions: {setProp},
  } = useNode((node) => ({
    size: node.data.props.size,
    background: node.data.props.background,
    padding: node.data.props.padding,
    title: node.data.props.title,
  }));
  const intl = useIntl();

  return (
    <div>
      <TextField
        fullWidth
        variant="standard"
        size="small"
        label={intl.formatMessage({id: "layouts.prop.title", defaultMessage: "Title"})}
        value={title}
        margin="normal"
        onChange={(e) => {
          setProp(props => props.title = e.target.value);
        }}
        placeholder={intl.formatMessage({
          id: "layouts.prop.text-empty",
          defaultMessage: "Write your text here"
        })}
      />
      <Padding setProp={setProp} value={padding}/>
      <Color setProp={setProp} value={background}/>
    </div>
  );
};

export const FieldsetButton = ({connectors, ...rest}) => {
  const intl = useIntl();
  return <Button
    {...rest}
    ref={ref => connectors.create(ref, <Fieldset/>, {})}
    variant="outlined"
    style={{border: "1px dashed " + themeColors.warning.badge}}
  >{intl.formatMessage({id: "layouts.prop.buttons.fieldset", defaultMessage: "Fieldset"})}</Button>
}

Fieldset.craft = {
  props: {
    title: '',
    background: '#ffffff',
    padding: 4,
  },
  related: {
    settings: FieldsetSettings,
  },
};
