import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {useEditor} from "@craftjs/core";
import {TextButton} from "../../../../components/Layouts/Nodes/Text";
import {useIntl} from "react-intl";
import {StaticFieldButton} from "../../../../components/Layouts/Nodes/StaticField";
import {AssetButton} from "../../../../components/Layouts/Nodes/Asset";
import {TableButton} from "../../../../components/Layouts/Nodes/Table";
import {ParagraphButton} from "../../../../components/Layouts/Nodes/Paragraph";

const Toolbox = () => {
    const {connectors} = useEditor();
    const intl = useIntl();
    return <Grid container direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
        <Grid item xs={12} style={{textAlign: "center"}}>
            <Typography>{intl.formatMessage({
                id: "layouts.toolbox.nodes-help",
                defaultMessage: "Drag to add"
            })}</Typography>
        </Grid>
      <Grid item><StaticFieldButton connectors={connectors}/></Grid>
        <Grid item><TextButton connectors={connectors}/></Grid>
        <Grid item><ParagraphButton connectors={connectors}/></Grid>
        <Grid item><AssetButton connectors={connectors}/></Grid>
        <Grid item><TableButton connectors={connectors}/></Grid>
    </Grid>;
};
export default Toolbox;
