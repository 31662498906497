import React from "react";
import {Button, FormControl, FormLabel, Grid, Slider} from "@material-ui/core";
import {Element, useNode} from "@craftjs/core";
import {makeStyles} from "@material-ui/core/styles";
import {craftjsElements} from "../../../assets/theme/components/craftjs-element";
import Alignment from "../Settings/Alignment";
import Color from "../Settings/Color";
import Padding from "../Settings/Padding";
import {useIntl} from "react-intl";
import themeColors from "../../../assets/theme/colors";

const useStyles = makeStyles(craftjsElements);

export const Block = ({size, background, padding, align, children, ...props}) => {
  const classes = useStyles();
  const {id, connectors: {connect, drag}} = useNode();
  let justify = align === "left" ? "flex-start" : align === "right" ? "flex-end" : "center";

  return <Grid
    {...props}
    ref={ref => connect(drag(ref))}
    classes={classes}
    item
    style={{
      backgroundColor: background,
      padding: padding,
      textAlign: align,
      justifyContent: justify,
      border: "1px dashed " + themeColors.warning.badge,
      minHeight: 16
    }}
    xs={size}>
    <Element
      is={"div"}
      id="block"
      parentNode={id}
      canvas
      style={{
        padding: 0,
        height: "100%",
        minHeight: 16,
        display: "flex",
        flexWrap: "wrap",
      }}
    >{children}</Element>
  </Grid>;
}


export const BlockSettings = () => {
  const {
    size,
    background,
    padding,
    align,
    actions: {setProp},
  } = useNode((node) => ({
    size: node.data.props.size,
    background: node.data.props.background,
    padding: node.data.props.padding,
    align: node.data.props.align,
  }));
  const intl = useIntl();

  return (
    <div>
      <FormControl fullWidth margin="dense" component="fieldset">
        <FormLabel component="legend" style={{fontSize: "0.825rem"}}>{
          intl.formatMessage({id: "layouts.prop.width", defaultMessage: "Width {width}"}, {width: size + '/12'})
        }</FormLabel>
        <Slider
          defaultValue={size}
          onChange={(_, value) =>
            setProp((props) => (props.size = value), 500)
          }
          step={1}
          marks
          min={1}
          max={12}
        />
      </FormControl>
      <Padding setProp={setProp} value={padding}/>
      <Alignment setProp={setProp} value={align}/>
      <Color setProp={setProp} value={background}/>
    </div>
  );
};

export const BlockButton = ({connectors, ...rest}) => {
  const intl = useIntl();
  return <Button
    {...rest}
    ref={ref => connectors.create(ref, <Block/>, {})}
    variant="outlined"
    style={{border: "1px dashed " + themeColors.warning.badge}}
  >{intl.formatMessage({id: "layouts.prop.buttons.block", defaultMessage: "Block"})}</Button>
}

Block.craft = {
  props: {
    background: '#ffffff',
    padding: 4,
    size: 6,
    align: 'left',
  },
  related: {
    settings: BlockSettings,
  },
};
