import {Chip, Paper, Snackbar} from '@material-ui/core';
import {FileCopy, List} from '@material-ui/icons';
import EnhancedTable from '../../../../components/DataTable/EnhancedTable';
import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import ConfirmDialog from "../../../../components/Dialogs/ConfirmDialog";
import {graphQLApi} from "../../../../services/GraphQLApi";
import {useAuthDispatch} from "../../../../contexts/Auth";

export default function ListingList(props) {
  const intl = useIntl();
  const [selected, setSelected] = useState([])
  const [showConfirmCopy, setShowConfirmCopy] = useState(false)
  const tableRef = useRef()
  const client = new graphQLApi(useAuthDispatch());
  /**
   * Notification
   */
  const [notification, setNotification] = React.useState({
    severity: 'info',
    show: false,
    message: '',
  });
  const notify = (message, color = 'info') => {
    setNotification({severity: color, message: message, show: true});
  }
  const closeNotification = () => setNotification({...notification, show: false});

  const columns = [
    {title: intl.formatMessage({id: 'listings.list.column.title', defaultMessage: 'Title'}), field: 'title'},
    {
      title: intl.formatMessage({id: 'listings.list.column.listing_operands_count', defaultMessage: 'Filters'}),
      field: 'listing_operands_count',
      render: row => parseInt(row.listing_operands_count)
    },
    {
      title: intl.formatMessage({id: 'listings.list.column.usages_count', defaultMessage: 'Usages'}),
      field: 'usages_count',
      render: row => parseInt(row.usages_count)
    },
    {
      title: intl.formatMessage({
        id: "listings.list.column.roles",
        defaultMessage: "Roles",
      }),
      field: "roles",
      render: row => {
        return row.roles.map((r, k) => <Chip size="small" key={"user_" + row.id + "_roles_" + k} label={r.title} style={{marginRight:3}}/>)
      }
    },
    {
      title: intl.formatMessage({id: 'listings.list.column.created_at', defaultMessage: 'Created at'}),
      field: 'created_at',
    },
  ];
  if (!props.filter) {
    columns.splice(1, 0,
      {
        title: intl.formatMessage({id: 'entity_type_layouts.list.column.entity_type', defaultMessage: 'Entity type'}),
        field: 'entity_type.title'
      });
  }

  const onCopyListingsConfirm = (confirmed) => {
    if (confirmed) {
      tableRef.current.isLoading(true)
      client.mutate('($ids:[ID]!) {listingCopyCreate(ids:$ids)}',
        {ids: selected.map(r => parseInt(r.id))}).then(_r => {
        if (_r) {
          notify(intl.formatMessage({
            id: "listings.list.notification.copy.message",
            defaultMessage: "{count} listings are copied!"
          }, {count: _r.listingCopyCreate}), 'success');
          tableRef.current.update();
        }
      });
    }
    setSelected([]);
    setShowConfirmCopy(false);
  }
  const {elevation = 2, margin = 3} = props;

  return (<Paper elevation={elevation} style={{margin: margin}}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        message={notification.message}
        open={notification.show}
        onClose={closeNotification}
        autoHideDuration={6000}
      />
      {showConfirmCopy && <ConfirmDialog
        onClose={onCopyListingsConfirm}
        open={showConfirmCopy}
        title={intl.formatMessage({
          id: "dropzone.listing.list.confirm.copy_selected.title",
          defaultMessage: "Confirm you want to copy {count} listings"
        }, {count: selected.length})}
        message={intl.formatMessage({
          id: "dropzone.listing.list.confirm.copy_selected.message",
          defaultMessage: "Are you sure you want to copy all of the {count} selected entities?"
        }, {count: selected.length})}
      />}
      <EnhancedTable
        ref={tableRef}
        title={intl.formatMessage({id: 'listings.list.table.heading', defaultMessage: "Filters"})}
        columns={columns}
        query="listings"
        filter="user_id:null"
        mutations="listing"
        fields='id title usages_count listing_operands_count created_at entity_type{title} roles{title}'
        actions={[
          {
            icon: FileCopy,
            tooltip: intl.formatMessage({id: "listings.list.actions.copy_selected", defaultMessage: "Copy selected"}),
            onSelected: true,
            onClick: (rows) => {
              setSelected(rows)
              setShowConfirmCopy(true)
            },
          }
        ]}
        icon={<List/>}
        urlState
        {...props}
      />
    </Paper>
  );
}
