import {FormControl, FormLabel, Grid, MenuItem, Paper, Slider, TextField} from "@material-ui/core";
import Toolbox from "./components/Toolbox";
import SettingsPanel from "../../../components/Layouts/SettingsPanel";
import {Text} from "../../../components/Layouts/Nodes/Text";
import {StaticField} from "../../../components/Layouts/Nodes/StaticField";
import {Asset} from "../../../components/Layouts/Nodes/Asset";
import {Editor, Element, Frame, useNode} from "@craftjs/core";
import Box from "@material-ui/core/Box";
import PdfLayoutTopbar from "./PdfLayoutTopbar";
import React from "react";
import {useIntl} from "react-intl";
import {Cell, Table} from "../../../components/Layouts/Nodes/Table";
import {Paragraph} from "../../../components/Layouts/Nodes/Paragraph";
import {useParams} from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import {makeStyles} from "@material-ui/core/styles";
import {Field} from "../../../components/Layouts/Nodes/Field";

const useStyles = makeStyles({
  positionSticky: {
    top: 60,
  }
});

const Layout = ({top, bottom, left, right, font, children}) => {
  return <div
    style={{
      width: "100%",
      minHeight: 16,
      paddingTop: top * 4,
      paddingBottom: bottom * 4,
      paddingLeft: left * 4,
      paddingRight: right * 4,
      fontFamily: font,
    }}>
    <Element
      is="div"
      id="Layout"
      canvas
      style={{height: "100%", width: "100%", minHeight: 16}}
    >{children}</Element>
  </div>
}
const LayoutSettings = () => {
  const {
    orientation,
    size,
    top,
    bottom,
    left,
    right,
    font,
    actions: {setProp},
  } = useNode((node) => ({
    orientation: node.data.props.orientation,
    size: node.data.props.size,
    top: node.data.props.top,
    bottom: node.data.props.bottom,
    left: node.data.props.left,
    right: node.data.props.right,
    font: node.data.props.font,
  }));
  const intl = useIntl();
  return <div>
    <TextField
      select
      fullWidth
      variant="standard"
      size="small"
      margin="normal"
      label={intl.formatMessage({id: "layouts.prop.font", defaultMessage: "Font"})}
      value={font}
      onChange={e => setProp(props => props.font = e.target.value)}
    >
      <MenuItem value="courier">Monospace</MenuItem>
      <MenuItem value="helvetica">Modern</MenuItem>
      <MenuItem value="times-roman">Serif</MenuItem>
    </TextField>
    <TextField
      select
      fullWidth
      variant="standard"
      size="small"
      margin="normal"
      label={intl.formatMessage({id: "layouts.prop.page-size", defaultMessage: "Page size"})}
      value={size}
      onChange={e => setProp(props => props.size = e.target.value)}
    >
      <MenuItem value="A3">A3</MenuItem>
      <MenuItem value="A4">A4</MenuItem>
      <MenuItem value="A5">A5</MenuItem>
      <MenuItem value="letter">Letter</MenuItem>
      <MenuItem value="legal">Legal</MenuItem>
    </TextField>
    <TextField
      select
      fullWidth
      variant="standard"
      size="small"
      margin="normal"
      label={intl.formatMessage({id: "layouts.prop.orientation.label", defaultMessage: "Orientation"})}
      value={orientation}
      onChange={e => setProp(props => props.orientation = e.target.value)}
    >
      <MenuItem value="P">{intl.formatMessage({
        id: "layouts.prop.orientation.portrait",
        defaultMessage: "Portrait"
      })}</MenuItem>
      <MenuItem value="L">{intl.formatMessage({
        id: "layouts.prop.orientation.landscape",
        defaultMessage: "Landscape"
      })}</MenuItem>
    </TextField>
    <FormControl fullWidth margin="dense" component="fieldset">
      <FormLabel component="legend" style={{fontSize: "0.825rem"}}>{
        intl.formatMessage({id: "layouts.prop.top", defaultMessage: "Top {value}mm"}, {value: top})
      }</FormLabel>
      <Slider
        defaultValue={top}
        onChange={(_, value) =>
          setProp((props) => (props.top = value), 500)
        }
        step={5}
        max={50}
        min={0}
      />
    </FormControl>
    <FormControl fullWidth margin="dense" component="fieldset">
      <FormLabel component="legend" style={{fontSize: "0.825rem"}}>{
        intl.formatMessage({id: "layouts.prop.bottom", defaultMessage: "Bottom {value}mm"}, {value: bottom})
      }</FormLabel>
      <Slider
        defaultValue={bottom}
        onChange={(_, value) =>
          setProp((props) => (props.bottom = value), 500)
        }
        step={5}
        max={50}
        min={0}
      />
    </FormControl>
    <FormControl fullWidth margin="dense" component="fieldset">
      <FormLabel component="legend" style={{fontSize: "0.825rem"}}>{
        intl.formatMessage({id: "layouts.prop.left", defaultMessage: "Left {value}mm"}, {value: left})
      }</FormLabel>
      <Slider
        defaultValue={left}
        onChange={(_, value) =>
          setProp((props) => (props.left = value), 500)
        }
        step={5}
        max={50}
        min={0}
      />
    </FormControl>
    <FormControl fullWidth margin="dense" component="fieldset">
      <FormLabel component="legend" style={{fontSize: "0.825rem"}}>{
        intl.formatMessage({id: "layouts.prop.right", defaultMessage: "Right {value}mm"}, {value: right})
      }</FormLabel>
      <Slider
        defaultValue={right}
        onChange={(_, value) =>
          setProp((props) => (props.right = value), 500)
        }
        step={5}
        max={50}
        min={0}
      />
    </FormControl>
  </div>
}
Layout.craft = {
  displayName: 'Layout',
  props: {
    orientation: 'P',
    top: 15,
    bottom: 15,
    left: 5,
    right: 5,
    size: 'A4',
    font: 'helvetica',
  },
  related: {
    settings: LayoutSettings,
  },
}

const PdfLayoutEdit = () => {
  const params = useParams();
  const classes = useStyles();
  let id = Number(params.id);
  return (<Box><Editor resolver={{Layout, Table, Cell, Text, Field, StaticField, Asset, Paragraph}}>
    <Grid container spacing={3}>
      <Grid item xs={12}><PdfLayoutTopbar id={id}/></Grid>
      <Grid item xs={9}>
        <Paper>
          <Grid container item spacing={1} style={{padding: "0 4px"}}>
            <Frame>
              <Element
                canvas
                is={Layout}
                padding={4}
              />
            </Frame>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <AppBar position="sticky" color="default" classes={classes}>
          <Toolbox/>
          <SettingsPanel/>
        </AppBar>
      </Grid>
    </Grid>
  </Editor></Box>);
}
export default PdfLayoutEdit;
