import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
// @material-ui/icons components
// core components
import componentStyles from "assets/theme/views/auth/login.js";
import {
  CardActions,
  Checkbox, CircularProgress,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import {authLogin, getApiDomain, useAuthDispatch} from "../contexts/Auth";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {getNames} from "i18n-iso-countries";
import theme from "../assets/theme/theme";

const useStyles = makeStyles(componentStyles);

function Register() {
  const classes = useStyles();
  const intl = useIntl();
  const navigate = useNavigate();

  const validationFields = {
    email: [],
    password: [],
    message: [],
    name: [],
    phone: [],
    company: [],
    address: [],
    zip: [],
    city: [],
    country: [],
    cvr: [],
    invoice_email: [],
    conditions: [],
  };
  const [accepted, setAccepted] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [validation, setValidation] = React.useState(validationFields);
  const [isLoading, setIsLoading] = React.useState(false);
  const [registered, setRegistered] = React.useState(null);

  const initialCountries = getNames(window.language ? window.language : 'da', {select: "official"});
  const countries = Object.keys(initialCountries).map((key) => ({code: key, value: initialCountries[key]}));

  if (registered) {
    console.log(registered)
    return <Grid item xs={12} md={10} lg={8} style={{display: "flex"}} justifyContent="center">
      <Card classes={{root: classes.cardRoot}}>
        <CardHeader
          title={intl.formatMessage({
            id: "auth.register.registered",
            defaultMessage: "Your OpenDIMS account has been created!"
          })}
          classes={{title: classes.cardTitle}}
          style={{textAlign: "center"}}
        />
        <CardContent classes={{root: classes.cardContent}} style={{textAlign:"center"}}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec in lorem id felis tristique pretium. Ut euismod nulla a urna tincidunt ultricies. Ut varius euismod blandit. Quisque ultricies elit lacinia maximus viverra. Integer ac ipsum ultricies, tempor justo vel, pulvinar tortor. Praesent viverra mauris vitae leo consequat vestibulum. Integer pellentesque, diam malesuada lobortis posuere, purus ipsum imperdiet lorem, elementum efficitur nulla quam in enim.
        </CardContent>
        <CardActions style={{justifyContent: "center"}}>
          <Button color="primary" variant="contained" href={registered.site+'/login'}>{
            intl.formatMessage({id:"auth.register.button.login", defaultMessage:"Continue to login"})
          }</Button>
        </CardActions>
      </Card>
    </Grid>
  }

  return <Grid item xs={12} md={10} lg={8} style={{display: "flex"}} justifyContent="center">
    <Card classes={{root: classes.cardRoot}} disabled={isLoading} component={"form"} onSubmit={e => {
      e.preventDefault();
      let formData = new FormData(e.target);
      setIsLoading(true);
      fetch(getApiDomain() + '/auth/register', {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: formData,
        headers: {
          "Accept": "application/json",
        },
      }).then(r => r.json()).then(r => {
        setIsLoading(false);
        setValidation({...validationFields, ...r?.errors});
        if (r?.status === 'success') setRegistered(r);
      });
      return false;
    }}>
      <CardHeader
        title={intl.formatMessage({
          id: "auth.register.header",
          defaultMessage: "Create a free OpenDIMS account"
        })}
        classes={{title: classes.cardTitle}}
        style={{textAlign: "center"}}
      />
      <CardContent classes={{root: classes.cardContent}}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item container xs={12} md={6} spacing={2}>
            <Grid item xs={12}>
              <Typography>{intl.formatMessage({
                id: "auth.register.you",
                defaultMessage: "Your information"
              })}</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={validation.name.length > 0}
                fullWidth
                label={intl.formatMessage({id: "auth.register.name", defaultMessage: "Name"})}
                name={"name"}
                helperText={validation.name.length > 0 ? validation.name.join(" ") : null}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={validation.email.length > 0}
                fullWidth
                label={intl.formatMessage({id: "auth.register.email", defaultMessage: "Email"})}
                name={"email"}
                value={email}
                onChange={e => setEmail(e.target.value)}
                helperText={validation.email.length > 0 ? validation.email.join(" ") : null}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={validation.phone.length > 0}
                fullWidth
                label={intl.formatMessage({id: "auth.register.phone", defaultMessage: "Telephone"})}
                name={"phone"}
                helperText={validation.phone.length > 0 ? validation.phone.join(" ") : null}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6} spacing={2} alignContent="flex-start">
            <Grid item xs={12}>
              <Typography>{intl.formatMessage({id: "auth.register.security", defaultMessage: "Password"})}</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={validation.password.length > 0}
                fullWidth
                label={intl.formatMessage({id: "auth.register.password", defaultMessage: "Password"})}
                name={"password"}
                value={password}
                onChange={e => setPassword(e.target.value)}
                helperText={validation.password.length > 0 ? validation.password.join(" ") : null}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={validation.password.length > 0}
                fullWidth
                label={intl.formatMessage({
                  id: "auth.register.password-confirm",
                  defaultMessage: "Confirm the password"
                })}
                name={"password_confirmation"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container xs={12} spacing={2} justifyContent="space-between">
          <Grid item xs={12} p={2}>
            <Typography>{intl.formatMessage({
              id: "auth.register.billing",
              defaultMessage: "Billing information"
            })}</Typography>
          </Grid>
          <Grid item container xs={12} md={6} spacing={2}>
            <Grid item xs={12}>
              <TextField
                error={validation.company.length > 0}
                fullWidth
                label={intl.formatMessage({id: "auth.register.company", defaultMessage: "Company name"})}
                name={"company"}
                helperText={validation.company.length > 0 ? validation.company.join(" ") : null}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={validation.address.length > 0}
                fullWidth
                label={intl.formatMessage({id: "auth.register.address", defaultMessage: "Address"})}
                name={"address"}
                helperText={validation.address.length > 0 ? validation.address.join(" ") : null}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                error={validation.zip.length > 0}
                fullWidth
                label={intl.formatMessage({id: "auth.register.zip", defaultMessage: "Zip"})}
                name={"zip"}
                helperText={validation.zip.length > 0 ? validation.zip.join(" ") : null}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                error={validation.city.length > 0}
                fullWidth
                label={intl.formatMessage({id: "auth.register.city", defaultMessage: "City"})}
                name={"city"}
                helperText={validation.city.length > 0 ? validation.city.join(" ") : null}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6} spacing={2}>
            <Grid item xs={12}>
              <TextField
                error={validation.country.length > 0}
                fullWidth
                select
                label={intl.formatMessage({id: "auth.register.country", defaultMessage: "Country"})}
                name={"country"}
                defaultValue={window.language === 'da' ? 'Danmark' : 'Denmark'}
                helperText={validation.country.length > 0 ? validation.country.join(" ") : null}
              >{countries.sort((a, b) => (a.value.toLowerCase().localeCompare(b.value.toLowerCase())))
                .map((country, key) => (
                  <MenuItem
                    key={"form-field-" + country.code + "-item-" + key}
                    value={country.value}
                  >
                    {country.value}
                  </MenuItem>))
              }</TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={validation.cvr.length > 0}
                fullWidth
                label={intl.formatMessage({id: "auth.register.cvr", defaultMessage: "VAT Registration"})}
                name={"cvr"}
                helperText={validation.cvr.length > 0 ? validation.cvr.join(" ") : null}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={validation.invoice_email.length > 0}
                fullWidth
                label={intl.formatMessage({id: "auth.register.invoice_email", defaultMessage: "Invoice Email"})}
                name={"invoice_email"}
                helperText={validation.invoice_email.length > 0 ? validation.invoice_email.join(" ") : null}
              />
            </Grid>
          </Grid>
          <FormGroup row style={{alignItems:"center", color:validation.conditions.length ? theme.palette.error.main : 'inherit'}}>
            <FormControlLabel
              control={<Checkbox value={'yes'} checked={accepted} onChange={e => setAccepted(e.target.checked)} name="conditions"/>}
              label={intl.formatMessage({
                id: "auth.register.accept-terms",
                defaultMessage: "I accept the terms and conditions of {site}"
              }, {
                site: process.env.REACT_APP_NAME,
              })}
            />
            <Link target="_blank" href="https://opendims.com/terms/">{
              intl.formatMessage({id: "auth.register.read-terms", defaultMessage: "Læs mere her"})
            }</Link>
          </FormGroup>
          {validation.conditions.length ? <Grid item xs={12} style={{paddingTop:0,color:theme.palette.error.main}}>
            <FormHelperText style={{color:'inherit',display:'block'}}>{validation.conditions}</FormHelperText>
          </Grid> : ''}
          <Grid item xs={12} container justifyContent="center">
            {isLoading
              ? <CircularProgress/>
              : <Button disabled={isLoading} type={"submit"} variant="contained" color="primary">{intl.formatMessage({
              id: "auth.button.register",
              defaultMessage: "Register"
            })}</Button>}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions style={{justifyContent: "space-between"}}>
        <Link
          href="/login"
          className={classes.footerLinks}
        >
          {intl.formatMessage({id: "auth.back_to_login", defaultMessage: "Back to login"})}
        </Link>
      </CardActions>
    </Card>
  </Grid>;
}

export default Register;
