import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {Card, CardContent, CardHeader, Snackbar, Tab, Tabs,} from '@material-ui/core';
import {Announcement, CallSplit} from "@material-ui/icons";
import {graphQLApi, graphQLReduceFields} from '../../../services/GraphQLApi';
import {useAuthDispatch} from '../../../contexts/Auth';
import EditForm from '../../../components/Form/EditForm';
import RoleSelector from '../Settings/RoleSelector';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import FieldList from "./Fields/FieldList";
import ListingList from "./Listings/ListingList";
import ViewList from "./Views/ViewList";
import LayoutList from "./Layouts/LayoutList";
import ViewEdit from "./Views/ViewEdit";
import FieldEdit from "./Fields/FieldEdit";
import ListingEdit from "./Listings/ListingEdit";
import LayoutEdit from "./Layouts/LayoutEdit";
import ConfirmDialog from "../../../components/Dialogs/ConfirmDialog";
import Tooltip from "@material-ui/core/Tooltip";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <CardContent>{children}</CardContent>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function EntityTypeEdit(props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  let id = params.id === 'create' ? null : Number(params.id);

  const handleChange = d => {
    setData(d);
    setDataChanged(true);
  }
  const fields = [
    {
      column: 1,
      field: "title",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "entity_types.edit.label.title", defaultMessage: "Title"}),
      input: "text"
    },
    {
      column: 1,
      field: "is_pinned_to_menu",
      initial: false,
      type: "Boolean",
      label: intl.formatMessage({id: "entity_types.edit.label.pinned", defaultMessage: "Pin to menu"}),
      input: "switch"
    },
    {
      column: 1,
      field: "menu_category",
      initial: "Data",
      type: "String",
      label: intl.formatMessage({id: "entity_types.edit.label.category", defaultMessage: "Menu category"}),
      disabled: data => !data.is_pinned_to_menu,
    },
    {
      column: 1,
      field: "uses_variants",
      initial: false,
      type: "Boolean",
      label: intl.formatMessage({
        id: "entity_types.edit.label.uses_variants",
        defaultMessage: "Uses variants and variant fields"
      }),
      input: "switch"
    },
    {
      column: 1,
      field: "roles",
      initial: [],
      type: "[ID]",
      label: intl.formatMessage({id: "entity_types.edit.label.roles", defaultMessage: "Roles with access"}),
      render: () => <RoleSelector roles={roles} data={data} setData={handleChange}/>
    },
  ];
  if (id) {
    fields.push({
      column: 2,
      field: "-",
      input: "heading",
      label: intl.formatMessage({
        id: "entity_types.edit.heading.has_parent",
        defaultMessage: "If this entity type has parents (like categories that uses a tree structure) select the parent id field here."
      }),
    });
    fields.push({
      column: 2,
      field: "parent_field_id",
      initial: null,
      type: "ID",
      label: intl.formatMessage({
        id: "entity_types.edit.label.parent_field",
        defaultMessage: "Field that contains the parents identifying value (Ex: id or key)"
      }),
      query: "fields",
      titleField: "name",
      filter: "entity_type_id:" + id,
    });
    fields.push({
      column: 2,
      field: "-",
      input: "heading",
      label: intl.formatMessage({
        id: "entity_types.edit.heading.field_titles",
        defaultMessage: "Setup the fields used to generate entity selections using the format: '[number] title'"
      }),
    });
    fields.push({
      column: 2,
      field: "number_field_id",
      initial: null,
      type: "ID",
      label: intl.formatMessage({id: "entity_types.edit.label.number_field", defaultMessage: "Number field"}),
      query: "fields",
      titleField: "name",
      filter: "entity_type_id:" + id
    });
    fields.push({
      column: 2,
      field: "title_field_id",
      initial: null,
      type: "ID",
      label: intl.formatMessage({id: "entity_types.edit.label.title_field", defaultMessage: "Title field"}),
      query: "fields",
      titleField: "name",
      filter: "entity_type_id:" + id
    });
    fields.push({
      column: 2,
      field: "third_field_id",
      initial: null,
      type: "ID",
      label: intl.formatMessage({id: "entity_types.edit.label.third_field", defaultMessage: "Third field"}),
      query: "fields",
      titleField: "name",
      filter: "entity_type_id:" + id
    });
    fields.push({
      column: 2,
      field: "fourth_field_id",
      initial: null,
      type: "ID",
      label: intl.formatMessage({id: "entity_types.edit.label.fourth_field", defaultMessage: "Fourth field"}),
      query: "fields",
      titleField: "name",
      filter: "entity_type_id:" + id
    });
    fields.push({
      column: 2,
      field: "fifth_field_id",
      initial: null,
      type: "ID",
      label: intl.formatMessage({id: "entity_types.edit.label.fifth_field", defaultMessage: "Fifth field"}),
      query: "fields",
      titleField: "name",
      filter: "entity_type_id:" + id
    });
    fields.push({
      column: 2,
      field: "sixth_field_id",
      initial: null,
      type: "ID",
      label: intl.formatMessage({id: "entity_types.edit.label.sixth_field", defaultMessage: "Sixth field"}),
      query: "fields",
      titleField: "name",
      filter: "entity_type_id:" + id
    });
  } else {
    fields.push({
      column: 2,
      field: "-",
      input: "heading",
      label: intl.formatMessage({
        id: "entity_types.edit.heading.field_title_after_creation",
        defaultMessage: "You will need to come back to this, to setup the fields to use for titles, after the entity type and fields have been created!"
      }),
    });
  }

  const d = {
    'title': '',
    'is_pinned_to_menu': false,
    'roles': [],
    'parent_field_id': null,
    'title_field_id': null,
    'number_field_id': null,
    'third_field_id': null,
    'fourth_field_id': null,
    'fifth_field_id': null,
    'sixth_field_id': null,
  }

  const [confirmation, setConfirmation] = useState({
    title: '',
    message: '',
    onClose: () => {
    },
  });
  const closeConfirmation = () => {
    setConfirmation({
      title: '',
      message: '',
      onClose: () => {
      },
    });
  }
  const [notification, setNotification] = useState();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [data, setData] = useState(d);
  const [dataChanged, setDataChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  const initialValidation = graphQLReduceFields(fields, 'validation');
  const [validation, setValidation] = useState(initialValidation);
  const setValidationFromErrors = (errors) => {
    if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
      setValidation({...initialValidation, ...errors[0].extensions.validation});
    }
  };

  const client = new graphQLApi(useAuthDispatch(), null, {handleErrors: setValidationFromErrors});

  const queryFields = 'id title is_pinned_to_menu uses_variants menu_category roles{id title} parent_field{id name} title_field{id name} number_field{id name}  third_field{id name}  fourth_field{id name}  fifth_field{id name}  sixth_field{id name} fields_count';

  const save = () => {
    setIsLoading(true);
    setValidation(initialValidation);
    const vars = {
      title: 'String',
      roles: '[ID]',
      is_pinned_to_menu: 'Boolean',
      uses_variants: 'Boolean',
      menu_category: 'String',
      parent_field_id: 'ID',
      number_field_id: 'ID',
      title_field_id: 'ID',
      third_field_id: 'ID',
      fourth_field_id: 'ID',
      fifth_field_id: 'ID',
      sixth_field_id: 'ID',
    };

    if (id) {
      data.id = id;
      vars.id = "ID!";
    }
    client.mutation('entityType', vars, data, queryFields).then(r => {
      if (r?.response) {
        r.response.listing_id = r.response.listing;
        r.response.parent_field_id = r.response.parent_field
        r.response.title_field_id = r.response.title_field
        r.response.number_field_id = r.response.number_field
        r.response.third_field_id = r.response.third_field
        r.response.fourth_field_id = r.response.fourth_field
        r.response.fifth_field_id = r.response.fifth_field
        r.response.sixth_field_id = r.response.sixth_field
        setData(r.response);
        if (r.response.fields_count === 0) {
          setTab(tabs.length - 2);
          setNotification(intl.formatMessage({
            id: "entity_types.edit.notifications.saved_no_fields",
            defaultMessage: "The entity type has been saved but has no fields, you need to create some before you can continue with the other settings."
          }))
        }
        navigate(location.pathname.replace('/create', '/' + r.response.id), {replace: true});
        setDataChanged(false);
      }
      setIsLoading(false);
    });
  }

  const editProperties = {
    setModified: setHasUnsavedChanges,
    id: params.tabId,
    entityTypeId: id,
    elevation: 0,
    margin: -24,
  };
  const tabs = [
    {
      url: 'layouts',
      title: intl.formatMessage({id: "entity_types.edit.tab.layouts", defaultMessage: "Form Designer"}),
      list: <LayoutList filter={'entity_type_id:' + id} baseUrl={'/admin/data-types/' + id + '/layouts'} elevation={0}
                        margin={-24} hideTopPagination urlState={false}/>,
      edit: <LayoutEdit {...editProperties}/>,
    },
    {
      url: 'views',
      title: intl.formatMessage({id: "entity_types.edit.tab.views", defaultMessage: "Views"}),
      list: <ViewList filter={'entity_type_id:' + id} baseUrl={'/admin/data-types/' + id + '/views'} elevation={0}
                      margin={-24} hideTopPagination urlState={false}/>,
      edit: <ViewEdit {...editProperties}/>,
    },
    {
      url: 'filters',
      title: intl.formatMessage({id: "entity_types.edit.tab.filters", defaultMessage: "Filters"}),
      list: <ListingList filter={'entity_type_id:' + id} baseUrl={'/admin/data-types/' + id + '/filters'} elevation={0}
                         margin={-24} hideTopPagination urlState={false}/>,
      edit: <ListingEdit {...editProperties}/>,
    },
    {
      url: 'fields',
      title: intl.formatMessage({id: "entity_types.edit.tab.fields", defaultMessage: "Fields"}),
      list: <FieldList filter={'entity_type_id:' + id} baseUrl={'/admin/data-types/' + id + '/fields'} elevation={0}
                       margin={-24} hideTopPagination urlState={false}/>,
      edit: <FieldEdit {...editProperties} entityType={data}/>,
    },
    {
      icon: dataChanged ? <Tooltip title={intl.formatMessage({
        id: "entity_types.edit.unsaved_changes.tooltip",
        defaultMessage: "There are unsaved changes on this tab!"
      })}><Announcement color="secondary"/></Tooltip> : null,
      url: 'settings',
      title: intl.formatMessage({id: "entity_types.edit.tab.settings", defaultMessage: "Settings"}),
      list: <EditForm id={id}
                      query={'entityTypes'}
                      mutations={'entityType'}
                      fields={fields}
                      cols={2}
                      data={data}
                      setData={handleChange}
                      isLoading={isLoading}
                      validation={validation}
                      save={save}
                      {...props}
      />
    },
  ];
  const [tab, setTab] = useState(id > 0 ? 0 : tabs.length - 1);
  useEffect(() => {
    if (!params.tab) return;
    setTab(tabs.findIndex(t => t.url === params.tab))
  }, [params.tab]);

  useEffect(() => {
    setIsLoading(true)
    let query = 'roles {data{id title}}';
    if (id) query = query + ' entityTypes (filter:{id:' + id + '},counts:["fields"]) {data {' + queryFields + '}}'
    client.query('{' + query + '}').then(r => {
      if (r && r.hasOwnProperty('roles')) {
        setRoles(r.roles?.data);
        if (!r.hasOwnProperty('entityTypes')) {
          setData({...data, roles: r.roles?.data})
        }
      }
      if (r && r.hasOwnProperty('entityTypes')) {
        r.entityTypes.data[0].parent_field_id = r.entityTypes.data[0].parent_field
        r.entityTypes.data[0].title_field_id = r.entityTypes.data[0].title_field
        r.entityTypes.data[0].number_field_id = r.entityTypes.data[0].number_field
        r.entityTypes.data[0].third_field_id = r.entityTypes.data[0].third_field
        r.entityTypes.data[0].fourth_field_id = r.entityTypes.data[0].fourth_field
        r.entityTypes.data[0].fifth_field_id = r.entityTypes.data[0].fifth_field
        r.entityTypes.data[0].sixth_field_id = r.entityTypes.data[0].sixth_field
        setData(r.entityTypes.data[0]);
        if (!params.tab && r.entityTypes.data[0].fields_count === 0) {
          setNotification(intl.formatMessage({
            id: "entity_types.edit.notifications.no_fields",
            defaultMessage: "This entity type has no fields, you need to create some before you can continue with the other settings."
          }))
          setTab(tabs.length - 2);
        }
      }
    });
    setIsLoading(false)
  }, []);

  useEffect(() => setHasUnsavedChanges(false), [params.tabId]);

  const changeTab = (newTab) => {
    setTab(newTab);
    setHasUnsavedChanges(false);
    navigate('/admin/data-types/' + id + '/' + tabs[newTab].url);
  }

  return <>
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      message={notification}
      open={!!notification}
      onClose={_ => setNotification(null)}
    />
    <ConfirmDialog
      onClose={confirmation.onClose}
      open={!!confirmation.title}
      title={confirmation.title}
      message={confirmation.message}
    />
    <Card>
      <CardHeader
        avatar={<CallSplit/>}
        title={intl.formatMessage({
          id: "entity_types.edit.heading",
          defaultMessage: "Edit data type {title}"
        }, data)}
      />
      <AppBar position={"sticky"} color={"default"} variant={"outlined"} style={{zIndex: 1}}>
        <Tabs
          style={{flexGrow: 1}}
          value={tab}
          onChange={(_, v) => {
            if (hasUnsavedChanges) {
              setConfirmation({
                title: intl.formatMessage({
                  id: "entity_types.edit.unsaved_changes.title",
                  defaultMessage: "Unsaved changes!"
                }),
                message: intl.formatMessage({
                  id: "entity_types.edit.unsaved_changes.message",
                  defaultMessage: "There are unsaved changes on this tab, please save them first or press the 'Back' button to cancel your changes."
                }),
                onClose: accepted => {
                  if (accepted) {
                    changeTab(v);
                  }
                  closeConfirmation();
                }
              })
            } else {
              changeTab(v);
            }
          }}
          indicatorColor={"primary"}
          textColor={"primary"}
          color={"default"}
          variant={"scrollable"}
          scrollButtons={"auto"}
          aria-label={"Entity type tabs"}
        >{tabs.map((t, index) =>
          <Tab
            {...a11yProps(index)}
            key={"tab-" + index}
            icon={t.icon || (tab === index && hasUnsavedChanges && <Tooltip title={intl.formatMessage({
              id: "entity_types.edit.unsaved_changes.tooltip",
              defaultMessage: "There are unsaved changes on this tab!"
            })}><Announcement color="secondary"/></Tooltip>)}
            label={t.title}
            disabled={!id && index < tabs.length - 1}
          />
        )}</Tabs>
      </AppBar>
      {!isLoading && tabs.map((t, index) =>
        <TabPanel key={"tab-panel-" + index} value={tab} index={index}>
          {params.tabId ? t.edit : t.list}
        </TabPanel>
      )}
    </Card>
  </>;
}
