import {
  VpnKey,
  VerifiedUser, AccountCircle
} from "@material-ui/icons";
import ForgotPassword from "views/ForgotPassword";

import Login from "views/Login";
import Resend from "views/ResendVerification";
import Reset from "views/Reset";
import Verify from "views/Verify";
import Register from "../views/Register";

export default function shared() {
  return [
    {
      path: "/login",
      name: "Login",
      icon: VpnKey,
      component: Login,
      shared: true
    },
    {
      path: "/register",
      name: "Register",
      icon: AccountCircle,
      iconColor: "ErrorLight",
      component: Register,
    },
    {
      path: "/resend",
      name: "Resend verification",
      icon: VerifiedUser,
      component: Resend,
      shared: true
    },
    {
      path: "/verify",
      name: "Verify",
      icon: VerifiedUser,
      component: Verify,
      shared: true
    },
    {
      path: "/reset",
      name: "Reset",
      icon: VpnKey,
      component: Reset,
      shared: true
    },
    {
      path: "/forgot",
      name: "Forgot password",
      icon: VpnKey,
      component: ForgotPassword,
      shared: true
    }
  ];
}
