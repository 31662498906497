import React, {useContext, useState} from "react";
import {useIntl} from "react-intl";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import {authUser} from "../../../../contexts/Auth";
import {getFieldLabel} from "./Field";
import {LanguageContext} from "../../../../contexts/Layouts";
import {EntityContext} from "../../EntityLayoutEditor";
import {Add, Delete, Edit} from "@material-ui/icons";
import EnhancedTable from "../../../../components/DataTable/EnhancedTable";
import {rolePriorities} from "../../../../config";
import {getEntityTypeColumns, getEntityValueFromField} from "../../../../variables/fields";
import {useNavigate} from "react-router-dom";
import RenderEntityValue from "../RenderEntityValue";

export default function EntitiesField({
                                        field,
                                        fields,
                                        variants,
                                        entityTypes,
                                        ...rest
                                      }) {
  const {language} = useContext(LanguageContext);
  const navigate = useNavigate();
  const intl = useIntl();
  const {entity, getValuesOfField, setValuesOfField} = useContext(EntityContext);
  const [selectedEntity, setSelectedEntity] = useState({});
  const [showAddDialog, setShowAddDialog] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(null);
  let values = getValuesOfField(field.id)?.filter(v => !!v) || [];

  let entityTypeId = variants ? entity.entity_type_id : field?.type?.match(/^entities_(\d+)$/);
  if (Array.isArray(entityTypeId)) entityTypeId = entityTypeId[1];
  let entityType = entityTypes.find(et => et.id === entityTypeId);
  const columns = getEntityTypeColumns(entityType, intl);
  if (variants) {
    values = variants.map(v => ({field: null, related_entity_id: v.id, related_entity: v}));
    columns.shift();
    entity.variant_fields.forEach(vf => {
      let f = fields[vf.id];
      columns.push({
        field: f.id,
        title: f.name,
        render: row => <RenderEntityValue entity={row} field={f}/>
      });
    });
  }
  let allowEdit = true;
  if (!entityType) allowEdit = false;
  const filter = variants ? variants.map(v => v.id) : values.map(v => v?.related_entity?.id).filter(i => !!i);
  // const relatedValueIds = values.map(v => ({
  //   id: v.related_entity.id,
  //   value_id: v.related_entity.entity_value_id
  // }));

  const actions = [
    {
      disabled: () => !entity.id,
      label: intl.formatMessage({id: "common.button.add", defaultMessage: "Add"}),
      isFreeAction: true,
      icon: Add,
      tooltip: '',
      onClick: () => {
        setShowAddDialog({
          transformedColumns: columns,
          entityType: entityType?.title,
          entityTypeId: entityTypeId,
          filter: filter
        });
      }
    },
    {
      disabled: () => !entity.id,
      label: intl.formatMessage({id: "common.button.create", defaultMessage: "Create"}),
      variant: 'contained',
      color: 'secondary',
      isFreeAction: true,
      icon: Add,
      tooltip: '',
      onClick: () => {
        navigate({
          pathname: '/data/' + entityTypeId + '/entity/create',
          search: !variants ? 'related=' + entity.id + '&related_field=' + field.id : ''
        });
      }
    },
    {
      tooltip: intl.formatMessage({id: "common.button.edit", defaultMessage: "Edit"}),
      icon: Edit,
      rowClick: true,
      onClick: (row) => {
        navigate('/data/' + entityTypeId + '/entity/' + row.related_entity.id);
      }
    },
    {
      disabled: () => !entity.id,
      tooltip: intl.formatMessage({id: "common.button.delete", defaultMessage: "Delete"}),
      icon: Delete,
      onClick: (row) => {
        setSelectedEntity(row);
        setShowDeleteDialog(true);
        // setShowDeleteDialog({
        //   relatedValueIds: relatedValueIds,
        // });
      }
    }
  ];
  // console.log(fields, values, entityType, columns);
  const hasRoleAccess =
    allowEdit
    && authUser().isAllowed(rolePriorities.can_data)
    && (authUser().roles?.map(r => r.id).some(e => entityType?.roles.map(r => parseInt(r.id)).includes(e)));
  return <FormControl
    fullWidth
    variant="standard"
    key={'field-entities-' + rest.nodeId}
  >
    <Box style={{display: "flex", gap: "1rem", alignItems: "center", justifyContent: "flex-end"}} mb={1}>
      <InputLabel shrink style={{flexGrow: 1}}>{getFieldLabel(field, language)}</InputLabel>
      {actions.filter(a => hasRoleAccess && a.isFreeAction).map((a, i) =>
        <Button key={'field-entities-' + field.id + '-free-action-' + i} disabled={a.disabled()} variant="outlined"
                size="small" onClick={a.onClick}
                startIcon={<a.icon/>}>{a.label}</Button>
      )}
    </Box>
    <TableContainer>
      <Table padding="none" size="small">
        <TableHead><TableRow>{columns.map((c, i) =>
          <TableCell key={'field-entities-' + field.id + '-header-' + i}>{c.title}</TableCell>
        )}<TableCell>&nbsp;</TableCell>
        </TableRow></TableHead>
        <TableBody>{!values.length
          ? <TableRow>
            <TableCell
              style={{padding: 8, fontStyle: "italic", textAlign: "center"}}
              colSpan={columns.length + 2}
            >{
              intl.formatMessage({
                id: "entity.fields.entities.empty",
                defaultMessage: "There isn't any {title} linked to this field"
              }, {title: entityType?.title})
            }</TableCell>
          </TableRow>
          : values.filter(v => !!v.related_entity).sort((a, b) => {
            let av = getEntityValueFromField(fields[columns[1].field], a.related_entity.values.find(v => v.field_id === columns[1].field), language.id);
            let bv = getEntityValueFromField(fields[columns[1].field], b.related_entity.values.find(v => v.field_id === columns[1].field), language.id);
            if (typeof av === "string") {
              return av.localeCompare(bv, language.locale, {sensitivity: 'base'});
            } else {
              if (av < bv) return -1;
              if (av > bv) return 1;
              return 0;
            }
          }).filter(v => !v.delete).map((v, ri) =>
            <TableRow key={'field-entities-' + field.id + '-row-' + ri}
                      style={{cursor: actions.find(a => hasRoleAccess && a.rowClick) ? 'pointer' : 'inherit'}}
            >{columns.map((c, ci) =>
              <TableCell
                onClick={_ => {
                  actions.find(a => hasRoleAccess && a.rowClick)?.onClick(v)
                }}
                key={'field-entities-' + field.id + '-row-' + ri + '-cell-' + ci}
              >{c.field === 'id'
                ? v.related_entity.id
                : getEntityValueFromField(fields[c.field], v.related_entity.values?.find(ev => ev.field_id === c.field), language.id)
              }</TableCell>
            )}<TableCell
              key={'field-entities-' + field.id + '-row-' + ri + '-actions'}
              align="right">{actions.filter(a => hasRoleAccess && !a.isFreeAction).map((a, i) =>
              <IconButton
                key={'field-entities-' + field.id + '-row-action-' + i}
                disabled={typeof a.disabled === "function" ? a.disabled() : a.disabled}
                size="small"
                onClick={_ => a.onClick(v)}
              >
                <a.icon/>
              </IconButton>
            )}</TableCell>
            </TableRow>
          )}</TableBody>
      </Table>
    </TableContainer>
    {showAddDialog !== null && <Dialog open={true} onClose={() => setShowAddDialog(null)} maxWidth="lg">
      <EnhancedTable
        title={intl.formatMessage({
          id: 'product.edit.dialog.select_entities',
          defaultMessage: "Choose one or more existing {entityType}"
        }, {entityType: showAddDialog.entityType})}
        columns={showAddDialog.transformedColumns}
        actions={[
          {
            tooltip: intl.formatMessage({id: 'common.button.add'}) + " " + showAddDialog.entityType,
            icon: Add,
            onSelected: true,
            onClick: (rows) => {
              setValuesOfField(field.id, [...values, ...rows.map(e => ({related_entity: e, related_entity_id: e.id}))]);
              setShowAddDialog(null);
            }
          }
        ]}
        query="entities"
        filter={"entity_type_id:" + showAddDialog.entityTypeId + ", ids_not:[" + showAddDialog.filter.join(",") + "], parent_id:null"}
        fields={'id entity_type{id title} values{id field_id language_id language{id} field_option{title} text string integer decimal bool date}'}
      />
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setShowAddDialog(null)}
        >{
          intl.formatMessage({id: 'common.button.close'})
        }</Button>
      </DialogActions>
    </Dialog>}
    {showDeleteDialog !== null && <Dialog open={true} onClose={() => setShowDeleteDialog(null)}>
      <DialogTitle disableTypography>
        <Typography
          variant="h2"
          color="primary">{
          intl.formatMessage({
            id: 'product.edit.dialog.delete_entity.title',
            defaultMessage: "How do you want to remove the {entityType}"
          }, {entityType: selectedEntity.entity_type?.title})
        }</Typography>
      </DialogTitle>
      <DialogContent>{intl.formatMessage({
        id: 'product.edit.dialog.delete_entity.message',
        defaultMessage: "Do you want to delete the relation to the {entityType} or the related entity itself?"
      }, {entityType: selectedEntity.entity_type?.title})}</DialogContent>
      <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
        <Button variant="contained" onClick={() => setShowDeleteDialog(null)}>{intl.formatMessage({
          id: "common.button.cancel",
          defaultMessage: "Cancel"
        })}</Button>
        <Button
          onClick={() => {
            setValuesOfField(field.id,
              [...(values?.filter(v => selectedEntity.related_entity.id !== v.related_entity?.id) || []),
                {...selectedEntity, related_entity: null, related_entity_id: null}
              ]
            );
            setShowDeleteDialog(null);
          }}
          variant="contained"
          color="primary"
          style={{marginRight: 10}}>{
          intl.formatMessage({id: 'common.button.remove'})
        }</Button>
      </DialogActions>
    </Dialog>}
  </FormControl>;
}
