import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {
  AppBar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import {Save} from '@material-ui/icons';
import {useAuthDispatch} from 'contexts/Auth';
import {graphQLApi} from 'services/GraphQLApi';
import {Editor, Element, Frame, useEditor} from "@craftjs/core";
import SettingsPanel from "components/Layouts/SettingsPanel";
import {Text, TextButton} from "components/Layouts/Nodes/Text";
import {Paragraph, ParagraphButton} from "components/Layouts/Nodes/Paragraph";
import {Asset, AssetButton} from "components/Layouts/Nodes/Asset";
import {Field, FieldButton} from "components/Layouts/Nodes/Field";
import {Cell, Table, TableButton} from "components/Layouts/Nodes/Table";
import {Block, BlockButton} from "components/Layouts/Nodes/Block";
import {Fieldset, FieldsetButton} from "../../../../components/Layouts/Nodes/Fieldset";
import {Variant, VariantButton} from "../../../../components/Layouts/Nodes/Variant";

function Topbar(props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const id = props.id ? props.id : Number(params.id);
  const client = new graphQLApi(useAuthDispatch());
  const [isLoading, setIsLoading] = useState(false);
  const {query, actions} = useEditor();

  const [layout, setLayout] = useState({
    title: '',
    sorting: 10,
    entity_type_id: props.entityTypeId,
    layout: [],
    roles: [],
  });
  const [roles, setRoles] = useState([]);

  const listingQueryFields = 'id title sorting layout roles{id}';
  useEffect(() => {
    setIsLoading(true);
    let query = 'roles{data{id title}}';
    if (!props.entityTypeId) {
      query += ' entityTypes{data{id title}}';
    }
    if (id > 0) {
      query = query + ' entityTypeLayouts(filter:{id:' + id + '}){data{' + listingQueryFields + '}}';
    }
    client.query('{' + query + '}').then(resp => {
      if (resp?.hasOwnProperty('roles')) {
        setRoles(resp.roles.data);
        if (!Number(id)) {
          setLayout({...layout, roles: resp.roles.data.map(r => r.id)})
        }
      }
      if (resp?.hasOwnProperty('entityTypeLayouts') && resp?.hasOwnProperty('roles')) {
        let l = resp.entityTypeLayouts.data[0]?.layout;
        console.log(resp.entityTypeLayouts.data, l);
        setLayout({
          ...resp.entityTypeLayouts.data[0],
          entity_type_id: props.entityTypeId,
          layout: l && actions.deserialize(l),
          roles: resp.entityTypeLayouts.data[0]?.roles.map(r => r.id),
        });
        if (props.setModified) props.setModified(false);
      }
      setIsLoading(false);
    });
  }, []);

  const saveListing = () => {
    let vars = {
      entity_type_id: 'ID',
      title: 'String',
      layout: 'String',
      sorting: 'Int',
      roles: '[ID]',
    };
    let data = {...layout, layout: query.serialize()};
    if (props.entityTypeId) {
      data.entity_type_id = props.entityTypeId;
    }
    if (id > 0) {
      vars.id = 'ID!';
    }
    data.roles = data.roles.filter(r => !!r).map(r => typeof r === "object" ? r.id : r);
    setIsLoading(true);
    client.mutation('entityTypeLayout', vars, data, listingQueryFields).then(resp => {
      if (resp?.hasOwnProperty('response')) {
        setLayout({...resp.response, roles: resp.response.roles ? resp.response.roles : []});
        if (props.setModified) props.setModified(false);
        if (props.entityTypeId) navigate(-1);
        else navigate(location.pathname.replace('/create', '/' + resp.response.id), {replace: true});
      }
      setIsLoading(false);
    });
  };

  const handleChange = (d) => {
    setLayout(d);
    if (props.setModified) props.setModified(true);
  }
  console.log(isLoading, layout)
  return <Grid container>
    <Grid item xs={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            autoFocus
            fullWidth
            label={intl.formatMessage({
              id: 'entity_type_layouts.edit.label.title',
              defaultMessage: 'Title',
            })}
            value={layout.title}
            onChange={e => handleChange({...layout, title: e.target.value})}
          />
        </Grid>
        <Grid item xs={12}>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={1}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={intl.formatMessage({
              id: 'entity_type_layouts.edit.label.sorting',
              defaultMessage: 'Sorting',
            })}
            value={layout.sorting}
            onChange={e => handleChange({...layout, sorting: e.target.value})}
          />
        </Grid>
        <Grid item xs={12}>
        </Grid>
      </Grid>
    </Grid>
    <Grid item style={{flexGrow: 1}}>
      <TextField
        fullWidth
        label={intl.formatMessage({
          id: 'entity_type_layouts.edit.label.roles',
          defaultMessage: 'User with these roles can see this tab',
        })}
        select
        SelectProps={{
          multiple: true,
          renderValue: selected => <div>{selected?.map((value, i) =>
            <Chip style={{marginRight: 4}} size="small" key={'layout-roles-chip-' + i}
                  label={roles.find(r => r.id === value)?.title}/>
          )}</div>,
          SelectDisplayProps: {
            style: {padding: 7},
          },
        }}
        value={layout.roles || []}
        onChange={e => handleChange({...layout, roles: e.target.value})}
      >{roles.map(r =>
        <MenuItem key={"layout-roles-" + r.id} value={r.id}>{r.title}</MenuItem>
      )}</TextField>
    </Grid>
    <Grid item>
      <Button
        variant="outlined"
        // size="small"
        onClick={_e => navigate(-1)}
      >{intl.formatMessage({id: 'common.button.back'})}</Button>&nbsp;&nbsp;&nbsp;&nbsp;
      <Button
        // size="small"
        color="primary"
        variant="contained"
        startIcon={isLoading ? <CircularProgress size={20}/> : <Save/>}
        disabled={isLoading || !layout.title || !layout.entity_type_id || !layout.roles.length}
        onClick={saveListing}
      >{intl.formatMessage({id: 'common.button.save'})}</Button>
    </Grid>
  </Grid>;
}

const useStyles = makeStyles({
  positionSticky: {
    top: 60,
  }
});

const Toolbox = () => {
  const {connectors} = useEditor();
  const intl = useIntl();
  return <Grid container direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
    <Grid item xs={12} style={{textAlign: "center"}}>
      <Typography>{intl.formatMessage({
        id: "layouts.toolbox.nodes-help",
        defaultMessage: "Drag to add"
      })}</Typography>
    </Grid>
    <Grid item><VariantButton connectors={connectors}/></Grid>
    <Grid item><FieldButton connectors={connectors}/></Grid>
    <Grid item><TextButton connectors={connectors}/></Grid>
    <Grid item><ParagraphButton connectors={connectors}/></Grid>
    <Grid item><AssetButton connectors={connectors}/></Grid>
    <Grid item><TableButton connectors={connectors}/></Grid>
    <Grid item><BlockButton connectors={connectors}/></Grid>
    <Grid item><FieldsetButton connectors={connectors}/></Grid>
  </Grid>;
};

const Layout = ({children}) => {
  return <div
    style={{
      width: "100%",
      minHeight: 16,
    }}>
    <Element
      is="div"
      id="Layout"
      canvas
      style={{
        height: "100%",
        width: "100%",
        minHeight: 16,
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
      }}
    >{children}</Element>
  </div>
}

export default function LayoutEdit(props) {
  const {id, elevation = 2, margin = 3, ...rest} = props;
  const intl = useIntl();
  const classes = useStyles();

  return <Card style={{margin: margin}} elevation={elevation}>
    <CardHeader title={id > 0 ? intl.formatMessage({
      id: "entity_type_layouts.edit.heading",
      defaultMessage: "Edit form tab"
    }) : intl.formatMessage({
      id: "entity_type_layouts.create.heading",
      defaultMessage: "Create form tab"
    })}/>
    <CardContent>
      <Editor
        resolver={{Layout, Field, Text, Paragraph, Asset, Table, Cell, Block, Fieldset, Variant}}
        onNodesChange={() => props.setModified && props.setModified(true)}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}><Topbar id={id} {...rest}/></Grid>
          <Grid item xs={9}>
            <Paper>
              <Grid container item spacing={1} style={{padding: "0 4px"}}>
                <Frame>
                  <Element
                    canvas
                    is={Layout}
                    padding={4}
                  />
                </Frame>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <AppBar position="sticky" color="default" classes={classes}>
              <Toolbox/>
              <SettingsPanel/>
            </AppBar>
          </Grid>
        </Grid>
      </Editor>
    </CardContent>
  </Card>;
}
