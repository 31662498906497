import CsvFile from './ConnectorDrivers/CsvFile';
import XmlFile from './ConnectorDrivers/XmlFile';
import FtpFileServer, {FtpFileServerTest} from './ConnectorDrivers/FtpFileServer';
import NettButikk, {NettButikkTest} from './ConnectorDrivers/NettButikk';
import SmartWeb, {SmartWebTest} from './ConnectorDrivers/SmartWeb';
import DxlFile from 'views/Admin/Settings/ConnectorDrivers/DxlFile';
import SalesforceXml from 'views/Admin/Settings/ConnectorDrivers/SalesforceXml';
import SalesforceLibraryXml from 'views/Admin/Settings/ConnectorDrivers/SalesforceLibraryXml';
import SmartWebOrders, {SmartWebOrdersTest} from './ConnectorDrivers/SmartWebOrders';
import DandomainClassic, {DandomainClassicTest} from './ConnectorDrivers/DandomainClassic';
import DandomainClassicOrders, {DandomainClassicOrdersTest} from './ConnectorDrivers/DandomainClassicOrder';
import CreativeCompanyProducts, {CreativeCompanyProductsTest} from './ConnectorDrivers/CreativeCompanyProducts';
import CreativeCompanyOrders, {CreativeCompanyOrdersTest} from './ConnectorDrivers/CreativeCompanyOrders';
import CreativeCompanyInventory, {CreativeCompanyInventoryTest} from './ConnectorDrivers/CreativeCompanyInventory';
import JsonFile from './ConnectorDrivers/JsonFile';
import RackBeatProducts, {RackBeatProductsTest} from "./ConnectorDrivers/RackBeatProducts";
import GeckoBookingItems, {GeckoBookingItemsTest} from "./ConnectorDrivers/GeckoBookingItems";
import ImapMail, {ImapMailTest} from "./ConnectorDrivers/ImapMail";

export function getConnectorDrivers(intl) {
  return [
    // {
    //   id: "Test",
    //   name: intl.formatMessage({id: "connector.driver.test.title", defaultMessage: "Test"})
    // },
    {
      id: "CsvFile",
      name: intl.formatMessage({id: "connector.driver.csv_file.title", defaultMessage: "CSV File"})
    },
    {
      id: "XmlFile",
      name: intl.formatMessage({id: "connector.driver.xml_file.title", defaultMessage: "XML File"})
    },
    {
      id: "JsonFile",
      name: intl.formatMessage({id: "connector.driver.json_file.title", defaultMessage: "JSON File"})
    },
    {
      id: "FtpFileServer",
      name: intl.formatMessage({id: "connector.driver.ftp_file_upload.title", defaultMessage: "File on an FTP server"})
    },
    {
      id: "SmartWeb",
      name: intl.formatMessage({id: "connector.driver.smartweb.title", defaultMessage: "SmartWeb/Dandomain Products"})
    },
    {
      id: "SmartWebOrders",
      name: intl.formatMessage({id: "connector.driver.smartweb_orders.title", defaultMessage: "SmartWeb/Dandomain Orders"})
    },
    {
      id: "NettButikk",
      name: intl.formatMessage({id: "connector.driver.nett_butikk.title", defaultMessage: "24NettButikk Shop API"})
    },
    {
      id: "DxlFile",
      name: intl.formatMessage({id: "connector.driver.dxl_file.title", defaultMessage: "Lotus Notes DXL File"})
    },
    {
      id: "SalesforceXml",
      name: intl.formatMessage({id: "connector.driver.salesforce_xml.title", defaultMessage: "Salesforce XML Files"})
    },
    {
      id: "SalesforceLibraryXml",
      name: intl.formatMessage({id: "connector.driver.salesforce_xml.title", defaultMessage: "Salesforce Library XML"})
    },
    {
      id: "DandomainClassic",
      name: intl.formatMessage({id: "connector.driver.dandomain_classic.title", defaultMessage: "DanDomain Classic Products"})
    },
    {
      id: "DandomainClassicOrders",
      name: intl.formatMessage({id: "connector.driver.dandomain_classic_orders.title", defaultMessage: "DanDomain Classic Orders"})
    },
    {
      id: "CreativeCompanyProducts",
      name: intl.formatMessage({id: "connector.driver.creative_company_products.title", defaultMessage: "Creative Company Products"})
    },
    {
      id: "CreativeCompanyOrders",
      name: intl.formatMessage({id: "connector.driver.creative_company_orders.title", defaultMessage: "Creative Company Orders"})
    },
    {
      id: "CreativeCompanyInventory",
      name: intl.formatMessage({id: "connector.driver.creative_company_inventory.title", defaultMessage: "Creative Company Inventory"})
    },
    {
      id: "RackBeatProducts",
      name: intl.formatMessage({id: "connector.driver.rackbeat_products.title", defaultMessage: "RackBeat Products"})
    },
    {
      id: "GeckoBookingItems",
      name: intl.formatMessage({id: "connector.driver.gecko_booking_items.title", defaultMessage: "Gecko Booking Items"})
    },
    {
      id: "ImapMail",
      name: intl.formatMessage({id: "connector.driver.imap_mail.title", defaultMessage: "IMAP/SMTP mail"})
    },
  ]
}
export const driverFields = {
  // "App\\Services\\ConnectorDrivers\\Test": () => [],
  "CsvFile": CsvFile,
  "XmlFile": XmlFile,
  "JsonFile": JsonFile,
  "FtpFileServer": FtpFileServer,
  "SmartWeb": SmartWeb,
  "SmartWebOrders": SmartWebOrders,
  "NettButikk": NettButikk,
  "DxlFile": DxlFile,
  "SalesforceXml": SalesforceXml,
  "SalesforceLibraryXml": SalesforceLibraryXml,
  "DandomainClassic": DandomainClassic,
  "DandomainClassicOrders": DandomainClassicOrders,
  "CreativeCompanyProducts": CreativeCompanyProducts,
  "CreativeCompanyOrders": CreativeCompanyOrders,
  "CreativeCompanyInventory": CreativeCompanyInventory,
  "RackBeatProducts": RackBeatProducts,
  "GeckoBookingItems": GeckoBookingItems,
  "ImapMail": ImapMail,
};
export const driverTestFields = {
  // "App\\Services\\ConnectorDrivers\\Test": () => [],
  "CsvFile": () => {},
  "XmlFile": () => {},
  "JsonFile": () => {},
  "FtpFileServer": FtpFileServerTest,
  "SmartWeb": SmartWebTest,
  "SmartWebOrders": SmartWebOrdersTest,
  "NettButikk": NettButikkTest,
  "DandomainClassic": DandomainClassicTest,
  "DandomainClassicOrders": DandomainClassicOrdersTest,
  "DxlFile": () => {},
  "SalesforceXml": () => {},
  "SalesforceLibraryXml": () => {},
  "CreativeCompanyProducts": CreativeCompanyProductsTest,
  "CreativeCompanyOrders": CreativeCompanyOrdersTest,
  "CreativeCompanyInventory": CreativeCompanyInventoryTest,
  "RackBeatProducts": RackBeatProductsTest,
  "GeckoBookingItems": GeckoBookingItemsTest,
  "ImapMail": ImapMailTest,
};
