import React, {useCallback, useEffect, useState} from 'react';
import EditForm from '../Form/EditForm';
import {graphQLApi, graphQLReduceFields} from 'services/GraphQLApi';
import {useAuthDispatch} from 'contexts/Auth';
import {NotificationsActive} from '@material-ui/icons';
import {useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Snackbar} from '@material-ui/core';
import {useLocation, useNavigate} from "react-router-dom";

export default function GraphQLEditForm(props) {
  const {
    id,
    query,
    mutations,
    fields,
    cols,
    colSizes,
    redirectToEdit,
    extraArgs = "",
    extraFields = "",
    extraQueries = "",
    extraResults = [],
    extraComponent = null,
    buttons = [],
    onSave,
    back = true
  } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const [notification, setNotification] = useState({
    type: undefined,
    msg: "",
  });
  const hideAlert = () => {
    setNotification({ type: undefined, msg: "" });
  };
  useEffect(() => {
    if (notification) {
      setTimeout(hideAlert, 7500);
    }
  }, [notification]);

  const initialValidation = graphQLReduceFields(fields, "validation");
  const [validation, setValidation] = useState(initialValidation);
  const setValidationFromErrors = (errors) => {
    if (errors && errors[0] && errors[0]["extensions"]) {
      if (errors[0]["extensions"]["validation"]) {
        setValidation({...initialValidation, ...errors[0]["extensions"]["validation"]});
      }
      if (errors[0]["extensions"]["category"] === 'internal') {
        alert(errors[0]["message"] + '\n' + errors[0]["debugMessage"]);
      }
    }
    setIsLoading(false);
  };
  const client = new graphQLApi(
    useAuthDispatch(),
    setValidationFromErrors
  );

  const [data, setData] = useState(graphQLReduceFields(fields, "initial"));
  const setDataFromQuery = (qData, extraData = {}) => {
    for (let i in fields) {
      if (fields[i].key) {
        if (qData[fields[i].field] === undefined) {
          qData[fields[i].field] = {};
        }
        let value = "";
        let rawData = qData[fields[i].field];
        qData[fields[i].field] = {};
        for (let k in rawData) {
          let fieldKey = rawData[k][fields[i].key];
          if (fields[i].titleField && fields[i].titleField.indexOf(".") !== -1) {
            value = fields[i].titleField
              .split(".")
              .reduce((o, k) => o[k], rawData[k]);
          } else {
            value = rawData[k][fields[i].titleField];
          }
          if (value === null || value === undefined) {
            value = fields[i].initial;
          }
          qData[fields[i].field] = {
            ...qData[fields[i].field],
            [fieldKey]: value,
          };
        }
      } else {
        if (fields[i].type === "ID") {
          let fieldName = fields[i].field.substr(0, fields[i].field.length - 3);
          if (qData.hasOwnProperty(fieldName)) {
            if (qData[fieldName] === null) {
              qData[fields[i].field] = fields[i].initial;
            } else {
              qData[fields[i].field] = qData[fieldName];
            }
          }
        }
        if (
          ["date", "datetime", "time"].indexOf(fields[i].input) !== -1 &&
          qData[fields[i].field] !== null
        ) {
          qData[fields[i].field] = moment(qData[fields[i].field]);
        }
        if (qData[fields[i].field] === null) {
          qData[fields[i].field] = fields[i].initial;
        } else if (fields[i].editor) {
          fields[i].editor.setData(qData[fields[i].field]);
        }
      }
    }
    setData({ ...data, ...qData, ...extraData });
  };

  const fetchFields = graphQLReduceFields(fields, "fields") + " " + extraFields;
  const stableSetDataFromQuery = useCallback(setDataFromQuery, []);
  const stableClient = useCallback(client, []);
  useEffect(() => {
    setIsLoading(true);
    let addQueries = extraQueries;
    let addResults = extraResults;
    for (let k in fields) {
      if (fields[k].key && fields[k].query) {
        addResults.push(fields[k].field + "_" + fields[k].key);
        addQueries +=
          fields[k].field + "_" + fields[k].key + ": " + fields[k].query;
      }
    }
    stableClient
      .query(
        '{ '+(id?'response: ' + query + '(filter:{id:'+id+'}) { data { '+fetchFields+' } } ':'')+addQueries+' }'
      )
      .then((result) => {
        let extraData = {};
        for (let k in addResults) {
          extraData[addResults[k]] = result[addResults[k]];
        }
        stableSetDataFromQuery(result.response ? result.response.data[0] : {}, extraData);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error("Caught exception", e);
      });
  }, [stableClient, fetchFields, id, stableSetDataFromQuery, query, fields]);

  const save = () => {
    setValidation(initialValidation);
    let query =
      "(" +
      graphQLReduceFields(fields, "vars_def") +
      ") " +
      "{ response: " +
      mutations +
      "Create(" +
      graphQLReduceFields(fields, "vars") +
      (extraArgs !== null ? " " + extraArgs : "") +
      ") " +
      "{ id " +
      graphQLReduceFields(fields, "fields") + " " + extraFields +
      " } }";
    let variables = {};
    for (let i in fields) {
      let fieldName = fields[i].field;
      switch (fields[i].type) {
        case "ID":
          variables[fieldName] = typeof data[fieldName] === "object" ? (data[fieldName] ? data[fieldName].id  : null): data[fieldName] > 0 ? data[fieldName] : null;
          break;
        case "[ID]":
          variables[fieldName] = data[fieldName]
            ? data[fieldName].map((v) => v.id)
            : [];
          break;
        case "Float":
          variables[fieldName] =
            data[fieldName] === "" ? null : parseFloat(data[fieldName]);
          break;
        case "Int":
        case "Integer":
          variables[fieldName] =
            data[fieldName] === "" ? null : parseInt(data[fieldName]);
          break;
        case "Json":
          variables[fieldName] = JSON.stringify(data[fieldName]);
          break;
        default:
          variables[fieldName] = data[fieldName];
          break;
      }
      switch (fields[i].input) {
        case "file":
          query = query.replace(
            "$" + fieldName + ":String",
            "$" + fieldName + ":String, $" + fieldName + "_data:String"
          );
          query = query.replace(
            fieldName + ":$" + fieldName,
            fieldName +
              ":$" +
              fieldName +
              ", " +
              fieldName +
              "_data:$" +
              fieldName +
              "_data"
          );
          variables[fieldName + "_data"] = data[fieldName + "_data"];
          break;
          case "date":
            if (data[fieldName])
              variables[fieldName] = moment(data[fieldName]).format("YYYY-MM-DD");
            break;
          case "datetime":
            if (data[fieldName])
              variables[fieldName] = moment(data[fieldName]).format("YYYY-MM-DD HH:mm:ss");
            break;
          case "time":
            if (data[fieldName])
              variables[fieldName] = moment(data[fieldName]).format("HH:mm:ss");
          break;
        default:
          break;
      }
      if (fields[i].required && !data[fieldName]) {
        let o = {};
        o[fieldName] = [intl.formatMessage({ id: "common.required_field" })];
        setValidation({ ...initialValidation, ...o });
        setNotification({
          type: "danger",
          msg: intl.formatMessage({
            id: "common.notification.missing_required",
          }),
        });
        return;
      }
    }
    if (id) {
      variables.id = id;
      // modify query from a create to an update mutation
      query =
        "($id:ID!, " + query.substr(1).replace("Create(", "Update(id:$id, ");
    }
    setIsLoading(true);
    client.mutate(query, variables).then((result) => {
      if (result) {
        if (result.response) {
          setDataFromQuery(result.response);
          setNotification({
            type: "success",
            msg: intl.formatMessage({
              id: "common.notification.success",
              defaultMessage: "Successfully saved!",
            }),
          });
          if (!onSave) {
            if (redirectToEdit) {
              // should we redirect to edit after creation
              navigate(location.pathname.replace("create", result.response.id), {replace: true});
            } else {
              // else when editing we redirect to list
              navigate(-1);
            }
          }
          else {
            onSave({...variables, id: result.response.id});
          }
        }
      }
      setIsLoading(false);
    });
  };

  return (
    <div>
      <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        color={notification.type}
        icon={NotificationsActive}
        message={notification.msg}
        open={notification.type !== undefined}
        onClose={hideAlert}
        autoHideDuration={6000}
      />
      <EditForm
        fields={fields}
        cols={cols}
        colSizes={colSizes}
        isLoading={isLoading}
        data={data}
        buttons={buttons}
        setData={setData}
        save={save}
        validation={validation}
        back={back}
        extraComponent={extraComponent}
      />
    </div>
  );
}

GraphQLEditForm.propTypes = {
  id: PropTypes.number,
  query: PropTypes.string.isRequired,
  mutations: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      initial: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
        PropTypes.number,
      ]),
      type: PropTypes.oneOf([
        "ID",
        "[ID]",
        "String",
        "Email",
        "Json",
        "Int",
        "Float",
        "Boolean",
      ]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      input: PropTypes.string,
      fullWidth: PropTypes.bool,
      required: PropTypes.bool,
      disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      titleField: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      query: PropTypes.string,
      filter: PropTypes.string,
      filterBy: PropTypes.string,
      accept: PropTypes.string,
      lines: PropTypes.number,
    })
  ),
  cols: PropTypes.number,
  colSizes: PropTypes.arrayOf(PropTypes.number),
};
