import React, {useContext, useEffect, useState} from "react";
import {FormControl, Grid, IconButton, InputLabel, LinearProgress} from "@material-ui/core";
import {LanguageContext} from "../../../../contexts/Layouts";
import {EntityContext} from "../../EntityLayoutEditor";
import {getFieldLabel} from "./Field";
import {graphQLApi} from "../../../../services/GraphQLApi";
import {useAuthDispatch} from "../../../../contexts/Auth";
import {makeStyles} from "@material-ui/core/styles";
import theme from "../../../../assets/theme/theme";
import {ArrowDropDown, ArrowDropUp, Check} from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme) => ({
  progressCompleted: {
    background: 'yellow',
    backgroundColor: theme.palette.success.dark,
  }
}));

export default function CompletionField({field}) {
  const intl = useIntl();
  const {language} = useContext(LanguageContext);
  const {getValuesOfField} = useContext(EntityContext);
  let value = getValuesOfField(field.id)?.pop()?.decimal || 0;
  const classes = useStyles();
  const [showFields, setShowFields] = useState(false);

  const [compFields, setCompFields] = useState([]);
  const client = new graphQLApi(useAuthDispatch());
  useEffect(() => {
    client.query('{fields(filter:{id:' + field.id + '}){data{id computation_fields{id name type}}}}').then(r => {
      if (r.hasOwnProperty('fields') && r.fields.data[0]) {
        setCompFields(r.fields.data[0].computation_fields || []);
      }
    });
  }, []);
  let values = [];
  compFields.forEach(f => getValuesOfField(f.id).forEach(v => values.push(v)));
  const hasValue = (fieldId) => {
    for (let i in values) {
      if (values[i].field?.id === fieldId || values[i].field_id === fieldId) return true;
    }
    return false;
  }
  let buffer = compFields.filter(cf => hasValue(cf.id)).length / compFields.length * 100;

  return <FormControl
    fullWidth
    style={{cursor: "pointer"}}
    onClick={() => setShowFields(!showFields)}
    title={intl.formatMessage({
      id: "entity.edit.fields.completion.clickable",
      defaultMessage: "Click here to see the fields being checked and their statuses"
    })}
  >
    <InputLabel shrink>{getFieldLabel(field, language)}</InputLabel>
    <Box display="flex" style={{gap: 8}} width="100%" alignItems="flex-start">
      <Box style={{flexGrow: 1, paddingTop: 24}}>
        <LinearProgress
          classes={{barColorPrimary: value >= 100 && classes.progressCompleted}}
          variant="buffer"
          value={value}
          valueBuffer={buffer}
        />
      </Box>
      <Box display="flex" pt={1.5}>
        <Box style={{
          flexGrow: 1,
          paddingTop: 6,
          color: value >= 100 && theme.palette.success.dark
        }}>{parseInt(buffer > value ? buffer : value)}%</Box>
        <IconButton size="small">{showFields ? <ArrowDropUp/> : <ArrowDropDown/>}</IconButton>
      </Box>
    </Box>
    {showFields && <Grid item style={{width: "100%"}}>{compFields?.map(f =>
      <Grid
        container
        key={'completeion_of_' + field.id + '_on_field_' + f.id}
        style={{color: hasValue(f.id) ? theme.palette.success.dark : 'inherit'}}
      >
        <Grid item style={{width: 30}}>{hasValue(f.id) && <Check fontSize={"small"}/>}</Grid>
        <Grid item style={{flexGrow: 1}}>{f.name}</Grid>
      </Grid>)}
    </Grid>}
  </FormControl>;
}
