import {config, getLayouts} from 'config';
import SinglePage from 'layouts/SinglePage';
import React, {createContext, useContext, useState} from 'react';
import {useIntl} from 'react-intl';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import UsersProfile from 'views/UsersProfile';
import {authUser, useAuthState} from './Auth';
import Versions from "../views/Versions";
import moment from "moment";
import AppOrder from "../views/AppOrder";
import {Dialog, DialogContent, DialogTitle, Grid, Paper} from "@material-ui/core";
import themeColors from "../assets/theme/colors";
import {LocaleContext} from './Intl';
import {SettingsProvider} from "./Settings";
import Box from "@material-ui/core/Box";

export const LanguageContext = createContext(null);


export default function Layouts() {
    let {isAuthenticated} = useAuthState();
    const intl = useIntl();
    const [showThankYou, setShowThankYou] = useState(false);
    const [_locale, setLocale] = useContext(LocaleContext);

    const getLayoutRoutes = () => {
        setLocale(authUser().locale)
        let routes = [];
        let defaultRoute = config.defaultRoute;
        const layouts = getLayouts(intl);
        if (isAuthenticated) {
            const midnight = moment().hour(0).minute(0);
            if (authUser().site.demo_expire_at && !authUser().site.order_sent_at && moment(authUser().site.demo_expire_at).isBefore(midnight)) {
                document.getElementsByTagName('body')[0].style.backgroundColor = themeColors.primary.light;
                routes.push(<Route
                    key={"app-order"}
                    path={"/"}
                    element={<Paper elevation={4} style={{padding: "2.5rem", margin: "5vh 20vh"}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}><img width={300} src={config.brandLogo.default}
                                                    alt={intl.formatMessage({id: "navbar.brand_text"})}/></Grid>
                            <Grid item xs={12} style={{
                                textAlign: "center",
                                fontSize: "larger"
                            }}>{intl.formatMessage({
                                id: "demo.expired.description",
                                defaultMessage: "Your demo has expired, we hope you were satisfied with your experience and want to order your own OpenDIMS."
                            })}</Grid>
                            <Grid item xs={12} style={{
                                textAlign: "center",
                                fontSize: "larger",
                                marginBottom: "1rem"
                            }}>{intl.formatMessage({
                                id: "demo.expired.contact",
                                defaultMessage: "If you have any questions or need help please do not hesitate to call {telephone} or write to us {email}."
                            }, {
                                telephone: <a href="tel:+4527507430">+45 2750 7430</a>,
                                email: <a href="mailto:info@opensunsystems.com">info@opensunsystems.com</a>
                            })}</Grid>
                          <AppOrder showThankYou={showThankYou} setShowThankYou={() => {
                                setShowThankYou(true);
                                document.getElementsByTagName('body')[0].style.backgroundColor = themeColors.background.default;
                            }}/>
                        </Grid>
                    </Paper>}
                />);
                return routes;
            }
            routes.push(<Route
                key={"user-profile"}
                path="/user-profile"
                element={<SinglePage routes={[
                    {
                      path: "",
                        component: UsersProfile,
                        hidden: true,
                    }
                ]}/>}
            />);
            routes.push(<Route
                key={"versions"}
                path="/versions"
                element={<SinglePage routes={[
                    {
                      path: "",
                        component: Versions,
                        hidden: true,
                    }
                ]}/>}
            />);
        }
        for (let key in layouts) {
            let layout = layouts[key];
            if ((!isAuthenticated && !layout.authenticated) || (isAuthenticated && layout.authenticated && authUser().isAllowed(layout.acl))) {
                routes.push(
                    <Route
                        key={"layouts-" + key}
                        path={layout.path + '/*'}
                        element={<layout.component routes={layout.routes}/>}
                    />
                )
                if (!defaultRoute && layout.defaultRoute) defaultRoute = layout.defaultRoute;
            }
        }
      console.log(defaultRoute, routes);
        if (defaultRoute) {
            routes.push(<Route
                key={"default-route"}
                path="/"
                element={<Navigate to={defaultRoute}/>}
            />);
        }
        return routes;
    }

  const [language, setLanguage] = useState({id: null, locale: 'da', name: 'Dansk', country_code: 'DK'});

    return <BrowserRouter basename={'/'}>
        <LanguageContext.Provider value={{language: language, setLanguage: setLanguage}}>
            <SettingsProvider>
              <Routes>
                    {getLayoutRoutes()}
              </Routes>
                {showThankYou && <Dialog maxWidth={"lg"} open={true}>
                    <DialogTitle
                        disableTypography
                        style={{
                            textAlign: "center",
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                            color: themeColors.primary.main
                        }}
                    >{intl.formatMessage({
                        id: "demo.dialog.thankyou.title",
                        defaultMessage: "Thank you for your order of OpenDIMS!"
                    })}</DialogTitle>
                    <DialogContent>
                        <AppOrder
                            showThankYou={showThankYou}
                            setShowThankYou={setShowThankYou}
                            setShowDialog={_e => {
                            }}
                        />
                    </DialogContent>
                </Dialog>}
            </SettingsProvider>
        </LanguageContext.Provider>
    </BrowserRouter>;
}
