import {Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Grid} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import {graphQLApi} from "../../../services/GraphQLApi";
import {authRefresh, authUser, useAuthDispatch} from "../../../contexts/Auth";
import {Image} from "@material-ui/icons";
import EditForm from "../../../components/Form/EditForm";


export default function Billing({}) {
  const intl = useIntl();
  const location = useLocation();
  const searchParams = useSearchParams();
  const [showAgreement, setShowAgreement] = useState(false);
  const [initialSupport, setInitialSupport] = useState(0);
  const [supportAgreements, setSupportAgreements] = useState([
    {id: 0, title: "None"},
    {id: 25, title: "25 hours"},
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [site, setSite] = useState({
    company: "",
    address: "",
    zip: "",
    city: "",
    country: "",
    logo: "",
    cvr: "",
    invoice_email: "",
    contact: "",
    phone: "",
    email: "",
    support: 0,
    agreed: false,
  });

  const initialValidation = {
    title: [],
    name: [],
    email: [],
    phone: [],
    locale: [],
    password: [],
    password_confirmation: [],
    site: {
      company: [],
      address: [],
      zip: [],
      city: [],
      country: [],
      logo: [],
      cvr: [],
      invoice_email: [],
      contact: [],
      phone: [],
      email: [],
      support: [],
      agreed: [],
    }
  };

  const [validation, setValidation] = useState(initialValidation);
  const setValidationFromErrors = (errors) => {
    if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
      setValidation({...initialValidation, ...errors[0].extensions.validation});
    }
  };
  const client = new graphQLApi(useAuthDispatch(), null, {handleErrors: setValidationFromErrors});
  useEffect(() => {
    setIsLoading(true);
    client.query('{' +
      'users(filter:{id:' + authUser().id + '}){data' +
      '{id name title phone email locale ' +
      'site{logo logo_uri company address zip city country cvr contact phone email invoice_email support}' +
      '}' +
      '}' +
      'supportAgreements{id Titel Timer Pris Betingelser}' +
      '}').then(r => {
      if (r && r.hasOwnProperty('users')) {
        setSite({...site, ...r.users.data[0].site});
        setSupportAgreements(r.supportAgreements);
        setInitialSupport(r.users.data[0].site.support);
      }
      setIsLoading(false);
    });
  }, []);

  const handleFileUpload = (field, file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      let n = {};
      n[field] = file.name;
      n[field + "_data"] = reader.result;
      console.log(n);
      setSite({...site, ...n});
    };
  };

  const save = () => {
    const data = {id: authUser().id, site: {...site}};
    delete data.site.logo_uri;
    const vars = {
      id: "ID!",
      site: "SiteInput",
    };
    setIsLoading(true);
    client.mutation('user', vars, data, 'id').then(r => {
      setIsLoading(false);
      if (r && r.hasOwnProperty('response')) {
        authRefresh(true).then(() => {
          window.location.reload();
        });
      }
    });
  }

  const fields = [
    {
      field: "company",
      label: intl.formatMessage({
        id: "sites.edit.label.company",
        defaultMessage: "Company name",
      }),
    },
    {
      field: "address",
      label: intl.formatMessage({
        id: "sites.edit.label.address",
        defaultMessage: "Address",
      }),
    },
    {
      field: "zip",
      label: intl.formatMessage({
        id: "sites.edit.label.zip",
        defaultMessage: "Zip",
      }),
    },
    {
      field: "city",
      label: intl.formatMessage({
        id: "sites.edit.label.city",
        defaultMessage: "City",
      }),
    },
    {
      field: "country",
      label: intl.formatMessage({
        id: "sites.edit.label.country",
        defaultMessage: "Country",
      }),
    },
    {
      column: 2,
      field: "cvr",
      label: intl.formatMessage({
        id: "sites.edit.label.cvr",
        defaultMessage: "VAT reg.",
      }),
    },
    {
      column: 2,
      field: "invoice_email",
      label: intl.formatMessage({
        id: "sites.edit.label.invoice_email",
        defaultMessage: "Invoice e-mail",
      }),
    },
    {
      column: 2,
      field: "contact",
      label: intl.formatMessage({
        id: "sites.edit.label.contact",
        defaultMessage: "Contact",
      }),
    },
    {
      column: 2,
      field: "phone",
      label: intl.formatMessage({
        id: "sites.edit.label.phone",
        defaultMessage: "Telephone",
      }),
    },
    {
      column: 2,
      field: "email",
      label: intl.formatMessage({
        id: "sites.edit.label.email",
        defaultMessage: "E-mail",
      }),
    },
  ];

  console.log(site.logo_data, site.logo_uri);

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} style={{paddingRight:0}}>
        {isLoading
          ? <center style={{margin: "3rem"}}><CircularProgress/></center>
          : <Card style={{
            borderRadius: 0,
            borderTopLeftRadius: "0.725rem",
            borderBottomLeftRadius: "0.725rem",
            boxShadow: "none",
          }}>
            <CardHeader
              avatar={<label title={intl.formatMessage({id:"sites.edit.label.logo", defaultMessage:"Click to set your logo"})}>
                <input type="file" accept="image/*" style={{display: "none"}}
                       onChange={e =>
                         handleFileUpload('logo', e.target.files[0])}/>
                {(site.logo_data || site.logo_uri) ? <img alt={site.name}
                      src={site.logo_data ? site.logo_data : site.logo_uri}
                      style={{maxWidth: 240, maxHeight: 120, cursor: "pointer"}}/> :
                  <Image style={{width: 120, height: 120, cursor: "pointer"}}/>}
              </label>}
              title={<FormattedMessage
              id={"user-profile.company.heading"}
              defaultMessage={"Your company"}
            />}/>
            <CardContent>
              <EditForm
                validation={validation.site}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                data={site}
                setData={setSite}
                fields={fields}
                cols={2}/>
            </CardContent>
            <CardActions style={{justifyContent: "flex-end"}}>
              <Button
                variant="contained"
                color="primary"
                onClick={save}
              ><FormattedMessage id={"common.button.save"}/></Button>
            </CardActions>
          </Card>}
      </Grid>
    </Grid>
  );
}
