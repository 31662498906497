import {Badge, Grid, IconButton, LinearProgress, makeStyles, Menu, MenuItem, Typography} from "@material-ui/core";
import {Storage} from "@material-ui/icons";
import {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import mqtt from "mqtt";
import {authUser} from "../../contexts/Auth";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    badge: {
        top: 14,
        right: 8,
        borderRadius: "1rem",
        transform: "scale(0.75) translate(50%, -50%)",
        border: "none",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    }
}));

export default function NavbarJobs() {
    const classes = useStyles();
    const intl = useIntl();

    const [jobs, setJobs] = useState({});
    const [queued, setQueued] = useState(0);
    const [waiting, setWaiting] = useState(0);
    const [menuAnchor, setMenuAnchor] = useState();

    useEffect(() => {
        let client = mqtt
            .connect(process.env.REACT_APP_MQTT_HOST, {
                port: process.env.REACT_APP_MQTT_PORT,
                username: process.env.REACT_APP_MQTT_USERNAME,
                password: process.env.REACT_APP_MQTT_PASSWORD,
                clientId: authUser().site.schema + '-' + authUser().id,
            })
            .on("error", error => {
                console.error(error);
                client.end();
            })
            .on('connect', () => {
                client.subscribe('jobs/' + authUser().site.schema + '/#');
            })
            .on('message', (topic, message) => {
                let match = topic.match(/^jobs\/.+\/(.+)$/);
                // console.log(match);
                if (match) {
                    message = JSON.parse(message.toString());
                    if (message) {
                        // console.log(match[1], message);
                        setJobs(cur => {
                            let n = {...cur};
                            let job = {...message, id: match[1]};
                            if (job.progress < 100)
                                n[job.id] = job;
                            else
                                delete n[job.id];
                            let q = 0;
                            let w = 0;
                            for (let id in n) {
                                q++;
                                if (n[id].progress === null) w++;
                            }
                            setQueued(q);
                            setWaiting(w);
                            return n;
                        });
                    }
                }
            });
        return () => {
            if (client?.connected)
                client.end();
        };
    }, []);

    const getJobsArray = () => {
        let a = [];
        for (let id in jobs) {
            a.push(jobs[id]);
        }
        return a;
    }

    return <>
        <Badge
            badgeContent={queued}
            anchorOrigin={{vertical: "top", horizontal: "right"}}
            classes={{badge: classes.badge}}
            overlap={"rectangular"}
            invisible={!queued}>
            <IconButton onClick={e => setMenuAnchor(e.target)}>
                <Storage/>
            </IconButton>
        </Badge>
        <Menu
            open={!!menuAnchor}
            anchorOrigin={{vertical: "top", horizontal: "right"}}
            anchorEl={menuAnchor}
            onClose={() => setMenuAnchor(undefined)}
        >
          <MenuItem disabled style={{opacity: 'unset'}}>
            <Typography variant="h3">{queued > 0
                ? intl.formatMessage({id: "jobs.heading", defaultMessage: "{queued} jobs in the queue, {waiting} jobs waiting"},{queued:queued, waiting:waiting})
                : intl.formatMessage({id: "jobs.heading_empty", defaultMessage: "No queued jobs"})
            }</Typography>
          </MenuItem>
          {queued ? getJobsArray().map(job => <MenuItem disabled key={"navbar-job-" + job.id}
                                                        style={{opacity: 'unset', width: 600}}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant={"overline"}>{job.name}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <LinearProgress
                    style={{marginTop: -4, marginBottom: -4}}
                    variant={"determinate"}
                    value={job.progress !== null ? job.progress : 0}/>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={"overline"} style={{float: 'right', fontSize: "small"}}>{job.progress === null
                        ? intl.formatMessage({
                            id: "jobs.waiting_since",
                            defaultMessage: "Job is waiting in queue since {started_at}"
                        }, {started_at: moment(job.started_at).format(intl.formatMessage({id:"common.datetime.format"}))})
                        : intl.formatMessage({
                            id: "jobs.estimated_finish_in",
                            defaultMessage: "Estimated time remaining {remaining_time}, processed {processed} of {total}, {progress}%"
                    }, job)}</Typography>
                </Grid>
              </Grid>
            </MenuItem>)
            : <MenuItem disabled style={{opacity: 'unset'}}>{intl.formatMessage({
              id: "jobs.no_jobs",
              defaultMessage: "There are no jobs on the queue."
            })}</MenuItem>
          }
        </Menu>
    </>;
}
