import React from "react";
import {Card, CardContent, CardHeader} from "@material-ui/core";
import {useIntl} from "react-intl";
import {Extension} from "@material-ui/icons";
import Field from "../../../../components/Fields/Field";
import {useParams} from "react-router-dom";

export default function FieldEdit(props) {
  const intl = useIntl();
  const params = useParams();

  let id = Number(props.id || params.id);
  const {elevation = 2, margin = 3, ...rest} = props;

  return <Card style={{margin: margin}} elevation={elevation}>
    <CardHeader color="primary" avatar={<Extension/>} title={intl.formatMessage({
      id: "fields.edit.heading",
      defaultMessage: "Edit field"
    })}/>
    <CardContent>
      <Field {...rest} id={id}/>
    </CardContent>
  </Card>;
}
