import React from "react";
import {useNode} from "@craftjs/core";
import {Button, FormControl, FormLabel, Slider, Typography} from "@material-ui/core";
import themeColors from "../../../assets/theme/colors";
import {useIntl} from "react-intl";
import {Jodit} from "jodit-pro-react";
import {joditConfig} from "../../../variables/general";
import {authHeader, getApiDomain} from "../../../contexts/Auth";

export const Asset = ({asset, width, ...props}) => {
    const {connectors: {connect, drag}} = useNode();
    const intl = useIntl();
    return asset ? <img
        {...props}
        src={asset}
        alt={asset?.split('/').pop()}
        title={asset?.split('/').pop()}
        ref={ref => connect(drag(ref))}
        style={{
            display: "inline",
            width: width + '%',
            border: "1px dashed " + themeColors.success.main,
            minWidth: 32,
        }}
    /> : <Typography
        ref={ref => connect(drag(ref))}
        style={{
            display: "inline-block",
            width: width + '%',
            border: "1px dashed " + themeColors.success.main,
        }}
    >{intl.formatMessage({id:"layouts.prop.asset-not-selected", defaultMessage:"No asset selected"})}</Typography>;
}

const AssetSettings = () => {
    const {
        asset,
        width,
        actions: {setProp}
    } = useNode((node) => ({
        asset: node.data.props.asset,
        width: node.data.props.width,
    }));
    const intl = useIntl();

    let fileBrowserPro = null;
    const handleShowAssetSelector = (onChange) => {
        if (!fileBrowserPro) {
            fileBrowserPro = new Jodit.modules.FileBrowserPro({
                license: joditConfig.license,
                width: window.innerWidth * 0.8,
                height: window.innerHeight * 0.8,
                moveFolder: true,
                moveFile: true,
                ajax: {
                    url: getApiDomain() + '/files/assets',
                    headers: authHeader(),
                },
                uploader: {
                    url: getApiDomain() + '/upload/assets',
                    headers: authHeader(),
                },
            });
        }
        if (!fileBrowserPro.isOpened) {
            fileBrowserPro.open(images => {
                onChange(images.files[0]);
            });
        }
    }

    return (
        <div>
            <Button
                fullWidth
                style={{marginTop:8,marginBottom:8}}
                variant="contained"
                title={intl.formatMessage({id:"layouts.prop.asset", defaultMessage:"Select asset"})}
                onClick={() => handleShowAssetSelector(value => setProp(props => props.asset = value))}
            >{  asset
                ? intl.formatMessage({id:"layouts.prop.asset-change", defaultMessage:"Change asset"})
                : intl.formatMessage({id:"layouts.prop.asset", defaultMessage:"Select asset"})
            }</Button>
            <FormControl size="small" component="fieldset" fullWidth>
                <FormLabel component="legend" style={{fontSize: "0.825rem"}}>{
                    intl.formatMessage({id: "layouts.prop.width", defaultMessage: "Width {width}"},{width:width+'%'})
                }</FormLabel>
                <Slider
                    value={width || 80}
                    step={5}
                    min={5}
                    max={100}
                    onChange={(_, value) => {
                        setProp(props => props.width = value);
                    }}
                />
            </FormControl>
        </div>
    )
}

export const AssetButton = ({connectors, ...rest}) => {
    const intl = useIntl();
    return <Button
        {...rest}
        ref={ref => connectors.create(ref, <Asset/>, {})}
        variant="outlined"
        style={{border: "1px dashed " + themeColors.success.main}}
    >{intl.formatMessage({id: "layouts.prop.buttons.asset", defaultMessage: "Asset"})}</Button>
}

Asset.craft = {
    props: {
        asset: null,
        width: 75,
    },
    related: {
        settings: AssetSettings,
    },
    rules: {}
};
