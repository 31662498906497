import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import {useIntl} from 'react-intl';
import {graphQLApi} from 'services/GraphQLApi';
import {useAuthDispatch} from 'contexts/Auth';
import {FieldTypes} from 'variables/fields';
import Selection from './Selection';
import EntityWizard from './EntityWizard';

export default function ExportLabel({onClose, open, entity, entityTypeID, ...rest}) {
  const intl = useIntl();
  const wizardRef = useRef(null);
  const client = new graphQLApi(useAuthDispatch());

  const [formats, setFormats] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState(null);
  const [copies, setCopies] = useState("1");
  const [download, setDownload] = useState(true);
  const [settings, setSettings] = useState({
    exports_token: '',
    exports_download: true,
  });
  const [fields, setFields] = useState(null);
  const [entityTypes, setEntityTypes] = useState([]);
  const [columns, setColumns] = useState(null);
  const [enableOkay, setEnableOkay] = useState(false);
  const [selectedValues, setSelectedValues] = useState(null);

  useEffect(() => {
    const query = `{
      settings(filter:{key_in:["exports_token","exports_download"]}){data{key data}}
      fields{data{id entity_type{id} type name key}}
      labels{data{id title is_multiple entity_type{id} label_elements{id type field{id type entity_type{id title}}}}}
      entityTypes{data{id title title_field{id name key} number_field{id name key} third_field{id name key} fourth_field{id name key} fifth_field{id name key} sixth_field{id name key}}}
      }`;
    client.query(query)
      .then(r => {
        const ss = {};
        if (r?.settings) {
          r.settings.data.forEach(s => {
            ss[s.key] = s.data;
          });
          setSettings(ss);
          if (ss.hasOwnProperty('exports_download')) {
            setDownload(ss.exports_download === "1");
          }
        }
        if (r?.entityTypes) {
          setEntityTypes(r?.entityTypes.data);
        }

        if (r && r.hasOwnProperty('labels') && r.hasOwnProperty('fields')) {
          const cols = {};
          r.fields.data.forEach(f => {
            let type = null;
            if (!cols[f.entity_type.id]) cols[f.entity_type.id] = [];
            switch (f.type) {
              case FieldTypes.BOOLEAN:
                type = 'boolean';
                break;
              case FieldTypes.NUMBER:
              case FieldTypes.DECIMAL:
                type = 'number';
                break;
              case FieldTypes.PRICE:
                type = 'currency';
                break;
              default:
                type = 'string';
                break;
            }
            cols[f.entity_type.id].push({
              field: f.key,
              sortable: false,
              title: f.name, sorting: false, search: false, type: type,
              align: [FieldTypes.PRICE, FieldTypes.DECIMAL, FieldTypes.NUMBER].findIndex(t => t === f.type) === -1 ? 'left' : 'right',
              render: (row => {
                let pVal = row.values ? row.values.find(pv => pv.field.id === f.id) : null;
                if (!pVal) return "";
                switch (f.type) {
                  case FieldTypes.BOOLEAN:
                    return pVal.bool;
                  case FieldTypes.STRING:
                    return pVal.string;
                  case FieldTypes.TEXT:
                    return pVal.text.substr(0, 50);
                  case FieldTypes.HTML:
                    return pVal.text === '<p></p>' ? "Empty" : "Filled";
                  case FieldTypes.PRICE:
                    return intl.formatNumber(pVal.decimal, {
                      style: 'currency',
                      currency: pVal.string,
                      currencyDisplay: 'code',
                      minimumFractionDigits: 2
                    });
                  case FieldTypes.DECIMAL:
                    return intl.formatNumber(pVal.decimal);
                  case FieldTypes.NUMBER:
                    return pVal.integer;
                  case FieldTypes.OPTION:
                    return pVal.field_option ? pVal.field_option.title : '';
                  case FieldTypes.ASSET:
                    return <img height={24} src={pVal.asset.file_thumb} alt=''/>;
                  case FieldTypes.ASSETS:
                    return !row.values ? '' :
                      row.values
                        .filter(pv => (pv.field.id === f.id && pv.asset && pv.asset.file_thumb !== ""))
                        .map((pv, pvIdx) => (
                          <img
                            key={"column-" + f.id + "-image-" + pvIdx} height={24}
                            style={{marginLeft: 3}}
                            src={pv.asset.file_thumb}
                            alt=''
                          />
                        ))
                  case FieldTypes.OPTIONS:
                    return !row.values ? '' :
                      row.values
                        .filter(pv => pv.field.id === f.id)
                        .map((pv, pvIdx) => <Chip key={"column-" + f.id + "-option-" + pvIdx} size={"small"}
                                                  style={{marginLeft: 3}} label={pv.field_option.title}/>)
                  default:
                    return '';
                }
              }),
            });
          });

          const filteredLabels = r.labels.data.filter(label => label.entity_type.id === entityTypeID);
          setColumns(cols);
          setFields(r.fields.data);
          setFormats(filteredLabels);
          setSelectedFormat(filteredLabels[0]);
        }
      });
    //const related_elements = format.label_elements.filter(le => le.field);
  }, []);

  function handleCancel() {
    onClose(false);
    resetStates();
  };

  function handleOk() {
    onClose({
      id: entity.id,
      export_format_id: selectedFormat.id,
      copies: parseInt(copies),
      export_as: "text",
      related_ids: selectedValues,
      download: download,
    });
    resetStates();
  };

  function resetStates() {
    setFormats(null);
    setCopies("1");
    setFields(null);
    setColumns(null);
    setEnableOkay(false);
    setSelectedValues(null);
  }

  function getCollections(data) {
    if(Array.isArray(data)) {
      let collectedRelativeFields = [];
      data.forEach(labelElement => {
        if (labelElement.field) {
          const foundField = fields.find(field => field.type === `entities_${labelElement.field.entity_type.id}`);
          const relatedEntity = entityTypes.find(et => et.id === labelElement.field.entity_type.id)
          const names = [relatedEntity.number_field?.name, relatedEntity.title_field?.name, relatedEntity.third_field?.name, relatedEntity.fourth_field?.name, relatedEntity.fifth_field?.name, relatedEntity.sixth_field?.name];

          if (labelElement.field.entity_type.id !== entity.id && foundField) {
            if (collectedRelativeFields.findIndex(collection => collection.id === labelElement.field.entity_type.id) === -1) {
              const idColumn = [{
                title: intl.formatMessage({id: "common.notation.id", defaultMessage: "#ID"}),
                field: 'id'
              }]
              collectedRelativeFields.push({
                id: labelElement.field.entity_type.id,
                title: labelElement.field.entity_type.title,
                elements: [labelElement],
                filterIds: entity.values.length ? entity.values.filter(v => v.field_id === foundField.id).map(value => value.related_entity_id) : [],
                columns: [...idColumn, ...columns[labelElement.field.entity_type.id].filter(c => c.title !== 'Type' && names.includes(c.title))]
              });
            } else {
              collectedRelativeFields.map(collection => {
                if (collection.id === labelElement.field.entity_type.id) collection.elements = [...collection.elements, labelElement];
                return collection;
              });
            }
          }
        }
      });

      return collectedRelativeFields;
    }
  }

  function handleFinishWizard(toggleOkay, values) {
    setEnableOkay(toggleOkay);
    if (toggleOkay) {
      setSelectedValues(values);
    }
  }

  return (
    <Dialog
      maxWidth="lg"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...rest}
    >
      <DialogTitle disableTypography id="confirmation-dialog-title"><Typography variant="h2">{intl.formatMessage({
        id: "product.edit.dialog.create_label_title",
        defaultMessage: "Create Label"
      })}</Typography></DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item xs={12}>
            {formats && selectedFormat ? (
              <Selection
                value={formats.find(format => format.id === selectedFormat.id)}
                onChange={e => {
                  setSelectedFormat(e.target.value);
                  if (wizardRef.current && wizardRef.current.hasOwnProperty('reset')) wizardRef.current.reset();
                }}
                label={intl.formatMessage({
                  id: "product.edit.dialog.label_format",
                  defaultMessage: "Label Format"
                })}
              >{formats.map(format => <MenuItem key={format.id} value={format}>{format.title}</MenuItem>)}</Selection>
            ) : ''}
          </Grid>
          <Grid item xs={3}>
            {selectedFormat && <TextField
              size="small"
              type="number"
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
              value={copies}
              onChange={e => setCopies(e.target.value)}
              label={intl.formatMessage({
                id: "product.edit.dialog.label_copies",
                defaultMessage: "Copies"
              })}
              disabled={!selectedFormat.is_multiple}
            />}
          </Grid>
          {selectedFormat && settings.exports_token?.length > 0 && <Grid item>
            <FormControlLabel
              control={<Checkbox name="download" checked={download} onChange={e => setDownload(e.target.checked)}/>}
              label={intl.formatMessage({
                id: "product.edit.dialog.label.download",
                defaultMessage: "Also download the exported file"
              })}/>
          </Grid>}
          {/*{selectedFormat && fields && columns && entitySelectFactory(selectedFormat.label_elements)}*/}
          {selectedFormat ? <EntityWizard ref={wizardRef} collections={getCollections(selectedFormat.label_elements)} handleFinishWizard={handleFinishWizard}/> : ''}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="default">
          {intl.formatMessage({id: "common.button.cancel", defaultMessage: "Cancel"})}
        </Button>
        <Button onClick={handleOk} color="primary" disabled={!enableOkay}>
          {/* NOTE: Could use a better describing label like, export or, print */}
          {intl.formatMessage({id: "common.button.ok"})}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
