import moment from 'moment';
import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  lighten,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import TablePaginationActions from './TablePaginationActions';
import {makeStyles} from '@material-ui/core/styles';
import {graphQLApi} from 'services/GraphQLApi';
import {authUser, useAuthDispatch} from 'contexts/Auth';
import {
  Add,
  Check,
  Close,
  Delete,
  Edit,
  History,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Remove,
  SubdirectoryArrowRight,
  Undo,
  Update
} from '@material-ui/icons';
import {FormattedMessage, useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';
import {rolePriorities} from '../../config';
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: 42,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  action: {
    margin: "0 4px"
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.text.secondary,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
    fontSize: 22
  },
  toolbar: {
    minHeight: 42,
  }
}));

const EnhancedTable = React.forwardRef((props, ref) => {
  const {
    columns,
    actions = [],
    query,
    fields,
    filter,
    sorting = '',
    direction = 'asc',
    children,
    mutations = null,
    title,
    icon,
    defaultRowsPerPage = 25,
    rowsPerPageOptions = [5, 10, 25, 50, 100, 200, 500],
    searchable = true,
    fullTextSearchable = false,
    urlState = true,
    reload,
    baseUrl,
    softDeletes = false,
    rowStyle,
    rowDisabled,
    counts,
    onSetTotal,
    hideTopPagination,
  } = props;

  const classes = useStyles();
  const intl = useIntl();
  const authDispatch = useAuthDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = React.useState(true);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [deleteRow, setDeleteRow] = React.useState(null);
  const [showTrashed, setShowTrashed] = useState(false);
  const [order, setOrder] = React.useState(direction);
  const [orderBy, setOrderBy] = React.useState(sorting);
  const [search, setSearch] = React.useState('');
  const [fullTextSearch, setFulltextSearch] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const [loadRows, setLoadRows] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [expandedRows, setExpandedRows] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
  const [preloaded, setPreloaded] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [editing, setEditing] = useState({column: null, row: null, value: ""});

  const dispatch = useAuthDispatch();
  useEffect(() => {
    if (loadRows) {
      if (loadRows === true)
        setIsLoading(true);
      setLoadRows(false);
      fetchRows();
    }
  }, [loadRows, loaded]);

  const fetchRows = () => {
    const client = new graphQLApi(dispatch);

    let queryOrderBy = orderBy;
    let queryOrder = order;
    let querySearch = search;
    let queryPage = page + 1;
    let queryRowsPerPage = rowsPerPage;

    if (urlState && !loaded && location.search !== "") {
      const urlParams = new URLSearchParams(location.search);
      if (urlParams.get('sorting')) queryOrderBy = urlParams.get('sorting');
      if (urlParams.get('direction')) queryOrder = urlParams.get('direction');
      if (urlParams.get('search')) querySearch = urlParams.get('search');
      if (urlParams.get('count')) queryRowsPerPage = +urlParams.get('count');
      if (urlParams.get('page') && +urlParams.get('page') > 1) queryPage = +urlParams.get('page');
      setLoaded(true);
    }
  return client.query('{' + query +
      '(' +
      'page:' + (queryPage) + ',limit:' + queryRowsPerPage + (queryOrderBy !== '' ? ',sorting:"' + queryOrderBy + '",direction:"' + queryOrder + '"' : '') +
    ',filter:{' + (querySearch !== '' ? 'q:"' + querySearch + '" ' : children ? 'parent_id:null ' : '') + (filter ? filter : '') + ' fullTextSearch:' + fullTextSearch + '}' +
      (showTrashed ? 'trashed:true ' : '') +
      (counts ? 'counts:["'+counts.join('","')+'"] ' : '') +
      ')' +
      '{total current_page last_page from to data {id ' + fields + (counts ? ' '+counts.map(c => c+'_count').join(' ') : '') + (children ? ' parent{id} children{id}' : '') + '} } }').then(r => {
      setIsLoading(false);
      if (r) {
        setRows(r[query].data);
        setTotal(r[query].total);
        if(typeof onSetTotal === 'function') onSetTotal(r[query].total)
      }
    });
  }

  useEffect(() => {
    if (reload) setLoadRows(true);
  }, [reload]);

  useEffect(() => {
    if (urlState && !preloaded) {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('sorting')) {
        setOrderBy(urlParams.get('sorting'));
        if (urlParams.get('direction')) {
          setOrder(urlParams.get('direction'));
        }
      }
      if (urlParams.get('search')) {
        setSearch(urlParams.get('search'));
      }
      if (urlParams.get('page')) {
        setPage(+urlParams.get('page') - 1);
      }
      if (urlParams.get('count')) {
        setRowsPerPage(+urlParams.get('count'));
      }
      setPreloaded(true);
    }
  }, [urlState, preloaded, search, orderBy, order, page]);

  useEffect(() => {
    if (urlState && preloaded) {
      let queryParams = new URLSearchParams(window.location.search);
      if (orderBy !== "") {
        queryParams.set("sorting", orderBy)
        queryParams.set("direction", order);
      } else {
        queryParams.delete("sorting");
        queryParams.delete("direction");
      }
      search !== "" ? queryParams.set("search", search) : queryParams.delete("search");
      page > 0 ? queryParams.set("page", page + 1) : queryParams.delete("page");
      rowsPerPage !== defaultRowsPerPage ? queryParams.set("count", rowsPerPage) : queryParams.delete("count");
      const params = queryParams.toString() !== "" ? "?"+queryParams.toString() : "";
      if (location.search === params) return;
      if (params !== "") navigate({pathname: location.pathname, search: params});
      else navigate({pathname: location.pathname, search: ""}, {replace: true});
    }
  }, [urlState, preloaded, search, orderBy, order, page, rowsPerPage, defaultRowsPerPage]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlState || !preloaded) return;
    if (urlParams.get('sorting')) {
      setOrderBy(urlParams.get('sorting'));
      if (urlParams.get('direction')) {
        setOrder(urlParams.get('direction'));
      }
    }
    if (urlParams.get('search')) {
      setSearch(urlParams.get('search'));
    }
    if (urlParams.get('page')) {
      setPage(+urlParams.get('page') - 1);
    }
    else setPage(0);
    if (urlParams.get('count')) {
      setRowsPerPage(+urlParams.get('count'));
    }
  }, [urlState, preloaded, location]);

  const getChildrenOfRow = (row) => {
    const client = new graphQLApi(dispatch);
    setIsLoading(true);
    client.query('{' + query +
      '(' + (orderBy !== '' ? ',sorting:"' + orderBy + '",direction:"' + order + '"' : '') +
      'filter:{parent_id:' + row.id + '}' +
      ')' +
      '{total current_page last_page from to data {id parent{id} children{id} ' + fields + '} } }').then(r => {
      setIsLoading(false);
      if (r) {
        setRows(curRows => {
          let parentRowIndex = curRows.findIndex(cr => cr.id === row.id);
          for (let i = r[query].data.length - 1; i > -1; i--) {
            curRows.splice(parentRowIndex + 1, 0, {
              ...r[query].data[i],
              path: (row.path ? row.path + '_' : '') + row.id
            });
          }
          // console.log('Inserted rows', r[query].data, 'at position', parentRowIndex, curRows);
          return curRows;
        });
        let path = row.path ? row.path + "_" + row.id : row.id;
        setExpandedRows([...expandedRows, path]);
        if (row.children && selected.find(sr => sr === row.id)) {
          console.log("Selecting the children of the row becasue it is selected", row.id, row.children)
          const s = row.children.filter(c => !selected.includes(c.id))
          setSelected([...selected, ...s?.map(c => c.id)]);
        }
      }
    });
  }

  let timeout = useRef();
  const debounce = (func, wait, immediate) => {
    return function () {
      let context = this, args = arguments;
      let later = function () {
        timeout.current = null;
        if (!immediate) func.apply(context, args);
      };
      let callNow = immediate && !timeout.current;
      // console.log('Debouncing', callNow, timeout.current, immediate, args);
      clearTimeout(timeout.current);
      timeout.current = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  const debouncedFetchRows = debounce(quiet => {
    setLoadRows(quiet ? 1 : true);
  }, 250);

  const handleRequestSort = (event, property) => {
    if (orderBy) {
      if (orderBy !== property) {
        setOrder('asc');
      }
      else {
        setOrder(order === 'asc' ? 'desc' : 'asc');
      }
    }
    setOrderBy(property);
    debouncedFetchRows();
  };

  const handleSearchChange = (newSearch) => {
    setPage(0);
    setSearch(newSearch);
    debouncedFetchRows();
  }
  const handleFullTextSearch = (fullTextSearch) => {
    setPage(0);
    setFulltextSearch(fullTextSearch);
    debouncedFetchRows();
  };

  const handleSelectAllClick = (event) => {
    event.stopPropagation();
    let newSelected = [];
    rows.forEach(r => {
      newSelected.push(r.id);
      if (r.children) {
        newSelected = [...newSelected, ...r.children.map(c=> c.id)]
      }
    })
    if (event.target.checked) {
      newSelected = newSelected.filter(ns => !selected.includes(ns));
      setSelected([...selected, ...newSelected])
      return;
    }
    setSelected(selected.filter(r => !newSelected.includes(r)));
  };

  const handleSelectClick = (event, row, alreadySelected) => {
    let id = row.id;
    if (event) {
      event.stopPropagation();
    }
    let newSelected = [];
    let forceSelect = false;
    if (alreadySelected) {
      forceSelect = true;
    } else {
      alreadySelected = [...selected];
    }
    const selectedIndex = alreadySelected.indexOf(id);

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(alreadySelected, id);
    } else if (!forceSelect && selectedIndex === 0) {
      newSelected = newSelected.concat(alreadySelected.slice(1));
    } else if (!forceSelect && selectedIndex === alreadySelected.length - 1) {
      newSelected = newSelected.concat(alreadySelected.slice(0, -1));
    } else if (!forceSelect && selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    } else {
      newSelected = alreadySelected;
    }

    if (row.children && selectedIndex === -1) {
      row.children.forEach(c => {
        let child = rows.find(r => r.id === c.id);
        if (child) {
          newSelected = handleSelectClick(null, child, newSelected);
        } else {
          newSelected.push(c.id);
        }
      })
    }

    if (event) {
      if (!newSelected.includes(row.id) && row.children) {
        const children = row.children.map(c => c.id);
        newSelected = newSelected.filter(n => !children.includes(n));
      }
      setSelected(newSelected);
    } else {
      return newSelected;
    }
  };

  const handleChangePage = (event, newPage) => {
    event.stopPropagation();
    setPage(newPage);
    debouncedFetchRows();
  };

  const handleChangeRowsPerPage = (event) => {
    event.stopPropagation();
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    debouncedFetchRows();
  };

  const handleSelectedActionClick = (event, action) => {
    event.stopPropagation();
    action(rows.filter(r => selected.includes(r.id)), event);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const hasOnSelectedActions = () => actions && actions.filter(af => af.onSelected === true).length > 0;

  const toggleRowExpand = (row) => {
    let path = row.path ? row.path + "_" + row.id : row.id;
    let index = expandedRows.findIndex(cr => cr === path);
    if (index !== -1) {
      setExpandedRows([...expandedRows].filter(cr => cr.substr(0, path.length) !== path));
      return;
    }
    if (rows.filter(r => r.parent && r.parent.id === row.id).length === 0) {
      getChildrenOfRow(row);
    } else {
      let expRows = [...expandedRows];
      expRows.push(path);
      setExpandedRows(expRows);
    }
  };

  // const getChildLevel = (row, level = 0) => {
  //   if (row && row.parent) {
  //     return getChildLevel(rows.find(r => r.id === row.parent.id), ++level);
  //   }
  //   return level;
  // }

  const getTreeButtonPadding = (row) => {
    if (!children) {
      return '';
    }
    let expandButton = <span>&nbsp;</span>;
    if (row.children && row.children.length) {
      expandButton = <IconButton style={{display: "inline-flex"}} size={"small"} onClick={event => {
        event.stopPropagation();
        toggleRowExpand(row)
      }}>{
        expandedRows.findIndex(er => er === (row.path ? row.path + "_" : "") + row.id) === -1 ? <KeyboardArrowRight/> :
          <KeyboardArrowDown/>
      }</IconButton>;
    }
    if (row.parent) {
      expandButton = <IconButton style={{marginLeft: 10}} disabled><SubdirectoryArrowRight/></IconButton>;
    }
    return expandButton;
  }

  useImperativeHandle(ref, () => ({
    update(quiet = false) {
      setExpandedRows([])
      setSelected([]);
      debouncedFetchRows(quiet);
    },
    isLoading(state = true) {
      setIsLoading(state);
    },
    fetchRows() {
      return fetchRows()
    },
    getRows() {
      return rows;
    },
    setRows(rows) {
      setRows(rows);
    },
    getSelected() {
      return selected;
    },
    setSelected(newSelected) {
      setSelected(newSelected);
    },
    clearSelected() {
      setSelected([]);
    },
    getSearch() {
      return {
        search : search,
        fullTextSearch : fullTextSearch
      };
    },
  }));

  if (mutations && actions.findIndex(a => a.system) === -1) {
    if (showTrashed) {
      actions.push({
        system: true,
        icon: Undo,
        tooltip: intl.formatMessage({
          id: "common.tooltip.restore",
          defaultMessage: "Restore",
        }),
        onClick: (row) => handleRestore(row),
      });
      actions.push({
        system: true,
        icon: Update,
        tooltip: intl.formatMessage({
          id: "common.tooltip.show-active",
          defaultMessage: "Show active",
        }),
        isFreeAction: true,
        onClick: () => {
          setShowTrashed(!showTrashed);
          setRows([]);
          debouncedFetchRows();
        },
      });
    } else {
      actions.push({
        system: true,
        rowClick: true,
        icon: Edit,
        tooltip: intl.formatMessage({id: "enhanced_table.actions.edit", defaultMessage: "Edit"}),
        onClick: (row) => {
          navigate((baseUrl ? baseUrl : location.pathname) + '/' + row.id)
        },
      });
      actions.push({
        color: "primary",
        system: true,
        isFreeAction: true,
        label: intl.formatMessage({id: "enhanced_table.actions.add", defaultMessage: "Add"}),
        onClick: () => {
          navigate((baseUrl ? baseUrl : location.pathname) + '/create')
        },
      });
      actions.push({
        system: true,
        icon: Delete,
        tooltip: intl.formatMessage({id: "enhanced_table.actions.delete", defaultMessage: "Delete"}),
        onClick: (row) => {
          setDeleteRow(row);
          setConfirmDelete(true);
        },
      });
      if (softDeletes && authUser().isAllowed(rolePriorities.admin)) {
        actions.push({
          system: true,
          icon: History,
          tooltip: intl.formatMessage({
            id: "common.tooltip.show-deleted",
            defaultMessage: "Show deleted",
          }),
          isFreeAction: true,
          onClick: () => {
            setShowTrashed(!showTrashed);
            setRows([]);
            debouncedFetchRows();
          },
        });
      }
    }
  }

  const handleConfirmDeletion = (confirmation) => {
    if (confirmation && deleteRow) {
      const client = new graphQLApi(authDispatch);
      client.mutate('{' + mutations + 'Delete(id:' + deleteRow.id + ')}').then(result => {
        if (result) {
          setRows([...rows.filter(r => r.id !== deleteRow.id)])
        }
      });
    }
    setConfirmDelete(false);
  }

  const handleRestore = (row) => {
    const client = new graphQLApi(dispatch);
    client
        .mutate("($id:ID!) { " + mutations + "Restore(id:$id) }", {id: row.id})
        .then((result) => {
          if (result === undefined || !result[mutations + "Restore"]) {
            return intl.formatMessage({
              id: "table.api.restore-failed",
              defaultMessage: "Failed to restore the row",
            });
          }
          debouncedFetchRows();
        });
  }

  const renderRowCellData = (column, row) => {
    let field = column.field.split('.');
    let value = row;
    field.forEach(o => {
      if (value && value[o] !== undefined) {
        value = value[o];
      }
      else {
        value = '';
      }
    })
    let m = moment(value);
    switch (column.type) {
      case 'time':
        if (!m.isValid()) return value;
        return m.format(intl.formatMessage({id:"common.time.format"}));
      case 'date':
        if (!m.isValid()) return value;
        return m.format(intl.formatMessage({id:"common.date.format"}));
      case 'datetime':
        if (!m.isValid()) return value;
        return m.format(intl.formatMessage({id:"common.datetime.format"}));
      case 'number':
        return parseFloat(value);
      case 'integer':
        return parseInt(value);
      case 'bool':
      case 'boolean':
        return value ? <Check style={{height:".8125rem",width:".8125rem"}}/> : <Remove style={{height:".8125rem",width:".8125rem"}}/>
      default:
        return value;
    }
  }

  let rowAction = actions.find(a => a.rowClick);

  return (
    <Box width="100%">
      <EnhancedTableToolbar
        numSelected={selected.length}
        icon={icon}
        title={typeof title === "string" ? title.replace('{total}', total) : title}
        actions={actions}
        onSelectedActions={handleSelectedActionClick}
        setSelected={setSelected}
        onSearchChange={searchable ? handleSearchChange : null}
        fullTextSearchable={fullTextSearchable}
        search={search}
        fullTextSearch={fullTextSearch}
        handleFullTextSearch={handleFullTextSearch}
        urlState={urlState}
      />
      <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <DialogContent><FormattedMessage id={"enhanced_table.dialog.confirm_deletion"}
                                         defaultMessage={'Please confirm the deletion of "{row}"'}
                                         values={{row: deleteRow ? deleteRow[columns[0].field] : ''}}
        /></DialogContent>
        <DialogActions>
          <Button color="default" onClick={() => handleConfirmDeletion(false)}>Cancel</Button>
          <Button color="primary" onClick={() => handleConfirmDeletion(true)}>OK</Button>
        </DialogActions>
      </Dialog>
      {(selected.length > 0 || !hideTopPagination) && <Grid container spacing={0}>
        <Grid item xs={6}>
          <Toolbar className={classes.root + (selected.length > 0 ? ' ' + classes.highlight : '')}
          ><Typography  color="primary" variant="body2" component="div" className={classes.title}>
           {selected.length > 0
                && intl.formatMessage({id: 'enhanced_table.toolbar.selected', defaultMessage: '{count} selected rows',}, {count: selected.length})}
          </Typography>
            {(selected.length > 0 && actions)
              && <Grid container spacing={1} justifyContent={'flex-end'}
                      style={{margin: -11}}>
                {actions.filter(af => af.onSelected === true)
                .map((action, index) =>
                    <Grid key={'toolbar-action-' + index}>{
                      action.tooltip ? <Tooltip title={action.tooltip}>
                          <IconButton aria-label={action.tooltip} size="medium"
                                      onClick={event => handleSelectedActionClick(event,
                                        action.onClick)}>
                            <action.icon style={{height: 20, width: 20}}/>
                          </IconButton>
                        </Tooltip>
                        :
                        <Button key={'toolbar-action-' + index}
                                startIcon={action.icon && <action.icon/>}
                                onClick={event => handleSelectedActionClick(event,
                                  action.onClick)}
                        >{action.label}</Button>
                    }</Grid>,
                  )}
              </Grid>}
          </Toolbar>
        </Grid>
        {!hideTopPagination && <Grid item xs={6}>
          <TablePagination
            classes={{toolbar: classes.toolbar}}
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={total > rowsPerPage ? page : 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Grid>}
      </Grid>}

      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size='small'
          aria-label="enhanced table"
          key={"table-with-expanded-" + expandedRows.join("-")}
        >
          <EnhancedTableHead
            columns={columns}
            actions={actions}
            classes={classes}
            selected={selected}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rows={rows.map(r => r.id)}
            children={children}
          />
          <TableBody>{isLoading
            ? <TableRow><TableCell colSpan={columns.length + 2} align="center" style={{padding: 16}}><CircularProgress
              color="inherit"/></TableCell></TableRow>
            : rows.length ? rows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              if (children && row.path && expandedRows.findIndex(er => er === row.path) === -1) {
                // console.log('parent is not expanded', row.id, 'parent', row.path)
                return null;
              }
              // console.log('Returning row', row.id, 'parent is expanded or none', (row.parent && row.path))
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={"row-" + index + "-" + row.id}
                  selected={isItemSelected}
                  style={rowStyle ? rowStyle(row) : null}
                >
                  {(hasOnSelectedActions() || children) &&
                    <TableCell padding="checkbox">
                      {hasOnSelectedActions() && <Checkbox
                        color="primary"
                        onClick={(event) => handleSelectClick(event, row)}
                        checked={isItemSelected}
                      />}
                      {children && getTreeButtonPadding(row)}
                    </TableCell>
                  }
                  {columns.map((header, columnIndex) => {
                    const value = header.render ? header.render(row, index) : renderRowCellData(header, row, index);
                    const editable = typeof header.set === "function"
                      && (editing.column !== columnIndex || editing.row !== index);
                    return <TableCell
                      onClick={event => {
                        if (rowDisabled && rowDisabled(row)) return;
                        if (editable) {
                          setEditing({column: columnIndex, row: index, value: value});
                        }
                        else if (rowAction) {
                          rowAction.onClick(row, event);
                        }
                      }}
                      style={{
                        cursor: editable ? "text" : ((rowAction && (!rowDisabled || !rowDisabled(row))) ? "pointer" : "inherit"),
                        textAlign: header.align ? header.align : "left",
                      }}
                      key={"row-cell-" + index + "-" + columnIndex + "-" + header.field}
                      align={header.align ? header.align : 'left'}
                    >
                      {(editing.column === columnIndex && editing.row === index)
                        ? <InputBase
                          fullWidth
                          size="small"
                          style={{fontSize:".8125rem",border:"1px solid", margin:"-3px -8px", padding:"1px 8px", minWidth:300}}
                          value={editing.value}
                          onChange={e => setEditing({...editing, value:e.target.value})}
                          autoFocus
                          onClick={e => e.stopPropagation()}
                          onKeyUp={e => {
                            if (e.key === "Enter") {
                              setRows(cur => {
                                cur[index] = header.set(cur[index], editing.value);
                                setEditing({column: null, row: null, value: ""});
                                return cur;
                              })
                            }
                            if (e.key === "Esc" || e.key === "Escape") {
                              setEditing({column: null, row: null, value: ""});
                            }
                          }}
                          endAdornment={<InputAdornment
                            position="end"
                            style={{cursor:"pointer"}}
                          ><Check onClick={e => {
                            e.stopPropagation();
                            setRows(cur => {
                              cur[index] = header.set(cur[index], editing.value);
                              setEditing({column: null, row: null, value: ""});
                              return cur;
                            })
                          }}/>&nbsp;<Close onClick={_e => setEditing({column: null, row: null, value: ""})}/>
                        </InputAdornment>}
                        /> :
//                         (editable ? <>{value}<Edit style={{height:16,float:"right",position:"absolute",zIndex:theme.zIndex.speedDial}}/></> : value)
                         (editable ? <span onMouseOver={e => e.target.style.textDecoration="underline"} onMouseOut={e => e.target.style.textDecoration="none"}>{value}</span> : value)
                         }
                    </TableCell>
                  })}
                  {actions ?
                    <TableCell key={"row-cell-" + index + "-actions"} align="right">
                      {actions.filter(a => a.icon && !a.onSelected && !a.isFreeAction && !a.isFilterAction).map((action, actionIndex) =>
                        <IconButton
                          disabled={typeof action.disabled === "function" ? action.disabled(row) : action.disabled || (rowDisabled && rowDisabled(row))}
                          className={classes.action}
                          size="small"
                          key={'row-' + index + '-actions-' + actionIndex}
                          title={action.tooltip}
                          onClick={event => {
                            event.stopPropagation();
                            action.onClick(row, event);
                          }}>
                          {typeof action.icon === 'function' ? action.icon(row) : <action.icon style={{height: 20, width: 20}} />}
                        </IconButton>)}
                    </TableCell>
                    : ''
                  }
                </TableRow>);
            }) :
            <TableRow>
              <TableCell colSpan={columns.length + 2} align="center" style={{padding: 30}}>
                {intl.formatMessage({
                  id: "enhanced_table.no_data",
                  defaultMessage: "Sorry, there is nothing to show you here!"
                })}
              </TableCell>
            </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        classes={{toolbar: classes.toolbar}}
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={total > rowsPerPage ? page : 0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Box>
  );
});

EnhancedTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto', 'inherit', 'default'])]),
      render: PropTypes.func,
      align: PropTypes.oneOf(['left', 'right']),
    })
  ).isRequired,
  query: PropTypes.string.isRequired,
  fields: PropTypes.string.isRequired,
  filter: PropTypes.string,
  sorting: PropTypes.string,
  direction: PropTypes.oneOf(["asc", "desc"]),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
      tooltip: PropTypes.string.isRequired,
      label: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      toolbar: PropTypes.bool,
      onSelected: PropTypes.bool,
      isFreeAction: PropTypes.bool,
      rowClick: PropTypes.bool,
    })
  ),
  children: PropTypes.bool,
  mutations: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  urlState: PropTypes.bool,
  reload: PropTypes.bool,
  rowStyle: PropTypes.func,
  defaultRowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  counts: PropTypes.arrayOf(PropTypes.string),
  fullTextSearchable: PropTypes.bool,
  searchable: PropTypes.bool,
  hideTopPagination: PropTypes.bool,
};

export default EnhancedTable;

