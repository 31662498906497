import React, {useContext} from "react";
import {useIntl} from "react-intl";
import {LanguageContext} from "../../../../contexts/Layouts";
import {EntityContext} from "../../EntityLayoutEditor";
import {TextField} from "@material-ui/core";
import {getFieldLabel, RenderEditableField} from "./Field";

export default function PriceField(
  {
    field,
    ...rest
  }) {
  const {language} = useContext(LanguageContext);
  const intl = useIntl();
  const {getValuesOfField, setValuesOfField} = useContext(EntityContext);
  let value = getValuesOfField(field.id)?.pop() || {};
  return <RenderEditableField
    field={field}
    value={value ? (value.decimal || '') + (value.string ? ' ' + value?.string : '') : ''}
  ><TextField
      {...rest}
      style={{flexGrow: 1}}
      fullWidth
      autoFocus
      type={'number'}
      inputProps={{step: 0.01}}
      label={getFieldLabel(field, language)}
      value={value ? value.decimal : ""}
      onChange={e => setValuesOfField(field.id, [{
        ...value,
        decimal: e.target.value,
      }])}
    /><TextField
      {...rest}
      style={{maxWidth: 75}}
      fullWidth
      inputProps={{maxlength: 3}}
      label={intl.formatMessage({id: "common.currency", defaultMessage: "Currency"})}
      value={value ? value.string : ""}
      onChange={e => setValuesOfField(field.id, [{
        ...value,
        string: e.target.value,
      }])}
  /></RenderEditableField>;
}
