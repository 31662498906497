import React from 'react';
import {Box, Button, Chip, Grid} from "@material-ui/core";
import {useEditor} from "@craftjs/core";
import {useIntl} from "react-intl";
import {ArrowUpward, Input} from "@material-ui/icons";

const SettingsPanel = () => {
    const {actions, selected} = useEditor((state, query) => {
        const [id] = state.events.selected;
        let selected;
        let currentNodeId = 'ROOT';
        if (id) currentNodeId = id;
        if (currentNodeId !== 'ROOT' && state.nodes[currentNodeId]?.data.isCanvas && state.nodes[currentNodeId]?.data.name === 'div') {
            currentNodeId = state.nodes[currentNodeId].data.parent;
            actions.selectNode(currentNodeId);
        }
        if (state.nodes[currentNodeId]) {
            selected = {
                id: currentNodeId,
                name: state.nodes[currentNodeId].data.name,
                settings: state.nodes[currentNodeId].related && state.nodes[currentNodeId].related.settings,
                isDeletable: query.node(currentNodeId).isDeletable(),
                parent: state.nodes[state.nodes[currentNodeId].data.parent],
            };
            if (selected.parent?.data.isCanvas && selected.parent?.data.name === 'div' && state.nodes[selected.parent?.data.parent]) {
                selected.parent = state.nodes[selected.parent.data.parent];
            }
        }
        return {
            selected
        }
    });
    const intl = useIntl();
    const getNodeName = (name) => {
      switch (name) {
          case 'Text':
              return intl.formatMessage({id:"layouts.prop.buttons.text"});
          case 'Asset':
              return intl.formatMessage({id:"layouts.prop.buttons.asset"});
          case 'Field':
              return intl.formatMessage({id:"layouts.prop.buttons.field"});
          case 'Block':
              return intl.formatMessage({id:"layouts.prop.buttons.block"});
          case 'Column':
              return intl.formatMessage({id:"layouts.prop.buttons.column", defaultMessage:"Kolonne"});
          case 'Container':
              return intl.formatMessage({id:"layouts.prop.buttons.container"});
          default:
              return name;
      }
    }

    return selected ? (
        <Box bgcolor="rgba(0, 0, 0, 0.06)" mt={2} px={2} py={2}>
            <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" spacing={1}>
                        <Grid item>
                            <Chip
                                size="medium"
                                variant="outlined"
                                icon={<Input/>}
                                title={intl.formatMessage({
                                    id: "layouts.settings.selected",
                                    defaultMessage: "Selected node"
                                })}
                                style={{textTransform:"uppercase"}}
                                label={getNodeName(selected.name)}/>
                        </Grid>
                        {selected.parent && <Grid item>
                            <Button
                                title={intl.formatMessage({
                                    id: "layouts.settings.parent",
                                    defaultMessage: "Goto parent node"
                                })}
                                variant="outlined"
                                onClick={_ => {
                                    console.log('Select node', selected.parent.id);
                                    actions.selectNode(selected.parent.id)
                                }}
                                size="small"
                                color="secondary"
                                startIcon={<ArrowUpward/>}
                            >{getNodeName(selected.parent.data?.name)}</Button>
                        </Grid>}
                    </Grid>
                </Grid>
                {selected.settings && <Grid item xs={12}>{React.createElement(selected.settings)}</Grid>}
                {selected.isDeletable ? <Grid item xs={12} style={{textAlign: "center"}}>
                    <Button
                        variant="contained"
                        color="default"
                        onClick={() => {
                            actions.delete(selected.id);
                        }}
                    >{intl.formatMessage({id: "common.button.delete"})}</Button>
                </Grid> : null}
            </Grid>
        </Box>
    ) : <div/>
}
export default SettingsPanel;
