import React, {useEffect, useState} from "react";
import {authUser} from "../../../contexts/Auth";
import {rolePriorities} from "../../../config";
import {IconButton, Menu, MenuItem} from "@material-ui/core";
import {useIntl} from "react-intl";
import MoreVert from "@material-ui/icons/MoreVert";

export default function ActionMenu({
                                     settings,
                                     isLoading,
                                     setShowSelectLayout,
                                     setShowExportLabel,
                                     setRevisionsListDialogOpen,
                                   }) {
  const intl = useIntl();
  const [showActionsMenu, setShowActionsMenu] = useState();

  const [menu, setMenu] = useState([]);
  useEffect(() => {
    let newMenu = [];
    if (authUser().isAllowed(rolePriorities.view_data) && settings?.hasPrints)
      newMenu.push(<MenuItem
        key={"menu-print"}
        disabled={isLoading}
        onClick={() => setShowSelectLayout(true)}
      >{
        intl.formatMessage({id: "common.button.print", defaultMessage: "Print"})
      }</MenuItem>)
    if (authUser().isAllowed(rolePriorities.view_data) && settings?.hasLabels)
      newMenu.push(<MenuItem
        key={"menu-labels"}
        disabled={isLoading}
        onClick={() => setShowExportLabel(true)}
      >{
        intl.formatMessage({id: "entity.edit.button.export_entity", defaultMessage: "Labels"})
      }</MenuItem>)
    if (authUser().isAllowed(rolePriorities.view_data) && settings?.revisions === '1')
      newMenu.push(<MenuItem
        key={"menu-revisions"}
        disabled={isLoading}
        onClick={() => setRevisionsListDialogOpen(true)}
      >{
        intl.formatMessage({id: "revisions.button.label", defaultMessage: "Revisions"})
      }</MenuItem>)
    setMenu(newMenu);
  }, [settings]);

  return <>
    {menu.length > 0 && <IconButton
      size="small"
      onClick={e => setShowActionsMenu(e.currentTarget)}
    ><MoreVert/></IconButton>}
    <Menu
      open={!!showActionsMenu}
      onClose={() => setShowActionsMenu(null)}
      anchorEl={showActionsMenu}
    >{menu.map(item => item)}</Menu>
  </>;
}
