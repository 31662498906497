import {Grid} from '@material-ui/core';

const versions = [
  {
    version: "2.1.0",
    date: "2023-02-03 15:35:00",
    da: {
      title: "Hvad er nyt i denne version",
      description: <Grid>Når du fremover logger ind og der er blevet frigivet en ny version, vil du fremover få vist hvad den nye version indeholder i en boks som denne.</Grid>,
    },
    en: {
      title: "Whats new in this version",
      description: <Grid>From now and onwards, when you log in and a new version has been released since your last login, you will see a dialog like this detailing the news.</Grid>,
    },
    images: [
      require('assets/img/versions/celebrate-800.jpg'),
    ],
  },
  {
    version: "2.2.0",
    date: "2023-02-10 08:45:00",
    da: {
      title: "Dashboard og forbindelser",
      description: <Grid container>
        <Grid item xs={12}><strong>Forbindelser</strong><br/>
          Vi har tilføjet flere forbindelser til eksterne systemer:
          <ul>
            <li>SmartWeb/DanDomain ordre Import</li>
            <li>DanDomain Classic produkt import/export</li>
            <li>DanDomain Classic ordre import</li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <strong>Dashboard</strong><br/>
          Så har vi også lavet et dashboard så du kan få et hurtigt overblik over dine OpenDIMS med alle de dimser, kanaler og forbindelser du har.<br/>
          Her kan du også se hvornår dine kanaler med tidsplan sidst har kørt og hvilke dimser der sidst er blevet ændret.
        </Grid>
      </Grid>,
    },
    en: {
      title: "Dashboards and connections",
      description: <Grid container>
        <Grid item xs={12}><strong>Connections</strong><br/>
          We have added more connections to external systems:
          <ul>
            <li>DanDomain order Import</li>
            <li>DanDomain Classic product import/export</li>
            <li>DanDomain Classic order import</li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <strong>Dashboard</strong><br/>
          We have also created a dashboard so you can get a quick overview of your OpenDIMS with all the entities, channels and connections you have.<br/>
          Here you can also see when your channels with a schedule last ran and which entities were last changed.
        </Grid>
      </Grid>,
    },
    images: [
      require('assets/img/versions/dashboard.png'),
    ],
  },
  {
    version: "2.3.0",
    date: "2023-02-15 21:35:00",
    da: {
      title: "Sprit nyt design",
      description: <Grid><p>Vi har implementeret et helt nyt design i OpenDIMS, så du får et bedre overblik.<br/>
        Vi har brugt nye og klare farver samt gjort det lettere at lokalisere fanen i browseren, med det nye ikon på fanebladet.</p>
        <strong>Nye features i OpenDIMS</strong>
        <ul>
          <li>"Redigering i lister" gør det muligt for at dig at redigere indhold direkte i listen, uden at åbne Dimsen først.</li>
          <li>Farver på dashboard med links til de forskellige punkter.</li>
          <li>Ny felttype: Completion (Procent udfyldte værdier) som kan bruges til workflow - fx Du opretter et felt og angiver, hvilke andre felter der skal være udfyldt, for at produktet kan sendes til webshoppen.</li>
          <li>Ny oversigt over jobs under afvikling og i kø.</li>
        </ul>
      </Grid>,
    },
    en: {
      title: "Brand new design",
      description: <Grid><p>We have implemented a completely new design in OpenDIMS, so that you get a better overview.<br/>
        We have used new and clear colors and made it easier to locate the tab in the browser, with the new icon on the tab page.</p>
        <strong>Other news in OpenDIMS</strong>
        <ul>
          <li>"Editing in lists" enables you to edit content directly in the list, without opening the entity first.</li>
          <li>Colors on the dashboard with links to the various points.</li>
          <li>New field type: Completion (Percent completed values) which can be used for workflow - for example you create a field and indicate which other fields must be completed in order for the product to be sent to the webshop.</li>
          <li>New overview of jobs in progress and in queue.</li>
        </ul>
      </Grid>,
    },
    images: [
      require('assets/img/versions/new-design.png'),
      require('assets/img/versions/in-list-editing.png'),
      require('assets/img/versions/completion-edit.png'),
      require('assets/img/versions/job-waiting.png'),
      require('assets/img/versions/job-processing.png'),
    ],
  },
  {
    version: "2.3.1",
    date: "2023-03-01 22:13:00",
    da: {
      title: "Få en demo",
      description: <Grid><p>For at sprede det gode budskab om OpenDIMS ud til flere er der blevet lavet mulighed for at få en begrænset demo af OpenDIMS.</p>
        <p><img height={75} src={require('assets/img/webshop8-logo.white.svg').default} alt='Webshop8' style={{float:"right",backgroundColor:"black",margin:4,padding:8}}/>
          <img height={75} src={require('assets/img/dandomain-logo.svg').default} alt='DanDomain' style={{float:"right",margin:4,padding:8}}/>
          Vi har også indgået et partnerskab med DanDomain og integreret OpenDIMS i deres Classic Webshop8 app store, så det er nu nemmere end nogensinde at komme i gang med at bruge OpenDIMS sammen med DanDomain.</p>
        <strong>Andre nyheder i denne version</strong>
        <ul>
          <li>Forbedret baggrund på login så den hænger sammen med resten af farverne i OpenDIMS.</li>
          <li>Rettet fejl i DanDomain Classic forbindelser.</li>
        </ul>
      </Grid>,
    },
    en: {
      title: "Get a demo",
      description: <Grid><p>To spread the good word of OpenDIMS to the masses, we have made it possible to get a limited DEMO of OpenDIMS.</p>
      <p><img height={75} src={require('assets/img/webshop8-logo.white.svg').default} alt='Webshop8' style={{float:"right",backgroundColor:"black",margin:4,padding:8}}/>
        <img height={75} src={require('assets/img/dandomain-logo.svg').default} alt='DanDomain' style={{float:"right",margin:4,padding:8}}/>
        We also partnered with DanDomain to integrate OpenDIMS into their Classic Webshop8 app store, so it is now even easier than before to get started using OpenDIMS with DanDomain.</p>
        <strong>Other news in this version</strong>
        <ul>
          <li>Improved background color on login to make it more seamless with the systems main colors.</li>
          <li>Fixed some bugs in the DanDomains Classic connectors.</li>
        </ul>
      </Grid>,
    },
    images: [],
  },
  {
    version: "2.3.2",
    date: "2023-03-06 15:30:00",
    da: {
      title: "Adgang til jeres firma oplysninger",
      description: <Grid><p>I kan nu selv rette (kræver administrator rettigheder) firma oplysninger og ændre logoet ved at gå op i menuen øverst til højre og trykke på "Min profil".</p>
        <strong>Andre nyheder i denne version</strong>
        <ul>
          <li>Importer en CSV file hvor der søges efter datatypens nummer felt og ikke OpenDIMS ID.</li>
          <li>Rettet problem med at gemme en ny modtager på en kanal.</li>
          <li>Rettet problem med hente den korrekte eksporteret CSV file.</li>
        </ul>
      </Grid>,
    },
    en: {
      title: "Access to your company details",
      description: <Grid><p>You can now change (requires administrator privileges) the company information and logo by accessing your profile under the top right menu "My profile".</p>
        <strong>Other news in this version</strong>
        <ul>
          <li>Import a CSV file were rows are matched on the entity type number field instead of the OpenDIMS ID.</li>
          <li>Fixed a problem with saving destination in channels.</li>
          <li>Fixed an issue fetching the correct exported CSV file.</li>
        </ul>
      </Grid>,
    },
    images: [],
  },
  {
    version: "2.3.3",
    date: "2023-03-21 08:20:00",
    da: {
      title: "Nemmere navigering",
      description: <Grid><p>Du kan nu navigere i tabeller både over tabellen og i bunden.</p>
        <strong>Andre nyheder i denne version</strong>
        <ul>
          <li>Rettet så der ikke vises dubletter ved søgning eller sortering i data lister.</li>
          <li>Lande og sprog er nu sorteret alfabetisk i vælgeren.</li>
          <li>Valgmuligheds felt værdier som ikke bruger sprog vises nu korrekt.</li>
          <li>Beregnet felt "Procent udfyldte værdier" vises nu korrekt for alle sprog.</li>
          <li>Begrænset list søgning til max 32 tegn, for at undgå overbelastning efter paste af unødig lang tekst.</li>
        </ul>
      </Grid>,
    },
    en: {
      title: "Easier navigation",
      description: <Grid><p>You can now navigate in tables both above the table and at the bottom.</p>
        <strong>Other news in this version</strong>
        <ul>
          <li>Corrected so that duplicates are not displayed when searching or sorting in data lists.</li>
          <li>Countries and languages are now sorted alphabetically in the selector.</li>
          <li>Option field values that do not use language are now displayed correctly.</li>
          <li>Calculated field "Percent Filled Values" now displays correctly for all languages.</li>
          <li>Limited list search to max 32 characters, to avoid overloading after pasting unnecessarily long text.</li>
        </ul>
      </Grid>,
    },
    images: [],
  },
  {
    version: "2.3.4",
    date: "2023-03-23 14:00:00",
    da: {
      title: "Creative Company Dropshipping",
      description: <Grid><p>Du kan nu hente produkter og sende dropshipping ordrer til Creative Company Hobby.</p>
        <strong>Andre nyheder i denne version</strong>
        <ul>
          <li>Rettet et par fejl i simple CSV import, og forbedret fejl beskederne.</li>
          <li>Rettet problemer med standard værdier i DanDomain (SmartWeb) webshop product oprettelse.</li>
        </ul>
      </Grid>,
    },
    en: {
      title: "Creative Company Dropshipping",
      description: <Grid><p>You can now pick up products and send dropshipping orders to Creative Company Hobby.</p>
        <strong>Other news in this version</strong>
        <ul>
          <li>Fixed a few errors in simple CSV import, and improved the error messages.</li>
          <li>Fixed problems with default values in DanDomain (SmartWeb) webshop product creation.</li>
        </ul>
      </Grid>,
    },
    images: [],
  },
  {
    version: "2.3.5",
    date: "2023-05-01 08:00:00",
    da: {
      title: "Der er lavet en del forbedringer",
      description: <Grid><p>Vi har fokuseret på at lave mindre forbedringer i denne version, som gør hverdagen nemmere.</p>
        <strong>I denne version</strong>
        <ul>
          <li>Alle roller er valgt på oprettelse af nye datatyper, lister og visninger.</li>
          <li>Søg og naviger i data lister, mens du vælger flere rækker fra forskellige sider som du kan eksportere, slette, etc.</li>
          <li>Start og stop kanaller direkte i oversigten.</li>
          <li>Hold øje med OpenDIMS systemerne via <a href="https://status.opendims.com/" target="_blank" rel="noreferrer">status siden</a>.</li>
          <li>Rettet et problem med overførsel af kategori billeder til DanDomain/Smartweb webshops.</li>
          <li>Tilføjet mulighed for at eksportere til JSON filer.</li>
          <li>Forbindelses log er erstattet af en generel handlings log som er mere detaljeret.</li>
        </ul>
        <em>Der er også startet på at implementere et stort nyt workflow system, som ikke helt er klar til frigivelse endnu...</em>
      </Grid>,
    },
    en: {
      title: "We have made several improvements",
      description: <Grid><p>We have focused on making minor improvements in this version that make everyday life easier.</p>
        <strong>In this version</strong>
        <ul>
          <li>All roles are selected on creation of new data types, lists and views.</li>
          <li>Search and navigate in data lists while selecting multiple rows from different pages that you can export, delete, etc.</li>
          <li>Start and stop channels directly in the overview.</li>
          <li>Keep an eye on the OpenDIMS systems via <a href="https://status.opendims.com/" target="_blank" rel="noreferrer">status page</a>.</li>
          <li>Corrected a problem with the transfer of category images to DanDomain/Smartweb webshops.</li>
          <li>Added option to export to JSON files.</li>
          <li>The connection log has been replaced by a general action log which is more detailed.</li>
        </ul>
        <em>We have also started implementing a large new workflow system, which is not quite ready for release yet...</em>
      </Grid>,
    },
    images: [],
  },
  {
    version: "2.4.0",
    date: "2023-10-03 08:00:00",
    da: {
      title: "Du kan nu eksportere og importere Excel filer",
      description: <Grid><p>Vi har skiftet grund motoren ud i import/eksport så den nu kan håndtere Excel filer direkte. Vi kan stadig læse CSV filer men vil nu altid eksportere i XLSX formatet.</p>
        <p>Der er blevet lavet en forbindelses metode til at hente og sende emails via IMAP og SMTP.</p>
        <p>Workflow systemet er endelig blevet frigivet. Indtil videre er det primært for start af kanaler/integrationer, men mulighederne udvides hele tiden.</p>
        <p>Udover dette har vi lavet mange forbedringer og nogen fejlrettelser.</p>
      </Grid>,
    },
    en: {
      title: "You can now export and import Excel files",
      description: <Grid><p>The main library for writing and reading files have been changed, which means it can handle Excel files directly. We can still read CSV files, but we will always export Excel files.</p>
        <p>We have also made a connection to fetch and send emails via IMAP and SMTP.</p>
        <p>The workflow system has finally been released. For now it is mainly for starting channels/integrations, but the possibilities are expanded all the time.</p>
        <p>A lot of improvements and some fixes has also been done.</p>
      </Grid>,
    },
    images: [],
  },
  {
    version: "2.4.1",
    date: "2023-10-03 08:00:00",
    da: {
      title: "Nemmere håndtering af relaterede data",
      description: <Grid>
        <p>Det er nu nemmere at oprette relaterede data, som f.eks. at oprette ordre linjer direkte fra ordren.</p>
        <p>Nye felttyper er blevet tilføjet.</p>
        <ul>
          <li>Fortløbende nummer med bruger angivet start.</li>
          <li>Knap der kan sætte en værdi hvis feltet ikke har denne værdi, samt validere at andre felter er udfyldt før knappen er aktiv.</li>
          <li>Genering af værdi ud fra et defineret script som kan bruge andre felters værdier.</li>
        </ul>
        <p>Felter kan nu have en standard værdi som en fast tekst, fra brugerens info eller taget fra en relateret data værdi.</p>
        <p>Udover dette har vi lavet mindre forbedringer og fejlrettelser.</p>
      </Grid>,
    },
    en: {
      title: "Easier handling of related data",
      description: <Grid>
        <p>It is now easier to create related data, such as to create order lines directly from the order.</p>
        <p>New field types have been added.</p>
        <ul>
          <li>Sequential number with a user defined start</li>
          <li>Button that can set a value if the field does not have this value, as well as validate that other fields are filled in before the button is active.</li>
          <li>Generating value from a defined script which can use the values of other fields.</li>
        </ul>
        <p>Fields can now have a default value as a fixed text, from the user's info or taken from a related data value.</p>
        <p>In addition to this, we have made minor improvements and bug fixes.</p>
      </Grid>,
    },
    images: [],
  },
  {
    version: "2.5.0",
    date: "2023-12-19 12:30:00",
    da: {
      title: "PDF udskrifter",
      description: <Grid>
        <p>Nu kan du oprette PDF udskrifter med vores nye træk og slip funktionalitet.</p>
        <ul>
          <li>I side layout kan du styre margener.</li>
          <li>Opstil dine data med tabel elementet hvor du kan styre antal rækker og kolonner, samt hver celle
            individuelt.
          </li>
          <li>Nye system data felt typer, såsom dato, tid og brugerens navn ved genering af PDF'en.</li>
          <li>Indsæt tekster og billeder.</li>
        </ul>
        <p>Udover dette har vi lavet mindre forbedringer og fejlrettelser.</p>
      </Grid>,
    },
    en: {
      title: "PDF printouts",
      description: <Grid>
        <p>Now you can create PDF prints with our new drag and drop functionality.</p>
        <ul>
          <li>In page layout you can control margins.</li>
          <li>Set up your data with the table element where you can control the number of rows and columns, as well as
            each cell individually.
          </li>
          <li>New system data field types, such as date, time and the user's name when generating the PDF.</li>
          <li>Insert texts and images.</li>
        </ul>
        <p>In addition to this, we have made minor improvements and bug fixes.</p>
      </Grid>,
    },
    images: [],
  },
  {
    version: "3.0.0",
    date: "2024-02-05 15:30:00",
    da: {
      title: "Formular design af data redigering for hver datatype",
      description: <Grid>
        <p>Vi er glade for at præsentere vores seneste opdatering til OpenDIMS</p>
        <p>Nu kan brugerne nyde en forbedret og tilpasselig oplevelse med introduktionen af en innovativ
          formular-designfunktion. Med denne funktion kan du nu intuitivt styre størrelsen og placeringen af felter på
          redigeringssiden. Dette giver dig fuld kontrol over layoutet, hvilket gør det lettere end nogensinde at
          tilpasse og optimere din dataindtastningsproces.</p>
        <ul>
          <li>Opstil felter i blokke, og farve giv blokke for at fremhæve dem.</li>
          <li>Nemmere håndtering af data typer, ved at samle alle informationer.</li>
          <li>Se dine ændringer på data'et inden du gemmer dem.</li>
        </ul>
        <p>Gør dig klar til en mere effektiv og tilpasset redigeringsoplevelse med vores opdaterede system!</p>
      </Grid>,
    },
    en: {
      title: "Form design of data editing for each data type",
      description: <Grid>
        <p>We are excited to present our latest update to OpenDIMS.</p>
        <p>Now users can enjoy an enhanced and customizable experience with the introduction of an innovative form
          design feature. With this feature, you can intuitively control the size and placement of fields on the
          editing page. This gives you full control over the layout, making it easier than ever to customize and
          optimize your data entry process.</p>
        <ul>
          <li>Arrange fields in blocks and color give blocks to highlight them.</li>
          <li>Easier handling of data types, by collecting all information.</li>
          <li>See your changes to the data before saving them.</li>
        </ul>
        <p>Get ready for a more efficient and customizable editing experience with our updated system!</p>
      </Grid>,
    },
    images: [
      require('assets/img/versions/form-designer.png'),
      require('assets/img/versions/data-editing.png'),
      require('assets/img/versions/changes.png'),
    ],
  }
]
export default versions;
