import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  TextField
} from '@material-ui/core';

import {Business, Person} from '@material-ui/icons';
// import Comments from "components/Comments/Comments";
import {authRefresh, authUser, useAuthDispatch} from 'contexts/Auth';
import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {LocaleContext} from 'contexts/Intl';
import EditForm from "../components/Form/EditForm";
import {rolePriorities} from "../config";
import {graphQLApi} from "../services/GraphQLApi";
import Link from "@material-ui/core/Link";
import themeColors from "../assets/theme/colors";
import {useLocation, useSearchParams} from "react-router-dom";

moment.locale(window.language);

export default function UsersProfile() {
  const intl = useIntl();
  const [_locale, setLocale] = useContext(LocaleContext);
  const location = useLocation();
  const searchParams = useSearchParams();

  const userFields = [
    {
      field: "title",
      label: intl.formatMessage({
        id: "user-profile.label.title",
        defaultMessage: "Title",
      }),
    },
    {
      field: "name",
      required: true,
      label: intl.formatMessage({
        id: "user-profile.label.name",
        defaultMessage: "Name",
      }),
    },
    {
      field: "email",
      required: true,
      label: intl.formatMessage({
        id: "user-profile.label.email",
        defaultMessage: "E-mail",
      }),
    },
    {
      field: "phone",
      label: intl.formatMessage({
        id: "user-profile.label.phone",
        defaultMessage: "Phone",
      }),
    },
    {
      field: "locale",
      label: intl.formatMessage({
        id: "user-profile.label.language",
        defaultMessage: "Language",
      }),
      options: [
        {id: "da", name: intl.formatMessage({id: "common.language.danish", defaultMessage: "Danish"})},
        {id: "en", name: intl.formatMessage({id: "common.language.english", defaultMessage: "English"})},
      ],
    },
    {
      field: "password",
      label: intl.formatMessage({
        id: "user-profile.label.password",
        defaultMessage: "Password",
      }),
    },
    {
      field: "password_confirmation",
      label: intl.formatMessage({
        id: "user-profile.label.passwordConfirm",
        defaultMessage: "Confirm password",
      }),
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({title: "",
    name: "",
    email: "",
    phone: "",
    locale: "",
    password: "",
    password_confirmation: "",});
  const initialValidation = {
    title: [],
    name: [],
    email: [],
    phone: [],
    locale: [],
    password: [],
    password_confirmation: [],
  };

  const [validation, setValidation] = useState(initialValidation);
  const setValidationFromErrors = (errors) => {
    if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
      setValidation({...initialValidation, ...errors[0].extensions.validation});
    }
  };

  const client = new graphQLApi(useAuthDispatch(), null, {handleErrors: setValidationFromErrors})
  useEffect(() => {
    setIsLoading(true);
    client.query('{' +
          'users(filter:{id:' + authUser().id + '}){data' +
            '{id name title phone email locale}' +
          '}' +
        '}').then(r => {
      if (r && r.hasOwnProperty('users')) {
        setUser({...user, ...r.users.data[0]});
      }
      setIsLoading(false);
    });
  }, []);

  const save = () => {
    const data = {...user};
    const vars = {
      id: "ID!",
      title: "String",
      name: "String",
      phone: "String",
      email: "String",
      locale: "String",
      password: "String",
      password_confirmation: "String",
    };
    setIsLoading(true);
    client.mutation('user', vars, data, 'id').then(r => {
      if (r && r.hasOwnProperty('response')) {
        authRefresh(true).then(() => {
          setLocale(data.locale);
          window.location.reload();
        });
      } else {
        setIsLoading(false);
      }
    });
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sm={11} md={10} lg={7} xl={6}>
        {isLoading
          ? <center style={{margin:"3rem"}}><CircularProgress/></center>
          : <Card>
          <CardHeader color="primary" avatar={<Person/>} title={<FormattedMessage
            id={"user-profile.heading"}
            defaultMessage={"Your profile"}
          />}/>
          <CardContent>
            <EditForm
              validation={validation}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              data={user}
              setData={setUser}
              fields={userFields}
              cols={2}
            />
          </CardContent>
          <CardActions style={{justifyContent: "flex-end"}}>
            <Button
              variant="contained"
              color="primary"
              onClick={save}
            ><FormattedMessage id={"common.button.save"}/></Button>
          </CardActions>
        </Card>}
      </Grid>
    </Grid>
  );
}
