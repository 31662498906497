import {authHeader, authRefresh, authUser, getApiDomain} from "../contexts/Auth";
import {Button, Checkbox, CircularProgress, FormControlLabel, Grid, Paper, TextField} from "@material-ui/core";
import themeColors from "../assets/theme/colors";
import React, {useState} from "react";
import {useIntl} from "react-intl";


export default function AppOrder(props) {
  const {
    isDanDomain = /^dandomain_.*$/.test(authUser().site?.schema),
    showThankYou,
    setShowThankYou,
    setShowDialog
  } = props;
  const intl = useIntl();
  const [choice, setChoice] = useState(authUser().site?.package);
  const defaultFields = {
    company: "",
    address: "",
    zip: "",
    city: "",
    country: "",
    cvr: "",
    contact: "",
    email: "",
    phone: "",
    invoice: "",
    accept: false
  };
  const [customer, setCustomer] = useState({
    ...defaultFields,
    country: "Denmark",
    email: authUser().email,
    contact: authUser().name,
    phone: authUser().phone
  });
  const [errors, setErrors] = useState(defaultFields);
  const [isProcessing, setIsProcessing] = useState(false);

  const textUnlimited = intl.formatMessage({id: "demo.dialog.choices.unlimited", defaultMessage: "Unlimited"});
  const packages = [
    {
      name: "starter",
      price: intl.formatMessage({
        id: "demo.dialog.choices.price_per_month",
        defaultMessage: "{price} DKK / month"
      }, {price: 3500}),
      popular: false,
      limits: {
        entities: "10k",
        storage: textUnlimited,
        users: textUnlimited,
        types: textUnlimited,
        connections: textUnlimited,
      }
    },
    {
      name: "standard",
      price: intl.formatMessage({
        id: "demo.dialog.choices.price_per_month",
        defaultMessage: "{price} DKK / month"
      }, {price: 4000}),
      popular: false,
      limits: {
        entities: "50k",
        storage: textUnlimited,
        users: textUnlimited,
        types: textUnlimited,
        connections: textUnlimited,
      }
    },
    {
      name: "premium",
      price: intl.formatMessage({
        id: "demo.dialog.choices.price_per_month",
        defaultMessage: "{price} DKK / month"
      }, {price: 5000}),
      popular: true,
      limits: {
        entities: "100k",
        storage: textUnlimited,
        users: textUnlimited,
        types: textUnlimited,
        connections: textUnlimited,
      }
    },
    {
      name: "enterprise",
      price: intl.formatMessage({id: "demo.dialog.choices.call_for_price", defaultMessage: "Call for price"}),
      popular: false,
      limits: {
        entities: textUnlimited,
        storage: textUnlimited,
        users: textUnlimited,
        types: textUnlimited,
        connections: textUnlimited,
      }
    },
  ]

  const order = () => {
    let error = false;
    let errors = defaultFields;
    if (!isDanDomain) {
      for (let k in defaultFields) {
        if (k === "invoice") continue;
        if (customer[k] === "" || customer[k] === false) {
          error = true;
          errors[k] = true;
        }
      }
    } else {
      if (choice === "enterprise" && customer.phone === "") {
        errors.phone = true;
        error = true;
      }
      if (customer.accept === false) {
        errors.accept = true;
        error = true;
      }
    }
    setErrors(errors);
    if (!error) {
      let formData = new FormData();
      formData.append('package', choice);
      let c = {...customer};
      for (let k in defaultFields) {
        if (k === "invoice") continue;
        formData.append(k, c[k]);
      }
      setIsProcessing(true);
      fetch(getApiDomain() + '/app-order', {
        headers: {...authHeader(), Accept: "text/json"},
        method: "POST",
        body: formData,
        mode: 'cors',
      }).then(r => {
        if (r.status === 200) {
          let p = authRefresh(true, false);
          if (p) {
            p.then(_r => {
              setShowThankYou(true);
              setIsProcessing(false);
            });
          }
          else {
            setShowThankYou(true);
            setIsProcessing(false);
          }
        }
        else {
          setIsProcessing(false);
        }
        return r.json();
      }).then(r => setErrors({...defaultFields, ...r?.errors}));
    }
  }

  if (showThankYou)
    return <Grid container spacing={2} justifyContent={"center"}>
      <Grid item xs={12} style={{textAlign: "center"}}><img
        src={require('assets/img/versions/celebrate-800.jpg').default} alt={"Celebrate"}/></Grid>
      <Grid item xs={2}><Button onClick={_e => {
        setShowThankYou(false);
        setShowDialog(false);
      }}>{intl.formatMessage({id: "common.button.close"})}</Button></Grid>
    </Grid>;
  else if (!isDanDomain && authUser().site?.order_sent_at)
   return <Grid container spacing={2} justifyContent={"center"}>
     <Grid item xs={12} style={{textAlign: "center"}}><img
       src={require('assets/img/working-800.jpg').default} alt={"Working"}/></Grid>
     <Grid item xs={12} style={{textAlign: "center"}}>{
       intl.formatMessage({
         id: "demo.dialog.thankyou.info",
         defaultMessage: "We will process your order as quickly as humanly possible and you will receive an invoice for your first month very quickly!"
       })
     }</Grid>
     <Grid item xs={2}><Button onClick={_e => {
       setShowThankYou(false);
       setShowDialog(false);
     }}>{intl.formatMessage({id: "common.button.close"})}</Button></Grid>
   </Grid>;
  else
    return <Grid container spacing={3} style={{justifyContent: "center"}}>
        <Grid item xs={12} style={{textAlign: "center"}}>{intl.formatMessage({
          id: "demo.dialog.packages.header",
          defaultMessage: "Your current package has been selected, but feel free to change your choice."
        })}</Grid>
        {packages.filter(p => !isDanDomain || p.name !== 'starter').map(p => <Grid item
                                                                                   xs={3}
                                                                                   key={'package_' + p.name}>
          <Paper elevation={4} style={{
            backgroundColor: themeColors.primary.text,
            border: "2px solid " + (choice === p.name ? themeColors.success.main : themeColors.primary.text)
          }}>
            <Grid container>
              <Grid item xs={12} style={{textAlign: "center"}}>{p.popular
                ? <span style={{
                  borderRadius: 4,
                  backgroundColor: themeColors.success.badge,
                  color: themeColors.white.main,
                  padding: "4px 16px",
                  fontWeight: "bold",
                }}>{intl.formatMessage({id: "demo.dialog.choices.popular", defaultMessage: "Popular"})}</span>
                : <span style={{padding: "4px 16px"}}>&nbsp;</span>
              }</Grid>
              <Grid item xs={12} style={{
                textAlign: "center",
                textTransform: "capitalize",
                fontSize: "1.2rem",
                fontWeight: "bold",
                paddingTop: 4
              }}>{p.name}</Grid>
              <Grid item xs={12} style={{textAlign: "center"}}>
                <h4>{p.price}</h4>
              </Grid>
              <Grid item xs={12} style={{textAlign: "center"}}>
                <Button
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={_e => setChoice(p.name)}
                >{intl.formatMessage({id: "common.button.choose", defaultMessage: "Choose"})}</Button>
              </Grid>
              <Grid item xs={12} style={{textAlign: "center", padding: 8}}>
                <small>
                  <strong>{p.limits.entities}</strong> {intl.formatMessage({id: "demo.dialog.choices.limits.entities", defaultMessage: "entities / ch. job"})}<br/>
                  <strong>{p.limits.storage}</strong> {intl.formatMessage({id: "demo.dialog.choices.limits.storage", defaultMessage: "storage"})}<br/>
                  <strong>{p.limits.users}</strong> {intl.formatMessage({id: "demo.dialog.choices.limits.users", defaultMessage: "users"})}<br/>
                  <strong>{p.limits.types}</strong> {intl.formatMessage({id: "demo.dialog.choices.limits.types", defaultMessage: "entity types"})}<br/>
                  <strong>{p.limits.connections}</strong> {intl.formatMessage({id: "demo.dialog.choices.limits.connections", defaultMessage: "connections"})}<br/>
                </small>
              </Grid>
            </Grid>
          </Paper>
        </Grid>)}
        {isDanDomain
          ? choice === "enterprise" && <Grid item xs={4}>
          <TextField
            fullWidth
            required
            error={!!errors.phone}
            label={intl.formatMessage({id: "demo.dialog.phone", defaultMessage: "Telephone"})}
            value={customer.phone}
            onChange={e => setCustomer({...customer, phone: e.target.value})}
          />
        </Grid>
          : <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>{intl.formatMessage({
                id: "demo.dialog.company.header",
                defaultMessage: "Please enter your company information below."
              })}</Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      error={!!errors.company}
                      label={intl.formatMessage({id: "demo.dialog.company", defaultMessage: "Company"})}
                      value={customer.company}
                      onChange={e => setCustomer({...customer, company: e.target.value})}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      error={!!errors.address}
                      label={intl.formatMessage({id: "demo.dialog.address", defaultMessage: "Address"})}
                      value={customer.address}
                      onChange={e => setCustomer({...customer, address: e.target.value})}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      required
                      error={!!errors.zip}
                      label={intl.formatMessage({id: "demo.dialog.zip", defaultMessage: "Zip"})}
                      value={customer.zip}
                      onChange={e => setCustomer({...customer, zip: e.target.value})}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      required
                      error={!!errors.city}
                      label={intl.formatMessage({id: "demo.dialog.city", defaultMessage: "City"})}
                      value={customer.city}
                      onChange={e => setCustomer({...customer, city: e.target.value})}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      error={!!errors.country}
                      label={intl.formatMessage({id: "demo.dialog.country", defaultMessage: "Country"})}
                      value={customer.country}
                      onChange={e => setCustomer({...customer, country: e.target.value})}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      error={!!errors.cvr}
                      label={intl.formatMessage({id: "demo.dialog.cvr", defaultMessage: "VAT Reg."})}
                      value={customer.cvr}
                      onChange={e => setCustomer({...customer, cvr: e.target.value})}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      error={!!errors.contact}
                      label={intl.formatMessage({id: "demo.dialog.contact", defaultMessage: "Contact name"})}
                      value={customer.contact}
                      onChange={e => setCustomer({...customer, contact: e.target.value})}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      error={!!errors.email}
                      label={intl.formatMessage({id: "demo.dialog.email", defaultMessage: "E-mail"})}
                      value={customer.email}
                      onChange={e => setCustomer({...customer, email: e.target.value})}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      error={!!errors.phone}
                      label={intl.formatMessage({id: "demo.dialog.phone", defaultMessage: "Telephone"})}
                      value={customer.phone}
                      onChange={e => setCustomer({...customer, phone: e.target.value})}
                    />
                  </Grid>
                  {/*<Grid item xs={12}>
                    <TextField
                      fullWidth
                      error={!!errors.invoice}
                      label={intl.formatMessage({id: "demo.dialog.invoice_email", defaultMessage: "Invoice e-mail"})}
                      value={customer.invoice}
                      onChange={e => setCustomer({...customer, invoice: e.target.value})}
                    />
                  </Grid>*/}
                </Grid>
              </Grid>
            </Grid>
          </Grid>}
        <Grid item xs={9}>
          <FormControlLabel
            style={{color: errors.accept ? themeColors.error.main : "inherit"}}
            control={
              <Checkbox
                checked={customer.accept}
                onChange={e => setCustomer({
                  ...customer,
                  accept: e.target.checked
                })}
                required
                name="accepted"
              />}
            label={isDanDomain
              ? intl.formatMessage({
                id: "demo.dialog.accept_terms",
                defaultMessage: "I accept the terms & conditions and acknowledge my package choice above. Be aware that invoicing for OpenDIMS is done by DanDomain/TeamBlue."
              })
              : intl.formatMessage({
                id: "demo.dialog.accept_terms_requires_payment",
                defaultMessage: "I accept the terms & conditions and acknowledge my package choice above. My OpenDIMS will be opened once my payment has been registered by OpenSUN."
              })}/>
        </Grid>
        <Grid item xs={3}>
          <a style={{fontStyle: "italic", fontSize: "smaller"}} href="https://opensunsystems.com/terms"
             target="_blank" rel="noreferrer">{intl.formatMessage({
            id: "demo.dialog.read_terms",
            defaultMessage: "OpenSUN terms & conditions"
          })}</a>
        </Grid>
        <Grid item xs={12} style={{display: "flex", justifyContent: "space-around"}}>{isProcessing
          ? <CircularProgress/>
          : (showThankYou || !!authUser().site?.order_sent_at)
            ? <Button onClick={_e => {
              setShowThankYou(false);
              setShowDialog(false);
            }}>{intl.formatMessage({id: "common.button.close"})}</Button>
            : <Button
              variant="contained"
              color="primary"
              onClick={order}
            >{
              intl.formatMessage({id: "demo.dialog.button.order", defaultMessage: "Order OpenDIMS"})
            }</Button>
        }</Grid>
      </Grid>;
}
