import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Card, CardContent, CardHeader, Grid, MenuItem, Paper, Tab, Tabs, TextField} from "@material-ui/core";
import {Dns, Domain} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";
import moment from "moment";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {useParams} from "react-router-dom";
import {Bar, Line} from "react-chartjs-2";
import {graphQLApi} from "../../../services/GraphQLApi";
import {useAuthDispatch} from "../../../contexts/Auth";
import theme from "../../../assets/theme/theme";
import {
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip
} from "chart.js";
import 'chartjs-adapter-moment';

Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(TimeScale);
Chart.register(PointElement);
Chart.register(LineElement);
Chart.register(BarElement);
Chart.register(Legend);
Chart.register(Tooltip);

export default function SiteEdit(props) {
  const intl = useIntl();
  const params = useParams();
  const [extraInfo, setExtraInfo] = useState(null);
  const [tab, setTab] = useState(0);
  const defDatasets = {
    labels: [],
    datasets: [
      {
        label: intl.formatMessage({id: "sites.edit.stats.values", defaultMessage: "Values used"}),
        data: [],
        borderColor: theme.palette.warning.main,
        fill: false,
        tension: 0.4,
        yAxisID: 'y1',
      },
      {
        label: intl.formatMessage({id: "sites.edit.stats.credits", defaultMessage: "Credits used"}),
        data: [],
        borderColor: theme.palette.info.main,
        fill: false,
        tension: 0.4,
        yAxisID: 'y',
      },
    ],
  };
  const [totals, setTotals] = useState({
    labels: [],
    datasets: [
      {
        label: intl.formatMessage({id: "sites.edit.stats.values", defaultMessage: "Values used"}),
        data: [],
        borderColor: theme.palette.warning.main,
      },
      {
        label: intl.formatMessage({id: "sites.edit.stats.credits", defaultMessage: "Credits used"}),
        data: [],
        borderColor: theme.palette.info.main,
      },
    ],
  });
  const [stats, setStats] = useState(defDatasets);

  let id = Number(params.id);

  const fields = [
    {
      column: 1,
      field: "name",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.name",
        defaultMessage: "Name",
      }),
      input: "text",
    },
    {
      column: 1,
      field: "package",
      initial: "premium",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.package",
        defaultMessage: "Package",
      }),
      options: [
        {id: "starter", name: "Starter"},
        {id: "standard", name: "Standard"},
        {id: "premium", name: "Premium"},
        {id: "enterprise", name: "Enterprise"},
        {id: "openconnect", name: "OpenConnect"},
        {id: "template", name: "Template"},
      ]
    },
    {
      column: 1,
      field: "reference",
      initial: "-",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.reference",
        defaultMessage: "Reference",
      }),
      input: "text",
    },
    {
      column: 1,
      field: "is_active",
      initial: false,
      type: "Boolean",
      label: intl.formatMessage({
        id: "sites.edit.label.is_active",
        defaultMessage: "Is Active",
      }),
      input: "switch",
    },
    {
      column: 1,
      field: "demo_expire_at",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.demo_expire_at",
        defaultMessage: "Demo expires at",
      }),
      input: "date",
    },
    {
      column: 1,
      field: "support",
      type: "String",
      initial: "",
      label: "",
      render: (key, data, setData) => {
        return <TextField
          label={intl.formatMessage({
            id: "sites.edit.label.support",
            defaultMessage: "Your current support agreement",
          })}
          value={data['support'] ? data['support'] : data['support_agreements'] ? data['support_agreements'][0].id : ''}
          onChange={e => setData({...data, support: e.target.value})}
          select={!!data['support_agreements']}
          fullWidth
        >
          {data['support_agreements']?.map(a => <MenuItem key={'support_' + a.id} value={a.id}>{a.Titel}</MenuItem>)}
        </TextField>
      },
    },
    {
      column: 2,
      field: "company",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.company",
        defaultMessage: "Company name",
      }),
    },
    {
      column: 2,
      field: "address",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.address",
        defaultMessage: "Address",
      }),
    },
    {
      column: 2,
      field: "zip",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.zip",
        defaultMessage: "Zip",
      }),
    },
    {
      column: 2,
      field: "city",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.city",
        defaultMessage: "City",
      }),
    },
    {
      column: 2,
      field: "country",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.country",
        defaultMessage: "Country",
      }),
    },
    {
      column: 2,
      field: "logo",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.logo",
        defaultMessage: "Click to set your logo",
      }),
      input: "file",
    },
    {
      column: 3,
      field: "cvr",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.cvr",
        defaultMessage: "VAT reg.",
      }),
    },
    {
      column: 3,
      field: "invoice_email",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.invoice_email",
        defaultMessage: "Invoice e-mail",
      }),
    },
    {
      column: 3,
      field: "contact",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.contact",
        defaultMessage: "Contact",
      }),
    },
    {
      column: 3,
      field: "phone",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.phone",
        defaultMessage: "Telephone",
      }),
    },
    {
      column: 3,
      field: "email",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.email",
        defaultMessage: "E-mail",
      }),
    },
  ];

  const domainColumns = [
    {
      title: intl.formatMessage({
        id: "domains.list.column.name",
        defaultMessage: "Name",
      }),
      field: "name",
    },
    {
      title: intl.formatMessage({
        id: "domains.list.column.is_active",
        defaultMessage: "Is Active",
      }),
      field: "is_active",
      type: "boolean",
      initial: false,
    },
    {
      title: intl.formatMessage({
        id: "domains.list.column.is_verified",
        defaultMessage: "Is Verified",
      }),
      field: "is_verified",
      type: "boolean",
      initial: false,
    },
  ];

  const monthsCounter = (months, d, type) => {
    let date = moment(d.created_at);
    let ym = date.format('YYYYMM');
    if (!months.hasOwnProperty(ym)) {
      months[ym] = {credits: 0, values: 0};
    }
    if (months.hasOwnProperty(ym)) {
      if (type === 'credits')
        months[ym][type] += parseInt(d.counter);
      else if (months[ym][type] < parseInt(d.counter))
        months[ym][type] = parseInt(d.counter);
    }
    return months;
  }
  const client = new graphQLApi(useAuthDispatch());
  useEffect(() => {
    client.query('{statistics(filter:{stats_type:"App\\\\Models\\\\Site", stats_id:' + id + '}){data{stats_type counter created_at}}}').then(r => {
      if (r?.hasOwnProperty('statistics')) {
        let months = {};
        let monthlySets = [...totals.datasets];
        let dailySets = [...stats.datasets];
        dailySets[0].data = [];
        r.statistics.data.filter(d => d.stats_type.endsWith(':values')).forEach(d => {
          dailySets[0].data.push({
            y: d.counter,
            x: d.created_at,
          });
          months = monthsCounter(months, d, 'values');
        });
        dailySets[1].data = [];
        r.statistics.data.filter(d => d.stats_type.endsWith(':credits')).forEach(d => {
          dailySets[1].data.push({
            y: d.counter,
            x: d.created_at,
          });
          months = monthsCounter(months, d, 'credits');
        })
        monthlySets[0].data = [];
        monthlySets[1].data = [];
        for (let m in months) {
          monthlySets[0].data.push({
            x: months[m].values,
            y: m.substring(0, 4) + '-' + m.substring(4, 6) + '-01',
          });
          monthlySets[1].data.push({
            x: months[m].credits,
            y: m.substring(0, 4) + '-' + m.substring(4, 6) + '-01',
          });
        }
        setTotals({...totals, datasets: monthlySets});
        setStats({...stats, datasets: dailySets});
      }
    });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Tabs value={tab} onChange={(_, n) => setTab(n)}>
          <Tab id={0} label={intl.formatMessage({id: "sites.edit.tab.settings", defaultMessage: "Settings"})}></Tab>
          <Tab id={1} label={intl.formatMessage({id: "sites.edit.tab.domains", defaultMessage: "Domains"})}></Tab>
          <Tab id={2} label={intl.formatMessage({id: "sites.edit.tab.usage", defaultMessage: "Usage"})}></Tab>
        </Tabs>
        {tab === 0 && <Card>
          <CardHeader
            color="primary"
            avatar={<Domain/>}
            title={intl.formatMessage({id: "sites.edit.heading"})}/>
          <CardContent>
            <GraphQLEditForm
              {...props}
              id={id}
              query={"sites"}
              mutations={"site"}
              fields={fields}
              cols={3}
              extraFields={" support schema created_at order_sent_at settings"}
              extraQueries={"support_agreements:supportAgreements{id Titel}"}
              extraResults={["support_agreements"]}
              buttons={[
                {
                  label: intl.formatMessage({id: "sites.edit.view_extras", defaultMessage: "Extra info"}),
                  onClick: (_e, data, _setData) => setExtraInfo(extraInfo ? null : data),
                  color: "primary",
                },
                {
                  label: intl.formatMessage({id: "sites.edit.payment_received", defaultMessage: "Payment received"}),
                  onClick: (_e, data, setData) => setData({...data, demo_expire_at: null}),
                  color: "secondary",
                  variant: "outlined"
                }
              ]}
            />
            {extraInfo && <Grid container>
              <Grid item xs={2}>{intl.formatMessage({
                id: "sites.edit.info.schema",
                defaultMessage: "DB Schema"
              })}:</Grid>
              <Grid item xs={10}>{extraInfo.schema}</Grid>
              <Grid item xs={12} style={{borderBottom: "1px solid lightgrey"}}></Grid>
              <Grid item xs={2}>{intl.formatMessage({
                id: "sites.edit.info.created_at",
                defaultMessage: "Created at"
              })}:</Grid>
              <Grid item
                    xs={10}>{extraInfo.created_at && moment(extraInfo.created_at).format(intl.formatMessage({id: "common.datetime.format"}))}</Grid>
              <Grid item xs={12} style={{borderBottom: "1px solid lightgrey"}}></Grid>
              <Grid item xs={2}>{intl.formatMessage({
                id: "sites.edit.info.order_sent_at",
                defaultMessage: "Order sent at"
              })}:</Grid>
              <Grid item
                    xs={10}>{extraInfo.order_sent_at && moment(extraInfo.order_sent_at).format(intl.formatMessage({id: "common.datetime.format"}))}</Grid>
              <Grid item xs={12} style={{borderBottom: "1px solid lightgrey"}}></Grid>
              <Grid item xs={2}>{intl.formatMessage({
                id: "sites.edit.info.settings",
                defaultMessage: "App Settings"
              })}:</Grid>
              <Grid item xs={10}><small><tt>{extraInfo.settings}</tt></small></Grid>
            </Grid>}
          </CardContent>
        </Card>}
        {id && tab === 1 && <Paper>
          <EnhancedTable
            {...props}
            title={intl.formatMessage({
              id: "domains.list.table.heading",
              defaultMessage: "Domains list",
            })}
            columns={domainColumns}
            query="domains"
            mutations="domain"
            filter={"site_id:" + id}
            fields="id site{name} name is_active is_verified"
            icon={<Dns/>}
            options={{
              pageSize: 10,
            }}
            baseUrl={"/admin/sites/" + id + "/domains"}
          />
        </Paper>}
        {id && tab === 2 && <Grid container spacing={2}>
          <Grid item xs={12}><Paper>
            <Bar
              data={totals}
              options={{
                aspectRatio: 4,
                indexAxis: 'y',
                elements: {
                  bar: {
                    borderWidth: 2,
                  }
                },
                responsive: true,
                plugins: {
                  tooltip: {
                    mode: "index",
                  },
                },
                scales: {
                  y: {
                    type: 'time',
                    time: {
                      tooltipFormat: 'MMM YYYY',
                      unit: 'month',
                      displayFormats: {
                        month: 'MMM YYYY'
                      },
                    },
                    title: {
                      display: true,
                      text: intl.formatMessage({id: "common.month", defaultMessage: "Month"})
                    }
                  },
                  x: {
                    position: 'right',
                    title: {
                      display: true,
                      text: intl.formatMessage({id: "sites.edit.stats.credits", defaultMessage: "Credits used"})
                    },
                  },
                }
              }}
            /></Paper></Grid>
          <Grid item xs={12}><Paper><Line
            data={stats}
            options={{
              aspectRatio: 4,
              responsive: true,
              plugins: {
                tooltip: {
                  mode: "index",
                },
                legend: {
                  display: true,
                  position: "top",
                },
              },
              interaction: {
                intersect: false,
                mode: 'index',
              },
              scales: {
                x: {
                  type: 'time',
                  time: {
                    tooltipFormat: 'D. MMM',
                    unit: 'day',
                    displayFormats: {
                      day: 'D. MMM'
                    },
                  },
                  title: {
                    display: true,
                    text: intl.formatMessage({id: "common.date", defaultMessage: "Date"})
                  }
                },
                y: {
                  position: 'right',
                  title: {
                    display: true,
                    text: intl.formatMessage({id: "sites.edit.stats.credits", defaultMessage: "Credits used"})
                  },
                },
                y1: {
                  position: 'left',
                  title: {
                    display: true,
                    text: intl.formatMessage({id: "sites.edit.stats.values", defaultMessage: "Values used"})
                  },
                },
              }
            }}
          />
          </Paper></Grid></Grid>}
      </Grid>
    </Grid>
  );
}
