import React, {useContext, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField} from "@material-ui/core";
import {graphQLApi} from "../../../../services/GraphQLApi";
import {authUser, useAuthDispatch} from "../../../../contexts/Auth";
import {getFieldLabel, RenderEditableField} from "./Field";
import {LanguageContext} from "../../../../contexts/Layouts";
import {EntityContext} from "../../EntityLayoutEditor";
import {Autocomplete} from "@material-ui/lab";
import {rolePriorities} from "../../../../config";
import Button from "@material-ui/core/Button";
import FieldOptions from "../../../../components/Fields/FieldOptions";
import {Edit} from "@material-ui/icons";

export default function OptionField({
                                      field,
                                      multiple = false,
                                      // ...rest
                                    }) {
  const {language} = useContext(LanguageContext);
  const dispatcher = useAuthDispatch();
  const intl = useIntl();
  const {getValuesOfField, setValuesOfField, setEditing} = useContext(EntityContext);
  let values = getValuesOfField(field.id) || [];
  if (!multiple) {
    values = values.length ? values.pop() : null;
  }

  const [options, setOptions] = useState([]);
  // if (options?.length) {
  //   values = options.filter(o => values.map(v => v.field_option.id).includes(o.id));
  // }
  const [isLoaded, setIsLoaded] = useState(false);
  const [showEditFieldOptions, setShowEditFieldOptions] = useState(null);
  const [_fieldOptionsIsLoading, setFieldOptionsIsLoading] = useState(false);
  const fieldOptionsRef = useRef();

  const getTitle = (option) => {
    if (!option) return '';
    // console.log(field.name, option)
    let title = language.id ? option.titles.find(t => t.language_id === language.id)?.translation : option.title;
    // console.log(title)
    if (!title && option.title) {
      return option.title;
    }
    return title;
  }

  // console.log(field.name, values, options);
  return <RenderEditableField
    field={field}
    value={multiple
      ? values.filter(v => v.field_option).map(v => <Chip size="small"
                                                          key={"option-" + field.id + "-" + v.field_option.id}
                                                          label={getTitle(v.field_option)}/>)
      : values?.field_option?.id ?
        <span key={"option-" + field.id + "-" + values.field_option.id}>{getTitle(values.field_option)}</span> : null
    }
    onEditing={_ => {
      if (!isLoaded) {
        const client = new graphQLApi(dispatcher);
        setOptions([{id: null, title: <em>{intl.formatMessage({id: "common.loading"})}</em>}, ...options])
        client.query('{' +
          'fieldOptions(sorting:"sorting",filter:{field_id:' + field.id + '}){data{id title titles{language_id translation}}}' +
          '}').then(r => {
          if (r && r.hasOwnProperty('fieldOptions')) {
            setOptions(r.fieldOptions.data);
          }
          setIsLoaded(true);
        });
      }
      setEditing(field.id);
    }}
  ><Grid container spacing={1}>
    <Grid item style={{flexGrow: 1}}><Autocomplete
      size="small"
      multiple={multiple}
      fullWidth
      id={"field-" + field.id}
      options={options}
      getOptionLabel={getTitle}
      getOptionSelected={(option, value) => {
        // console.log(field.name, option.id === value.field_option_id, option, value);
        return option.id === value.field_option_id;
      }}
      value={multiple ? values.filter(v => !!v.field_option).map(v => v.field_option) : values?.field_option}
      onChange={(_, value) => {
        // console.log(field.name, multiple ? 'multiple' : 'single', value);
        if (multiple) {
          let newVals = [];
          values.forEach(v => {
            let nv = {...v};
            if (!value.find(o => o.id === nv.field_option?.id)) {
              nv.field_option_id = null;
              nv.field_option = null;
            }
            newVals.push(nv);
          })
          value.forEach(o => {
            if (!values.find(v => o.id === v.field_option?.id)) {
              newVals.push({
                field_option_id: o.id,
                field_option: o,
              });
            }
          })
          setValuesOfField(field.id, newVals);
        } else {
          setValuesOfField(field.id, [{field_option: value || null, field_option_id: value?.id || null}]);
        }
      }}
      renderInput={(params) => <TextField
        {...params}
        label={getFieldLabel(field, language)}
      />}
      onKeyUp={e => ['Enter', 'Escape'].includes(e.code) && setEditing(null)}
    /></Grid>
    {authUser().isAllowed(rolePriorities.can_fields) &&
      <Grid item style={{display: "flex"}}>
        <IconButton size="small"
                    title={intl.formatMessage({
                      id: "entity.edit.dialog.edit-options.title",
                      defaultMessage: "Edit field options"
                    })}
                    onClick={() => setShowEditFieldOptions(field)}><Edit/></IconButton>
      </Grid>}
  </Grid>
    <Dialog open={showEditFieldOptions !== null} maxWidth={"xl"}>
      <DialogTitle>{intl.formatMessage({
        id: "entity.edit.dialog.edit-options.title",
        defaultMessage: "Edit field options"
      })}</DialogTitle>
      <DialogContent>
        <FieldOptions field={showEditFieldOptions} ref={fieldOptionsRef}
                      isSaving={setFieldOptionsIsLoading}/>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (fieldOptionsRef.current && fieldOptionsRef.current.hasOwnProperty('addOption'))
              fieldOptionsRef.current.addOption();
          }}>{intl.formatMessage({id: "field_options.button.add", defaultMessage: "Add option"})}</Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setShowEditFieldOptions(null)}>{intl.formatMessage({id: "common.button.cancel"})}</Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            if (fieldOptionsRef.current && fieldOptionsRef.current.hasOwnProperty('saveOptions')) {
              fieldOptionsRef.current.saveOptions().then(opts => {
                setOptions(opts);
                setShowEditFieldOptions(null);
              });
            } else {
              setShowEditFieldOptions(null);
            }
          }}>{intl.formatMessage({id: "common.button.save"})}</Button>
      </DialogActions>
    </Dialog>
  </RenderEditableField>;
}
