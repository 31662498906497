import {Button, Menu, MenuItem} from "@material-ui/core";
import React, {useState} from "react";
import {ArrowDropDown} from "@material-ui/icons";

export default function LanguageSelect({
                                         settings = {
                                           language: 'Dansk',
                                           locale: 'da',
                                           country_code: 'DK',
                                         },
                                         languages = [],
                                         language = {
                                           id: null,
                                           name: 'Dansk',
                                           locale: 'da',
                                           country_code: 'DK',
                                         },
                                         setLanguage = () => null
                                       }) {
  const [menuAnchor, setMenuAnchor] = useState();
  const handleClick = (l) => {
    setLanguage(l);
    setMenuAnchor(null);
  }
  return <><Button
    size="small"
    onClick={e => setMenuAnchor(e.target)}
    disabled={languages.length < 1}
  ><img
    alt={language.name}
    src={"/flags/" + language.country_code.toUpperCase() + ".png"}
    title={language.name}/>&nbsp;{language.name}&nbsp;<ArrowDropDown/>
  </Button>
    <Menu
      open={!!menuAnchor}
      onClose={_ => setMenuAnchor(null)}
      anchorEl={menuAnchor}
    >
      <MenuItem value={'0'} onClick={_ => handleClick({
        id: null,
        name: settings.language,
        locale: settings.locale,
        country_code: settings.country_code,
      })}><img
        alt={settings.language}
        src={"/flags/" + settings.country_code.toUpperCase() + ".png"}
        title={settings.language}/>&nbsp;{settings.language}
      </MenuItem>
      {languages.map(l => <MenuItem key={"language-select-" + l.id} value={l.id} onClick={_ => handleClick(l)}><img
        alt={l.name}
        src={"/flags/" + l.country_code.toUpperCase() + ".png"}
        title={l.name}/>&nbsp;{l.name}
      </MenuItem>)}
    </Menu>
  </>
}
