import React from "react";
import {Box} from "@material-ui/core";
import {authHeader, getApiDomain} from "contexts/Auth";
import {Filebrowser} from "../../components/Filebrowser/Filebrowser";
import {joditConfig} from "../../variables/general";

export default function AssetList() {

  return (<Box id="assets-list"><Filebrowser
    options={{
      license: joditConfig.license,
      height: window.innerHeight - 127,
      defaultTimeout: 10,
      ajax: {
        url: getApiDomain() + '/files/assets',
        headers: authHeader(),
      },
      uploader: {
        url: getApiDomain() + '/upload/assets',
        headers: authHeader(),
      },
    }}
  /></Box>);
}
