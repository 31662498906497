import themeColors from "../colors";

const componentStyles = (theme) => ({
  drawerPaper: {
    marginTop: 50,
    paddingBottom: 50,
    paddingTop: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    borderTopRightRadius: "0.725rem",
    boxShadow: "none !important",
    backgroundColor: "rgb(255,255,255)",
    background: "linear-gradient(150deg, rgba(255,255,255,1) 0%, rgba(238,241,249,1) 90%)",
    filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#eef1f9",GradientType=1)',
  },
  listRootTop: {
    paddingTop: "0.625rem",
    width: 250,
    overflow: "hidden",
    overflowY: "auto",
  },
  listRootBottom: {
    width: 250,
    position: "fixed",
    bottom: 0,
  },
  listSub: {
    marginLeft: "0.75rem",
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemRoot: {
    width: "calc(100% - 1rem)",
    margin: "0.5rem",
    marginRight: "1rem",
    borderRadius: "0.5rem",
    display: "flex",
    fontSize: ".9rem",
    color: themeColors.primary.text,
    padding: ".25rem 0.5rem !important",
    "&:hover": {
      backgroundColor: "#DFE2EB",
      cursor: "pointer"
    },
    "& .MuiListItemText-primary": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  listItemSelected: {
    fontWeight: "bold",
    backgroundColor: "#cce5ff !important",
    color: themeColors.primary.text,
  },
  listItemIconRoot: {
    minWidth: "2.25rem",
    fontSize: ".9375rem",
    lineHeight: "1.5rem",
    display: "inline-block",
    top: "2px",
  },
  divider: {
    marginBottom: "1rem",
    marginTop: "1rem",
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
  },
  icon: {
    color: "inherit !important",
    "&:hover": {
      color: theme.palette.sidebarLinks.main,
      cursor: "pointer"
    },
    marginRight: 10
  },
  title: {
    margin: 0,
    // fontSize: "1rem",
    textTransform: "uppercase",
    letterSpacing: ".04em",
    paddingLeft: "1.0rem",
    paddingRight: "1.5rem",
    color: themeColors.primary.text,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  logoBox: {
    backgroundColor: theme.palette.navbar.main,
    minHeight: 50,
    paddingBottom: "unset"
  },
  logoClasses: {
    maxHeight: "2rem",
    maxWidth: "100%",
    verticalAlign: "middle",
    borderStyle: "none",
    [theme.breakpoints.up("md")]: {
      maxHeight: "2.5rem",
    },
    margin: "0 10px"
  },
  logoLinkClasses: {
    fontSize: "1.25rem",
    lineHeight: "inherit",
    whiteSpace: "nowrap",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    height: "100%"
  },
  logoLinkText: {
    color: "#323338",
    margin: "12px 0 0 0",
    fontWeight: "bold",
  },
  textPrimary: {
    color: theme.palette.primary.main,
  },
  textPrimaryLight: {
    color: theme.palette.primary.light,
  },
  textError: {
    color: theme.palette.error.main,
  },
  textErrorLight: {
    color: theme.palette.error.light,
  },
  textWarning: {
    color: theme.palette.warning.main,
  },
  textWarningLight: {
    color: theme.palette.warning.light,
  },
  textInfo: {
    color: theme.palette.info.main,
  },
  textInfoLight: {
    color: theme.palette.info.light,
  },
  menuPaper: {
    width: "calc(100% - 1rem)",
    backgroundColor: theme.palette.primary.main
  },
  outlineNone: {
    outline: "none!important",
  },
  close: {
    color: theme.palette.sidebarLinks.main
  }
});

export default componentStyles;
