import React, {useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import {authUser, useAuthDispatch} from "../../../../contexts/Auth";
import {getFieldLabel} from "./Field";
import {LanguageContext} from "../../../../contexts/Layouts";
import {EntityContext, getQueryFields} from "../../EntityLayoutEditor";
import {Edit} from "@material-ui/icons";
import {rolePriorities} from "../../../../config";
import {getEntityTypeColumns, getEntityValueFromField} from "../../../../variables/fields";
import {useNavigate, useParams} from "react-router-dom";
import {graphQLApi} from "../../../../services/GraphQLApi";

export default function RelatedField({
                                       field,
                                       fields,
                                       entityTypes,
                                       ...rest
                                     }) {
  const {language} = useContext(LanguageContext);
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useAuthDispatch();
  const {entity} = useContext(EntityContext);
  const params = useParams();
  let id = params?.id;
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState([]);

  useEffect(() => {
    const client = new graphQLApi(dispatch);
    if (entity?.id && language) {
      setIsLoading(true);
      client.query('{' +
        ' entities(filter:{related_entity_id:' + entity.id + '}) {' +
        '  data{' + getQueryFields(language) + '}' +
        ' }' +
        '}').then(r => {
        if (r?.hasOwnProperty('entities')) {
          setValues(r.entities.data);
        }
        setIsLoading(false);
      });
    }
  }, [id]);

  if (!field) return null;

  let entityTypeId = field?.type.match(/^relations_(\d)+$/);
  if (entityTypeId) entityTypeId = entityTypeId[1];
  let entityType = entityTypes.find(et => et.id === entityTypeId);
  const columns = getEntityTypeColumns(entityType, intl);
  let allowEdit = true;
  if (!entityType) allowEdit = false;

  const actions = [
    {
      tooltip: intl.formatMessage({id: "common.button.edit", defaultMessage: "Edit"}),
      icon: Edit,
      rowClick: true,
      onClick: (row) => {
        navigate('/data/' + entityTypeId + '/entity/' + row.id);
      }
    }
  ];

  // console.log(field, values, entityType, columns);
  const hasRoleAccess =
    allowEdit
    && authUser().isAllowed(rolePriorities.can_data)
    && (authUser().roles?.map(r => r.id).some(e => entityType?.roles.map(r => parseInt(r.id)).includes(e)));
  return <FormControl
    fullWidth
    variant="standard"
    key={'field-entities-' + rest.nodeId}
  >
    <Box style={{display: "flex", gap: "1rem", alignItems: "center", justifyContent: "flex-end", minHeight: "0.725rem"}}
         mb={1}>
      <InputLabel shrink style={{flexGrow: 1}}>{getFieldLabel(field, language)}</InputLabel>
      {actions.filter(a => hasRoleAccess && a.isFreeAction).map((a, i) =>
        <Button key={'field-entities-' + field.id + '-free-action-' + i} disabled={a.disabled()} variant="outlined"
                size="small" onClick={a.onClick}
                startIcon={<a.icon/>}>{a.label}</Button>
      )}
    </Box>
    <TableContainer>
      <Table padding="none" size="small">
        <TableHead><TableRow>{columns.map((c, i) =>
          <TableCell key={'field-entities-' + field.id + '-header-' + i}>{c.title}</TableCell>
        )}<TableCell>&nbsp;</TableCell>
        </TableRow></TableHead>
        <TableBody>{!values.length
          ? <TableRow>
            <TableCell
              style={{padding: 8, fontStyle: "italic", textAlign: "center"}}
              colSpan={columns.length + 2}
            >{isLoading
              ? <CircularProgress/>
              : intl.formatMessage({
                id: "entity.fields.relations.empty",
                defaultMessage: "There isn't any {title} linked to this entity"
              }, {title: entityType?.title})
            }</TableCell>
          </TableRow>
          : values.sort((a, b) => {
            let av = getEntityValueFromField(fields[columns[1].field], a.values.find(v => v.field_id === columns[1].field), language.id);
            let bv = getEntityValueFromField(fields[columns[1].field], b.values.find(v => v.field_id === columns[1].field), language.id);
            if (typeof av === "string") {
              return av.localeCompare(bv, language.locale, {sensitivity: 'base'});
            } else {
              if (av < bv) return -1;
              if (av > bv) return 1;
              return 0;
            }
          }).filter(v => !v.delete).map((v, ri) =>
            <TableRow key={'field-entities-' + field.id + '-row-' + ri}
                      style={{cursor: actions.find(a => hasRoleAccess && a.rowClick) ? 'pointer' : 'inherit'}}
            >{columns.map((c, ci) =>
              <TableCell
                onClick={_ => {
                  actions.find(a => hasRoleAccess && a.rowClick)?.onClick(v)
                }}
                key={'field-entities-' + field.id + '-row-' + ri + '-cell-' + ci}
              >{c.field === 'id'
                ? v.id
                : getEntityValueFromField(fields[c.field], v.values?.find(ev => ev.field_id === c.field), language.id)
              }</TableCell>
            )}<TableCell
              key={'field-entities-' + field.id + '-row-' + ri + '-actions'}
              align="right">{actions.filter(a => hasRoleAccess && !a.isFreeAction).map((a, i) =>
              <IconButton
                key={'field-entities-' + field.id + '-row-action-' + i}
                disabled={typeof a.disabled === "function" ? a.disabled() : a.disabled}
                size="small"
                onClick={_ => a.onClick(v)}
              >
                <a.icon/>
              </IconButton>
            )}</TableCell>
            </TableRow>
          )}</TableBody>
      </Table>
    </TableContainer>
  </FormControl>;
}
