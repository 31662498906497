import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {Card, CardContent, CardHeader,} from '@material-ui/core';
import ViewColumns from "./ViewColumns";
import EditForm from '../../../../components/Form/EditForm';
import {graphQLApi, graphQLReduceFields} from '../../../../services/GraphQLApi';
import {useAuthDispatch} from '../../../../contexts/Auth';
import RoleSelector from '../../Settings/RoleSelector';
import {useLocation, useNavigate, useParams} from "react-router-dom";

export default function ViewEdit(props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  let id = props.id || Number(params.id);

  const data = {
    id: null,
    title: '',
    sorting: 0,
    description: '',
    listing_id: null,
    columns: [],
    roles: []
  }

  const [view, setView] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const handleChange = (d) => {
    setView(d);
    if (props.setModified) props.setModified(true);
  }

  const fields = [
    {
      column: 1,
      field: "title",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "views.edit.label.title", defaultMessage: "Title"}),
      input: "text"
    },
    {
      column: 1,
      field: "listing_id",
      initial: null,
      type: "ID",
      query: "listings",
      titleField: ["entity_type.title", "title"],
      sorting: {field: "entity_type.title", direction: "asc"},
      filter: "user_id:null" + (props.entityTypeId ? " entity_type_id:" + props.entityTypeId : ''),
      label: intl.formatMessage({id: "labels.edit.label.listing", defaultMessage: "List"}),
      onChange: (value, data, setData) => {
        setData({
          ...data,
          listing_id: value,
          columns: data.columns?.filter(c => !c.field || c.field.entity_type_id === value.entity_type.id),
        });
      }
    },
    {
      column: 1,
      field: "sorting",
      initial: 0,
      type: "Int",
      label: intl.formatMessage({id: "views.edit.label.sorting", defaultMessage: "Sorting"}),
      input: "number"
    },
    {
      column: 1,
      field: "columns",
      label: "",
      type: "Json",
      titleField: "",
      extraFields: "field{id name entity_type_id} column",
      render: (key, data, setData) => id ? <ViewColumns reactKey={key} data={data} setData={setData}/> : '',
    },
    {
      column: 2,
      field: "description",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "views.edit.label.description", defaultMessage: "Description"}),
      lines: 7,
      required: true
    },
    {
      column: 2,
      field: "roles",
      initial: [],
      type: "[ID]",
      label: intl.formatMessage({id: "views.edit.label.roles", defaultMessage: "Roles with access"}),
      render: (key, data, setData) => <RoleSelector roles={roles} data={data} setData={setData}/>
    },
  ];


  const initialValidation = graphQLReduceFields(fields, 'validation');
  const [validation, setValidation] = useState(initialValidation);
  const setValidationFromErrors = (errors) => {
    if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
      setValidation({...initialValidation, ...errors[0].extensions.validation});
    }
  };

  const client = new graphQLApi(useAuthDispatch(), null, {handleErrors: setValidationFromErrors});
  const queryFields = 'id title description sorting roles{id title} listing{id entity_type{id title} title} columns{id field{id name entity_type_id} column}'
  useEffect(() => {
    setIsLoading(true)
    let query = 'roles {data{id title}}';
    if (id > 0) query = query + ' views (filter:{id:' + id + '}) {data {' + queryFields + '}}'
    client.query('{' + query + '}').then(r => {
      if (r && r.hasOwnProperty('roles')) {
        setRoles(r.roles?.data);
        if (!Number(id)) {
          setView({...view, roles: r.roles ? r.roles.data : []});
        }
      }
      if (r && r.hasOwnProperty('views')) {
        r.views.data[0].listing_id = r.views.data[0].listing;
        setView(r.views.data[0])
      }
    });
    setIsLoading(false)
  }, []);


  const save = () => {
    setIsLoading(true);
    setValidation(initialValidation);
    const vars = {
      title: "String",
      description: "String",
      sorting: "Int",
      listing_id: "ID",
      roles: "[ID]",
      columns: "String"
    };

    if (id > 0) {
      view.id = id;
      vars.id = "ID!";
    }
    view.columns = JSON.stringify(view.columns)
    client.mutation('view', vars, view, queryFields).then(r => {
      if (r?.response) {
        r.response.listing_id = r.response.listing;
        setView(r.response);
        if (props.entityTypeId) navigate(-1);
        else navigate(location.pathname.replace('/create', '/' + r.response.id), {replace: true});
      }
      setIsLoading(false);
    });
  }
  const {elevation = 2, margin = 3} = props;
  return <Card style={{margin: margin}} elevation={elevation}>
    <CardHeader
      color="primary"
      title={intl.formatMessage({id: "views.edit.heading", defaultMessage: "Views edit"})}/>
    <CardContent>
      <EditForm
        {...props}
        id={id > 0 ? Number(id) : null}
        fields={fields}
        data={view}
        setData={handleChange}
        isLoading={isLoading}
        validation={validation}
        cols={2}
        save={save}
        back
      />
    </CardContent>
  </Card>;
}
