import React, {useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@material-ui/core";
import {Add, DragHandle, Remove} from "@material-ui/icons";
import {useIntl} from "react-intl";
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import {arrayMoveMutable as arrayMove} from "array-move";
import EditForm from "../../../../components/Form/EditForm";

const DraggableHandle = sortableHandle(() => <DragHandle cursor="row-resize" fontSize="small"/>);
const SortableItem = sortableElement(({column, onDelete, intl}) => {
  let title;
  switch (column.column) {
    case 'id':
      title = <i>{intl.formatMessage({id: "view.edit.columns.id", defaultMessage: "#ID"})}</i>;
      break;
    case 'parent_id':
      title = <i>{intl.formatMessage({id: "view.edit.columns.parent_id", defaultMessage: "Parent #ID"})}</i>;
      break;
    case 'variant_fields':
      title = <i>{intl.formatMessage({id: "view.edit.columns.variant_fields", defaultMessage: "Variant fields"})}</i>;
      break;
    default:
      title = column.field.name;
      break;
  }
  return <ListItem>
    <ListItemIcon><DraggableHandle/></ListItemIcon>
    <ListItemText>{title}</ListItemText>
    <ListItemSecondaryAction>
      <IconButton size="small" onClick={() => onDelete(column)} title={intl.formatMessage({id:"common.button.remove", defaultMessage:"Remove"})}><Remove/></IconButton>
    </ListItemSecondaryAction>
  </ListItem>;
});
const SortableContainer = sortableContainer(({children}) => {
  return <List dense>{children}</List>;
});


export default function ViewColumns(props) {
  const {reactKey, data, setData} = props;
  if (typeof data.columns === 'string') data.columns = JSON.parse(data.columns)

  const intl = useIntl();
  const [showAddColumn, setShowAddColumn] = useState(false);
  const initialColumn = {id:null, column:null, field_id:null};
  const [newColumn, setNewColumn] = useState(initialColumn);

  const onSortEnd = ({oldIndex, newIndex}) => {
    let columns = [...data.columns];
    arrayMove(columns, oldIndex, newIndex);
    setData({...data, columns: columns});
  }

  return <Box key={"form-field-columns-" + reactKey}>
    <InputLabel shrink>{intl.formatMessage({id:"view.edit.columns.label", defaultMessage:"Columns in the view list"})}</InputLabel>
    <SortableContainer onSortEnd={onSortEnd} useDragHandle>{data.columns?.map((col, i) => <SortableItem
      intl={intl}
      key={"form-field-column-" + (i+1)}
      column={col}
      index={i}
      onDelete={column => setData({...data, columns: data.columns.filter(c => c !== column)})}
    />)}</SortableContainer>
    <Button startIcon={<Add/>} variant="outlined" size="small" onClick={() => {
      setNewColumn(initialColumn);
      setShowAddColumn(true);
    }}>{intl.formatMessage({id:"view.edit.columns.button.add", defaultMessage:"Add Column"})}</Button>
    {showAddColumn && <Dialog open={true} onClose={() => setShowAddColumn(false)}>
      <DialogContent>
        <Typography variant={"subtitle1"}>{intl.formatMessage({id:"view.edit.columns.dialog.select_column_or_field", defaultMessage:"Select a column or field"})}</Typography>
        <EditForm
          isLoading={false}
          cols={1}
          validation={{column:[], field_id:[]}}
          data={newColumn}
          setData={setNewColumn}
          fields={[
            {field:"column", initial: null, label: "Column", type: "String", options: [
              {id:"id", name: intl.formatMessage({id:"entity.columns.id", defaultMessage:"ID"})},
              {id:"parent_id", name: intl.formatMessage({id:"entity.columns.parent_id", defaultMessage:"Parent ID"})},
              {id:"variant_fields", name: intl.formatMessage({id:"entity.columns.variant_fields", defaultMessage:"Variant Fields"})},
            ], onChange: (value, data, setData) => setData({...data, column: value, field_id: null})},
            {
              field:"field_id",
              initial: null,
              label: "Field",
              type: "ID",
              query: "fields",
              titleField: "name",
              filter:"entity_type_id:"+data.listing_id?.entity_type?.id,
              extraFields:"entity_type_id",
              onChange: (value, data, setData) => setData({...data, column: null, field_id: value}),
            },
          ]}
          save={d => {
            setData({...data, columns: [...data.columns, {...d, field: d.field_id}]});
            setShowAddColumn(false);
          }}
        />
      </DialogContent>
    </Dialog>}
  </Box>
}
