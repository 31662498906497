import {FormControl, FormLabel, Slider} from "@material-ui/core";
import React from "react";
import {useIntl} from "react-intl";


export default function Padding({setProp, value, key = "padding"}) {
    const intl = useIntl();
    return <FormControl fullWidth margin="dense" component="fieldset">
        <FormLabel component="legend" style={{fontSize:"0.825rem"}}>{
            intl.formatMessage({id:"layouts.prop.padding",defaultMessage:"Padding {px}px"}, {px:value})
        }</FormLabel>
        <Slider
            defaultValue={value}
            onChange={(_, value) =>
                setProp((props) => (props[key] = value), 500)
            }
            step={4}
            min={0}
        />
    </FormControl>
}
