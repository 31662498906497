import React from 'react';
import {useIntl} from 'react-intl';
import {Card, CardContent, CardHeader, Grid} from '@material-ui/core';
import {Settings} from '@material-ui/icons';
import GraphQLEditForm from 'components/GraphQL/GraphQLEditForm';
import {useParams} from "react-router-dom";

export default function SettingsEdit(props) {
    const intl = useIntl();
  const params = useParams();

  let id = Number(params.id);

    const fields = [
        {
            field: "key",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "settings.edit.label.key", defaultMessage: "Key"}),
            input: "text"
        },
        {
            field: "type",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "settings.edit.label.type",
                defaultMessage: "Type"
            }),
            input: "text",
        },
        {
            field: "locale",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "settings.edit.label.locale",
                defaultMessage: "Locale (da, de, en, es, se, etc.)"
            }),
            input: "country",
            inputCode: "Alpha-2"
        },
        {
            field: "data",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "settings.edit.label.data",
                defaultMessage: "Data"
            }),
            input: "text",
        },
        {
            field: "description",
            initial: "",
            type: "String",
            label: intl.formatMessage({
                id: "settings.edit.label.description",
                defaultMessage: "Description"
            }),
            input: "html",
            fullWidth: true,
        }
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        avatar={<Settings/>}
                        title={intl.formatMessage({id: "settings.edit.heading"})}
                    />
                    <CardContent>
                        <GraphQLEditForm
                            id={id}
                            query={"settings"}
                            mutations={"setting"}
                            fields={fields}
                            cols={2}
                            {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
