import {
  AssignmentInd,
  CallSplit,
  CreditCard,
  Domain,
  DoubleArrow,
  FormatListBulleted,
  Language,
  List,
  ListAlt,
  MultilineChart,
  Person,
  PrintOutlined,
  Share,
  SwapHoriz,
  ViewColumn,
} from '@material-ui/icons';
import ViewEdit from '../views/Admin/EntityTypes/Views/ViewEdit';
import ViewList from '../views/Admin/EntityTypes/Views/ViewList';

import UserList from '../views/Admin/Account/UserList';
import UserEdit from '../views/Admin/Account/UserEdit';
import LanguageList from 'views/Admin/Settings/LanguageList';
import LanguageEdit from 'views/Admin/Settings/LanguageEdit';
import SiteList from 'views/Admin/MutliSite/SiteList';
import SiteEdit from 'views/Admin/MutliSite/SiteEdit';
import DomainEdit from 'views/Admin/MutliSite/DomainEdit';
import RoleList from '../views/Admin/Account/RoleList';
import RoleEdit from '../views/Admin/Account/RoleEdit';
import ConnectorList from '../views/Admin/Settings/ConnectorList';
import ConnectorEdit from '../views/Admin/Settings/ConnectorEdit';
import EventLogList from '../views/Admin/Settings/EventLogList';
import FieldList from '../views/Admin/EntityTypes/Fields/FieldList';
import FieldEdit from '../views/Admin/EntityTypes/Fields/FieldEdit';
import FieldGroupList from 'views/Admin/Settings/FieldGroupList';
import FieldGroupEdit from 'views/Admin/Settings/FieldGroupEdit';
import ListingList from '../views/Admin/EntityTypes/Listings/ListingList';
import LabelList from 'views/Admin/Labels/LabelList';
import LabelEdit from 'views/Admin/Labels/LabelEdit';
import EntityTypeList from '../views/Admin/EntityTypes/EntityTypeList';
import EntityTypeEdit from '../views/Admin/EntityTypes/EntityTypeEdit';
import {rolePriorities} from 'config';
import SettingsList from 'views/Admin/Settings/SettingsList';
import SettingsEdit from 'views/Admin/Settings/SettingsEdit';
import ListingEdit from '../views/Admin/EntityTypes/Listings/ListingEdit';
import ChannelList from '../views/Admin/Channels/ChannelList';
import ChannelEdit from '../views/Admin/Channels/ChannelEdit';
import Dashboard from '../views/Admin/Dashboard';
import WorkflowEdit from "../views/Admin/Workflows/WorkflowEdit";
import WorkflowList from "../views/Admin/Workflows/WorkflowList";
import PdfLayoutEdit from "../views/Admin/PdfLayouts/PdfLayoutEdit";
import PdfLayoutList from "../views/Admin/PdfLayouts/PdfLayoutList";
import Billing from "../views/Admin/Account/Billing";

export default function routes(intl) {
  return [
    {
      path: "",
      name: intl.formatMessage({id: "menu.dashboard", defaultMessage: "Dashboard",}),
      component: Dashboard,
      icon: MultilineChart,
      allow_priority: rolePriorities.view_data,
    },
    {
      path: "sites",
      name: intl.formatMessage({
        id: "menu.sites.list",
        defaultMessage: "Sites",
      }),
      icon: Domain,
      component: SiteList,
      allow_priority: rolePriorities.site_manager,
      views: [
        {
          path: "create",
          name: intl.formatMessage({
            id: "menu.sites.create",
            defaultMessage: "Create Site",
          }),
          component: SiteEdit,
          hidden: true,
        },
        {
          path: ":site_id/domains/create",
          name: intl.formatMessage({
            id: "menu.domains.create",
            defaultMessage: "Create Domain",
          }),
          component: DomainEdit,
          hidden: true,
        },
        {
          path: ":site_id/domains/:id",
          name: intl.formatMessage({
            id: "menu.domains.edit",
            defaultMessage: "Edit Domain",
          }),
          component: DomainEdit,
          hidden: true,
        },
        {
          path: ":id",
          name: intl.formatMessage({
            id: "menu.sites.edit",
            defaultMessage: "Edit Site",
          }),
          component: SiteEdit,
          hidden: true,
        },
      ],
    },
    {
      path: "lists",
      name: intl.formatMessage({id: "menu.lists.list", defaultMessage: "Lists"}),
      icon: List,
      component: ListingList,
      iconColor: "Info",
      allow_priority: rolePriorities.developer,
      views: [
        {
          path: "create",
          name: intl.formatMessage({id: "menu.lists.create", defaultMessage: "Create list"}),
          component: ListingEdit,
          hidden: true,
        },
        {
          path: ":id",
          name: intl.formatMessage({id: "menu.lists.edit", defaultMessage: "Edit list"}),
          component: ListingEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "views",
      name: intl.formatMessage({id: "menu.views.list", defaultMessage: "Views"}),
      icon: ViewColumn,
      component: ViewList,
      iconColor: "Info",
      layout: "/admin",
      allow_priority: rolePriorities.developer,
      views: [
        {
          path: "create",
          name: intl.formatMessage({id: "menu.views.create", defaultMessage: "Create View"}),
          component: ViewEdit,
          hidden: true,
        },
        {
          path: ":id",
          name: intl.formatMessage({id: "menu.views.edit", defaultMessage: "Edit View"}),
          component: ViewEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "data-types",
      name: intl.formatMessage({id: "menu.data-types.list", defaultMessage: "Data types"}),
      icon: CallSplit,
      component: EntityTypeList,
      iconColor: "default",
      layout: "/admin",
      allow_priority: rolePriorities.can_fields,
      views: [
        {
          path: ":id",
          name: intl.formatMessage({id: "menu.data-types.edit", defaultMessage: "Edit entity type"}),
          component: EntityTypeEdit,
          hidden: true,
        },
        {
          path: ":id/:tab/:tabId?",
          name: intl.formatMessage({id: "menu.data-types.tab.edit", defaultMessage: "Edit entity type setting"}),
          component: EntityTypeEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "fields",
      name: intl.formatMessage({
        id: "menu.fields.list",
        defaultMessage: "Fields",
      }),
      icon: FormatListBulleted,
      iconColor: "Warning",
      component: FieldList,
      allow_priority: rolePriorities.developer,
      views: [
        {
          path: ":id",
          name: intl.formatMessage({
            id: "menu.fields.edit",
            defaultMessage: "Field edit",
          }),
          component: FieldEdit,
          hidden: true,
        },
        {
          path: "create",
          name: intl.formatMessage({
            id: "menu.fields.create",
            defaultMessage: "Field create",
          }),
          component: FieldEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "field_groups",
      name: intl.formatMessage({id: "menu.field_groups.list", defaultMessage: "Field Groups"}),
      icon: ListAlt,
      iconColor: "Warning",
      component: FieldGroupList,
      allow_priority: rolePriorities.developer,
      views: [
        {
          path: "create",
          name: intl.formatMessage({
            id: "menu.field_groups.create",
            defaultMessage: "Create Field Group"
          }),
          component: FieldGroupEdit,
          hidden: true,
        },
        {
          path: ":id",
          name: intl.formatMessage({
            id: "menu.field_groups.edit",
            defaultMessage: "Edit Field Group"
          }),
          component: FieldGroupEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "exports",
      name: intl.formatMessage({id: "menu.labels.list", defaultMessage: "Labels"}),
      icon: ListAlt,
      component: LabelList,
      allow_priority: rolePriorities.can_fields,
      views: [
        {
          path: "create",
          name: intl.formatMessage({
            id: "menu.labels.create",
            defaultMessage: "Create Label"
          }),
          component: LabelEdit,
          hidden: true,
        },
        {
          path: ":id",
          name: intl.formatMessage({
            id: "menu.labels.edit",
            defaultMessage: "Edit Label"
          }),
          component: LabelEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "pdfs",
      name: intl.formatMessage({id: "menu.pdf-layouts", defaultMessage: "PDF Layouts",}),
      component: PdfLayoutList,
      icon: PrintOutlined,
      allow_priority: rolePriorities.can_fields,
      views: [
        {
          path: ":id",
          name: intl.formatMessage({
            id: "menu.pdf-layouts.edit",
            defaultMessage: "Edit PDF layout",
          }),
          component: PdfLayoutEdit,
          hidden: true,
        },
        {
          path: "create",
          name: intl.formatMessage({
            id: "menu.pdf-layouts.create",
            defaultMessage: "Create PDF layout",
          }),
          component: PdfLayoutEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "workflows",
      name: "Workflows",
      icon: DoubleArrow,
      component: WorkflowList,
      layout: "/admin",
      allow_priority: rolePriorities.can_import_export,
      views: [
        {
          path: "create",
          name: intl.formatMessage({id: "menu.settings.connectors.create", defaultMessage: "Create workflow"}),
          component: WorkflowEdit,
          hidden: true,
        },
        {
          path: ":id",
          name: intl.formatMessage({id: "menu.settings.connectors.edit", defaultMessage: "Edit workflow"}),
          component: WorkflowEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "channels",
      name: intl.formatMessage({id: "menu.channels.list", defaultMessage: "Channels"}),
      icon: SwapHoriz,
      component: ChannelList,
      iconColor: "Info",
      layout: "/admin",
      allow_priority: rolePriorities.can_import_export,
      views: [
        {
          path: "create",
          name: intl.formatMessage({id: "menu.channels.create", defaultMessage: "Create channel"}),
          component: ChannelEdit,
          hidden: true,
        },
        {
          path: ":id",
          name: intl.formatMessage({id: "menu.channels.edit", defaultMessage: "Edit channel"}),
          component: ChannelEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "connectors",
      name: intl.formatMessage({id: "menu.settings.connectors.list", defaultMessage: "Applications"}),
      icon: Share,
      component: ConnectorList,
      iconColor: "Info",
      allow_priority: rolePriorities.can_import_export,
      views: [
        {
          path: "create",
          name: intl.formatMessage({id: "menu.settings.connectors.create", defaultMessage: "Install Application"}),
          component: ConnectorEdit,
          hidden: true,
        },
        {
          path: ":id",
          name: intl.formatMessage({id: "menu.settings.connectors.edit", defaultMessage: "Edit Application"}),
          component: ConnectorEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "event-logs",
      name: intl.formatMessage({id: "menu.settings.event.logs", defaultMessage: "Event Logs"}),
      component: EventLogList,
      icon: Share,
      iconColor: "Info",
      allow_priority: rolePriorities.can_import_export,
    },
    {
      path: "languages",
      name: intl.formatMessage({id: "menu.languages.list", defaultMessage: "Languages"}),
      icon: Language,
      component: LanguageList,
      allow_priority: rolePriorities.admin,
      views: [
        {
          path: "create",
          name: intl.formatMessage({id: "menu.languages.create", defaultMessage: "Create Language"}),
          component: LanguageEdit,
          hidden: true,
        },
        {
          path: ":id",
          name: intl.formatMessage({id: "menu.languages.edit", defaultMessage: "Edit Language"}),
          component: LanguageEdit,
          hidden: true,
        },
      ]
    },
    {
      divider: true,
      path: '',
    },
    {
      title: intl.formatMessage({
        id: "menu.account.title",
        defaultMessage: "Account",
      }),
      path: '',
    },
    {
      path: "billing",
      name: intl.formatMessage({
        id: "menu.billing.list",
        defaultMessage: "Billing",
      }),
      icon: CreditCard,
      component: Billing,
      allow_priority: rolePriorities.can_users,
    },
    {
      path: "users",
      name: intl.formatMessage({
        id: "menu.users.list",
        defaultMessage: "Users",
      }),
      icon: Person,
      component: UserList,
      allow_priority: rolePriorities.can_users,
      views: [
        {
          path: "create",
          name: intl.formatMessage({
            id: "menu.users.create",
            defaultMessage: "Create user",
          }),
          component: UserEdit,
          hidden: true
        },
        {
          path: ":id",
          name: intl.formatMessage({
            id: "menu.users.edit",
            defaultMessage: "Edit user",
          }),
          component: UserEdit,
          hidden: true
        },
      ],
    },
    {
      path: "roles",
      name: intl.formatMessage({id: "menu.roles.list", defaultMessage: "Roles"}),
      icon: AssignmentInd,
      component: RoleList,
      allow_priority: rolePriorities.admin,
      views: [
        {
          path: "create",
          name: intl.formatMessage({id: "menu.roles.create", defaultMessage: "Create Role"}),
          component: RoleEdit,
          hidden: true,
        },
        {
          path: ":id",
          name: intl.formatMessage({id: "menu.roles.edit", defaultMessage: "Edit Role"}),
          component: RoleEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "settings",
      name: intl.formatMessage({
        id: "menu.settings.list",
        defaultMessage: "Settings",
      }),
      icon: Person,
      component: SettingsList,
      allow_priority: rolePriorities.developer,
      views: [
        {
          path: "create",
          name: intl.formatMessage({
            id: "menu.settings.create",
            defaultMessage: "Create setting",
          }),
          component: SettingsEdit,
          hidden: true
        },
        {
          path: ":id",
          name: intl.formatMessage({
            id: "menu.settings.edit",
            defaultMessage: "Edit setting",
          }),
          component: SettingsEdit,
          hidden: true
        },
      ],
    },
  ];
}
