import React, {useContext, useState} from "react";
import {
  FormControl,
  FormHelperText,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputLabel,
  Snackbar
} from "@material-ui/core";
import {EntityContext} from "../../EntityLayoutEditor";
import {authHeader, getApiDomain} from "../../../../contexts/Auth";
import {Add, Cancel, OpenInNew} from "@material-ui/icons";
import themeColors from "../../../../assets/theme/colors";
import {getThumbnailComponentFromUrl} from "../../../../components/FileTypes";
import {useIntl} from "react-intl";
import {Jodit} from "jodit-pro-react";
import {joditConfig} from "../../../../variables/general";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(_theme => ({
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
      overflow: "auto",
    },
    gridTile: {
      display: "flex",
      flexWrap: "wrap !important",
      alignContent: "space-around",
      justifyContent: "space-around",
    },
    gridTileRoot: {
      width: "184px !important"
    },
    title: {
      color: "white",
      fontSize: 14,
      textAlign: "left",
      textShadow: "1px 1px 2px black, 0 0 10px white, 0 0 1px white",
    },
    titleWrap: {
      marginLeft: 3,
      marginRight: 3,
    },
    titleBar: {
      // height: 20
      background: "none",
      //   'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    iconButton: {
      color: themeColors.primary.badgeBg,
    }
  }
));

export default function AssetsField({
                                      field,
                                      single = false,
                                    }) {
  const intl = useIntl();
  const classes = useStyles();
  const {getValuesOfField, setValuesOfField, canEdit} = useContext(EntityContext);
  const [alert, setAlert] = useState({message: "", open: false});
  let values = getValuesOfField(field.id);

  let fileBrowserPro = null;
  const handleShowAssetSelector = () => {
    if (!fileBrowserPro) {
      fileBrowserPro = new Jodit.modules.FileBrowserPro({
        license: joditConfig.license,
        width: window.innerWidth * 0.8,
        height: window.innerHeight * 0.8,
        moveFolder: true,
        moveFile: true,
        ajax: {
          url: getApiDomain() + '/files/assets',
          headers: authHeader(),
        },
        uploader: {
          url: getApiDomain() + '/upload/assets',
          headers: authHeader(),
        },
      });
    }
    if (!fileBrowserPro.isOpened) {
      fileBrowserPro.open(images => {
        if (images.files?.length > 1 && single) {
          setAlert({
            message: intl.formatMessage({
              id: "entity.edit.alert.only_one_asset",
              defaultMessage: "This field only accepts 1 asset, so only the first {asset} has been used."
            }, {asset: images.files[0]}), open: true
          });
        }
        if (single) {
          setValuesOfField(field.id, [{string: images.files[0]}]);
        } else {
          setValuesOfField(field.id, [...values, ...images.files.map(f => ({string: f}))]);
        }
      });
    }
  }

  return <FormControl fullWidth>
    <Snackbar
      autoHideDuration={5000}
      {...alert}
      onClose={_ => setAlert({...alert, open: false})}
    />
    <Box>
      <InputLabel shrink style={{position: 'unset', display: "inline-block"}}>{field.name}</InputLabel>
      {canEdit(field) && <IconButton size={"small"} color={"primary"}
                                     onClick={handleShowAssetSelector}>
        <Add style={{height: 20, width: 20}}/>
      </IconButton>}
    </Box>
    {!values?.length ?
      <FormHelperText style={{marginLeft: 5}}>{intl.formatMessage({
        id: "entity.edit.field.assets.empty",
        defaultMessage: "No assets has been assigned yet!"
      })}</FormHelperText> :
      <ImageList
        style={{margin: "2px 5px"}}
        className={classes.gridList}
      >
        {values.filter(v => v.string).map((value, aIdx) =>
          <ImageListItem
            key={"asset-" + field.id + "-" + aIdx}
            classes={{item: classes.gridTile, root: classes.gridTileRoot}}
          >
            <IconButton
              size="small"
              style={{
                float: "right",
                position: "absolute",
                right: 4,
                zIndex: 12200,
                backgroundColor: "rgba(0,0,0,0.05)",
                color: themeColors.gray[200]
              }}
              onClick={() => window.open(value.string, '_blank')}
            ><OpenInNew/></IconButton>
            {getThumbnailComponentFromUrl(value.string)}
            <ImageListItemBar
              title={value.string.split('/').pop()}
              classes={{
                root: classes.titleBar,
                title: classes.title,
                titleWrap: classes.titleWrap,
              }}
              actionIcon={canEdit(field) && <IconButton
                size={"small"}
                style={{color: themeColors.gray[500]}}
                onClick={_ => setValuesOfField(field.id, values.map(v => {
                  if ((value.id && value.id === v.id) || (!value.id && value.string === v.string)) {
                    return {...v, string: null};
                  }
                  return v;
                }))}
              ><Cancel/></IconButton>}
              actionPosition="right"
            />
          </ImageListItem>
        )}
      </ImageList>}
  </FormControl>
    ;
}
