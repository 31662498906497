import React, {useContext} from "react";
import {FormControl, FormControlLabel, Switch} from "@material-ui/core";
import {isFieldComputed} from "../../../../variables/fields";
import {LanguageContext} from "../../../../contexts/Layouts";
import {EntityContext} from "../../EntityLayoutEditor";
import {getFieldLabel} from "./Field";

export default function BooleanField({field, ...rest}) {
  const {language} = useContext(LanguageContext);
  const {getValuesOfField, setValuesOfField} = useContext(EntityContext);
  let value = getValuesOfField(field.id)?.pop() || {};

  return <FormControl fullWidth><FormControlLabel
    control={
      <Switch
        color="primary"
        id={'field-' + field.id}
        name={'field-' + field.id}
        disabled={isFieldComputed(field)}
        checked={value ? value.bool : false}
        onChange={event => setValuesOfField(field.id, [{bool: event.target.checked}])}
        {...rest}
      />
    }
    label={getFieldLabel(field, language)}
  /></FormControl>;
}
