import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

// core components
import componentStyles from "assets/theme/components/auth-header.js";

const useStyles = makeStyles(componentStyles);

const AuthHeader = () => {
  const classes = useStyles();
  // const theme = useTheme();
  // const intl = useIntl();
  return (
    <>
      <Box
        className={classes.header}
        paddingTop="8rem"
        paddingBottom="5rem"
      >
        <Container maxWidth="xl">
          <Box marginBottom="6rem" textAlign="center">
            {/*<Box
              component={Grid}
              container
              justifyContent="center"
              color={theme.palette.white.main}
            >
              <Grid item lg={5} md={6} xs={12}>
                <h1>{intl.formatMessage({id:"auth.header.welcome", defaultMessage:"Welcome to OpenDIMS"})}</h1>
                <Box
                  component="p"
                  color={theme.palette.gray[200]}
                  lineHeight="1.7"
                  fontSize="1rem"
                >{
                  intl.formatMessage({id:"auth.header.subheader", defaultMessage:"Single Point Of Truth!"})
                }</Box>
              </Grid>
            </Box>*/}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AuthHeader;
