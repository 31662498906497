import React, {useContext} from "react";
import {FormControl, IconButton, InputLabel} from "@material-ui/core";
import {LanguageContext} from "../../../../contexts/Layouts";
import {getFieldLabel} from "./Field";
import {EntityContext} from "../../EntityLayoutEditor";
import Box from "@material-ui/core/Box";
import {Check} from "@material-ui/icons";
import {Editor} from "../../../../components/Editor";

export default function HtmlField(
  {
    field,
    // ...rest
  }) {
  const {language} = useContext(LanguageContext);
  const {getValuesOfField, setValuesOfField, editing, setEditing, canEdit} = useContext(EntityContext);
  // console.log(field.name, column);
  let value = getValuesOfField(field.id);
  if (field.uses_languages) {
    value = value?.find(v => v.language_id === language.id) || {};
  } else {
    value = value?.pop() || {};
  }
  // console.log(field.name, value);
  return editing === field.id ?
    <Box display="flex" style={{gap: 8}} width="100%"><FormControl
      key={"field-" + field.key + "-" + language.id}
      id={field.key}
      // error={validation[field.field].length > 0}
      fullWidth
      style={{marginTop: 16, flexGrow: 1}}
    >
      <InputLabel style={{marginBottom: 10, marginTop: "-16px"}}
                  shrink={true}>{getFieldLabel(field, language)}</InputLabel>
      <Editor
        value={value?.text || ""}
        onBlur={newContent => setValuesOfField(field.id, [{text: newContent}])}
      />
      {/*<FormHelperText>{validation[field.field].join(" ")}</FormHelperText>*/}
    </FormControl><Box pt={2}><IconButton size="small"
                                          onClick={_ => setEditing(null)}><Check/></IconButton></Box></Box> :
    <FormControl
      fullWidth
      variant="standard"
      style={{cursor: canEdit(field) ? "pointer" : "auto", display: "flex"}}
      onClick={_ => canEdit(field) && setEditing(field.id)}
    >
      <InputLabel style={{cursor: "pointer"}} shrink>{getFieldLabel(field, language)}</InputLabel>
      <Box pt={2} dangerouslySetInnerHTML={{__html: value?.text || ''}}/>
    </FormControl>;
}
